const initialState = {
  nama: "",
  nopeg: "",
  email: "",
  idPerusahaan: "",
  role: "",
  idUser: "",
};

const authReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_IDENTITY":
      // console.log(data, ">>>>>>>>>>>>>>>>>>>>");
      return {
        ...state,
        nama: data.nama,
        nopeg: data.nopeg,
        email: data.email,
        idPerusahaan: data.idPerusahaan,
        role: data.role,
        idUser: data.idUser,
      };
    default:
      return state;
  }
};

export default authReducer;
