import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Tab,
  InputGroup,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Table,
  Image,
  FormGroup,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import CurrencyInput from "react-currency-input-field";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { setConstantValue } from "typescript";
import { Label } from "reactstrap";
import { Dna } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
import { getAllAngsuran } from "../../stores/pengajuan/function";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { updateBayarAngsuran } from "stores";
import { pelunasan } from "stores";

function DetailAngsuran(data) {
  const dispatch = useDispatch();
  const date = new Date();
  const auth = useSelector((state) => state.authReducer);
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  const valDetail = pengajuan.detailPengajuan;
  const valAngsuran = pengajuan.listAllAngsuran.data;
  const [detailListAngsuran, setDetailListAngsuran] = React.useState([]);

  const [idPengajuan, setIdPengajuan] = React.useState("");
  const [idAngsuran, setIdAngsuran] = React.useState("");

  const [idUser, setIdUser] = React.useState("");
  const [modalUbahSimpanan, setModalUbahSimpanan] = React.useState(false);
  const [modalPelunasan, setModalPelunasan] = React.useState(false);
  const [nominalBayar, setNominalBayar] = React.useState("");
  const [nominalAdmin, setNominalAdmin] = React.useState("");
  const [tampilNominalAngsuran, setTampilNominalAngsuran] = React.useState("");
  const [tampilJatuhTempo, setTampilJatuhTempo] = React.useState("");
  const [tampilAdministrasi, setTampilAdministrasi] = React.useState("");
  const [
    totalAdministrasiBelumTerbayarkan,
    setTotalAdministrasiBelumTerbayarkan,
  ] = React.useState(0);
  const [totalAngsuranBelumTerbayarkan, setTotalAngsuranBelumTerbayarkan] =
    React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };
  moment.locale("id");
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "id-ID",
      options
    );
    return formattedDate;
  };
  const formattedValue = (e) => {
    return e.toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
  };

  function submitUpdateAngsuran(e) {
    e.preventDefault();
    if (nominalAdmin === "" || nominalBayar === "") {
      Swal.fire({
        title: "Nominal pembayaran atau Nominal admin harus diisi !",
        icon: "warning",
      });
    } else {
      updateBayarAngsuran({
        idAngsuran: idAngsuran,
        nominalBayarAdministrasi: nominalAdmin,
        nominalPembayaran: nominalBayar,
        idPengajuan: idPengajuan,
        idUser: idUser,
        fcmTokenUser: data.dataFcmToken,
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 1500,
          });
          setModalUbahSimpanan(false);
          getAllAngsuran(dispatch, {
            idPengajuan: data.data,
          });
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    }
  }

  function submitPelunasan(e) {
    e.preventDefault();
    if (nominalAdmin === "" || nominalBayar === "") {
      Swal.fire({
        title: "Nominal pembayaran atau Nominal admin harus diisi !",
        icon: "warning",
      });
    } else {
      pelunasan({
        idAngsuran: idAngsuran,
        nominalBayarAdministrasi: nominalAdmin,
        nominalPembayaran: nominalBayar,
        idPengajuan: idPengajuan,
        idUser: idUser,
        fcmTokenUser: data.dataFcmToken,
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 1500,
          });
          setModalPelunasan(false);
          getAllAngsuran(dispatch, {
            idPengajuan: data.data,
          });
          // dispatch({ type: "SET_MODAL_ANGSURAN", data: true });
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    }
  }

  // function submitPelunasan(e) {
  //   e.preventDefault();
  //   if (nominalAdmin === "" && nominalBayar === "") {
  //     Swal.fire({
  //       title: "Nominal pembayaran atau Nominal admin harus diisi !",
  //       icon: "warning",
  //     });
  //   } else {
  //     Swal.fire({
  //       title: "Apakah Anda yakin?",
  //       text: "Anda akan melakukan pelunasan. Lanjutkan?",
  //       icon: "question",
  //       showCancelButton: true,
  //       confirmButtonText: "Ya",
  //       cancelButtonText: "Tidak",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         pelunasan({
  //           idAngsuran: idAngsuran,
  //           nominalBayarAdministrasi: nominalAdmin,
  //           nominalPembayaran: nominalBayar,
  //           idPengajuan: idPengajuan,
  //           idUser: idUser,
  //           fcmTokenUser: data.dataFcmToken,
  //         }).then((response) => {
  //           if (response.status === 200) {
  //             Swal.fire({
  //               position: "center",
  //               icon: "success",
  //               title: response.message,
  //               showCancelButton: false,
  //               timer: 1500,
  //             });
  //             setModalPelunasan(false);
  //             getAllAngsuran(dispatch, {
  //               idPengajuan: data.data,
  //             });
  //             dispatch({ type: "SET_MODAL_ANGSURAN", data: true });
  //           } else if (response.status === 400) {
  //             Swal.fire({
  //               icon: "error",
  //               title: "Oops...",
  //               text: response.message,
  //             });
  //           }
  //         });
  //       }
  //     });
  //   }
  // }

  React.useEffect(() => {
    let totalAdministrasiBelumTerbayarkan = 0;
    let totalAngsuranBelumTerbayarkan = 0;
    let tmp =
      pengajuan.listAllAngsuran.data &&
      pengajuan.listAllAngsuran.data.map((val, index, array) => {
        if (val.statusAngsuran === "Belum Terbayarkan") {
          totalAdministrasiBelumTerbayarkan += val.nominalAdministrasi;
          totalAngsuranBelumTerbayarkan += val.nominalAngsuran;
        }

        return {
          ...val,
          action: (
            <>
              <div className="actions-right">
                {index === array.length - 1 ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Update Pelunasan</Tooltip>}
                  >
                    <Button
                      disabled={val.statusAngsuran === "Terbayar"}
                      className="mr-2"
                      style={{
                        marginTop: 5,
                        padding: "5px 14px",
                        fontSize: "18px",
                        border: 0,
                        backgroundColor:
                          val.statusAngsuran === "Terbayar"
                            ? "grey"
                            : "#03C988",
                        borderColor: "#03C988",
                        cursor:
                          val.statusAngsuran === "Terbayar"
                            ? "not-allowed"
                            : "pointer",
                      }}
                      onClick={() => {
                        setIdAngsuran(val.idAngsuran);
                        setIdPengajuan(val.idPengajuan);
                        setIdUser(val.idUser);
                        setNominalAdmin(val.nominalAdministrasi);
                        setNominalBayar(val.nominalPembayaran);
                        setTampilNominalAngsuran(val.nominalAngsuran);
                        setTampilAdministrasi(val.nominalAdministrasi);
                        setTampilJatuhTempo(val.dateTempo);
                        setModalPelunasan(val);
                        getAllAngsuran(dispatch, {
                          idPengajuan: data.data,
                        });
                      }}
                    >
                      <i className="fas fa-check"></i>
                    </Button>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Update Status Angsuran</Tooltip>}
                  >
                    <Button
                      disabled={
                        data.statusPembayaran === "Lunas" ||
                        val.statusAngsuran === "Terbayar"
                      }
                      className="mr-2"
                      style={{
                        backgroundColor:
                          data.statusPembayaran === "Lunas" ||
                          val.statusAngsuran === "Terbayar"
                            ? "grey"
                            : "#ffa534",
                        border: 0,
                        marginTop: 5,
                        padding: "6px 15px",
                        fontSize: "18px",
                        cursor:
                          data.statusPembayaran === "Lunas" ||
                          val.statusAngsuran === "Terbayar"
                            ? "not-allowed"
                            : "pointer",
                      }}
                      onClick={() => {
                        setIdAngsuran(val.idAngsuran);
                        setIdPengajuan(val.idPengajuan);
                        setIdUser(val.idUser);
                        setNominalAdmin(val.nominalAdministrasi);
                        setNominalBayar(val.nominalAngsuran);
                        setTampilNominalAngsuran(val.nominalAngsuran);
                        setTampilAdministrasi(val.nominalAdministrasi);
                        setTampilJatuhTempo(val.dateTempo);
                        setModalUbahSimpanan(val);
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </Button>
                  </OverlayTrigger>
                )}
              </div>
            </>
          ),
        };
      });
    setDetailListAngsuran(tmp);
    setTotalAdministrasiBelumTerbayarkan(totalAdministrasiBelumTerbayarkan);
    setTotalAngsuranBelumTerbayarkan(totalAngsuranBelumTerbayarkan);
    setNominalBayar(totalAngsuranBelumTerbayarkan);
    setNominalAdmin(totalAdministrasiBelumTerbayarkan);
  }, [pengajuan.listAllAngsuran]);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colDetailAngsuran = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row) => detailListAngsuran.indexOf(row) + 1,
    },
    {
      name: "Angsuran ID",
      minWidth: "280px",
      center: true,
      selector: (row) => row.idAngsuran,
    },

    {
      name: "Nominal Administrasi",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalAdministrasi);
        return formattedValue;
      },
    },
    {
      name: "Nominal Bayar Administrasi",
      minWidth: "220px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalBayarAdministrasi);
        return formattedValue;
      },
    },
    {
      name: "Nominal Angsuran",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalAngsuran);
        return formattedValue;
      },
    },
    {
      name: "Nominal Pembayaran",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalPembayaran);
        return formattedValue;
      },
    },
    {
      name: "Jatuh Tempo",
      minWidth: "180px",
      center: true,
      selector: (row) => formatDate(row.dateTempo),
    },
    {
      name: "Status Angsuran",
      minWidth: "200px",
      center: true,
      selector: (row) =>
        row.statusAngsuran === "Belum Terbayarkan"
          ? "Belum Terbayar"
          : row.statusKoperasi === "Terbayar"
          ? "Terbayar"
          : row.statusAngsuran,
      cell: (row) => {
        const statusAngsuran =
          row.statusAngsuran === "Belum Terbayarkan"
            ? "Belum Terbayar"
            : row.statusKoperasi === "Terbayar"
            ? "Terbayar"
            : row.statusAngsuran;
        const statusStyle = getStatus(row.statusAngsuran);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {statusAngsuran}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      omit: !(auth.role === "super admin" || auth.role === "admin"),
      selector: (row) => row.action,
    },
  ];

  const getStatus = (status) => {
    switch (status) {
      case "Belum Terbayarkan":
        return {
          fontWeight: "bold",
          backgroundColor: "#FEBEC0",
          textColor: "#FB404B",
        };
      case "Terbayar":
        return {
          fontWeight: "bold",
          backgroundColor: "#DBEFB5",
          textColor: "#87CB16",
        };
      default:
        return {};
    }
  };

  const handleBackClick = (idPengajuan) => {
    // setIdPengajuan(idPengajuan);
    dispatch({ type: "SET_GET_ALL_ANGSURAN", data: [] });
    dispatch({ type: "SET_MODAL_ANGSURAN", data: true });
  };

  const handleCloseUbahSimpanan = () => {
    setNominalBayar("");
    setNominalAdmin("");
  };

  const handleClosePelunasan = () => {
    setNominalBayar("");
    setNominalAdmin("");
  };

  //   function submitUbahSimpanan(e) {
  //     console.log(nominal, idSimpanan, "AAAA");
  //     e.preventDefault();
  //     if (nominal === "") {
  //       Swal.fire({
  //         title: "Nominal Harus Diisi",
  //         icon: "warning",
  //       });
  //       return;
  //     }
  //     ubahSimpanan({
  //       idSimpanan: idSimpanan,
  //       nominal: nominal,
  //     }).then((response) => {
  //       if (response.status === 200) {
  //         Swal.fire({
  //           position: "center",
  //           icon: "success",
  //           title: response.message,
  //         });
  //         setModalUbahSimpanan(false);
  //         getAllAngsuran(dispatch, {
  //           idUser: data.data.idUser,
  //           page: page + 1,
  //           limit: totalPerHalaman,
  //         });
  //         dispatch({ type: "SET_MODAL_SIMPANAN", data: true });
  //       } else if (response.status === 400) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: response.message,
  //         });
  //       }
  //     });
  //   }

  React.useEffect(() => {
    setIsLoading(true);
    getAllAngsuran(dispatch, {
      idPengajuan: data.data,
    })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, []);

  return (
    <>
      <Card.Body>
        <Button
          className="btn-transparent mr-2"
          type="button"
          variant="primary"
          size="md"
          style={{
            border: "none",
            backgroundColor: "#00BFFF",
            border: 0,
          }}
          onClick={handleBackClick}
        >
          <i className="fas fa-arrow-left"></i>
        </Button>
      </Card.Body>

      <Container fluid>
        {detailListAngsuran &&
        detailListAngsuran.length > 0 &&
        data.pencairan === true ? (
          <Card>
            <Card style={{ border: 0 }}></Card>
            {isLoading ? (
              <div
                className="loader-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "30vh",
                }}
              >
                <Dna
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  wrapperStyle={{}}
                  wrapperClass="dna-wrapper"
                />
                <p>Loading...</p>
              </div>
            ) : (
              <>
                <Card.Title
                  as="h3"
                  style={{
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    flex: 1,
                    marginBottom: 20,
                    marginLeft: 20,
                  }}
                >
                  list Angsuran
                </Card.Title>
                <Card.Body>
                  <div>
                    <DataTable
                      columns={colDetailAngsuran}
                      data={detailListAngsuran}
                      customStyles={customStyles}
                      paginationPerPage={totalPerHalaman}
                      progressPending={isLoading}
                      fixedHeader={true}
                      fixedHeaderScrollHeight={"500px"}
                    />
                    <div style={{ marginTop: 35 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      ></Row>
                    </div>
                  </div>
                </Card.Body>
              </>
            )}
          </Card>
        ) : null}
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  Detail Pengajuan
                  <hr></hr>
                </Card.Title>
              </Card.Header>

              <Card.Body>
                <Card style={{ border: 0 }}>
                  <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
                    <div className="row">
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          referensi id
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.idPengajuan}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          nama
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.user.nama}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          nomor pegawai
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.user.nopeg}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          perusahaan
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.perusahaan.namaPerusahaan}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          client
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.client.namaClient}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          cabang
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.cabang.namaCabang}
                          </Label>
                        </li>
                      </div>

                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          nominal permohonan
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.plafond !== 0
                              ? `Rp ${Number(valDetail.plafond).toLocaleString(
                                  "id-ID"
                                )}`
                              : "Rp 0"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          nominal Administrasi
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.nominalAdministrasi !== 0
                              ? `Rp ${Number(
                                  valDetail.nominalAdministrasi
                                ).toLocaleString("id-ID")}`
                              : "Rp 0"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          nominal Administrasi per bulan
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.nominalAdministrasiPerBulan !== 0
                              ? `Rp ${Number(
                                  valDetail.nominalAdministrasiPerBulan
                                ).toLocaleString("id-ID")}`
                              : "Rp 0"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          nominal bayar per bulan
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.nominalDibayarkanPerBulan !== 0
                              ? `Rp ${Number(
                                  valDetail.nominalDibayarkanPerBulan
                                ).toLocaleString("id-ID")}`
                              : "Rp 0"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          nominal sisa bayar
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.nominalSisaBayar !== 0
                              ? `Rp ${Number(
                                  valDetail.nominalSisaBayar
                                ).toLocaleString("id-ID")}`
                              : "Rp 0"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          nominal blokir
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.nominalBlokir !== 0
                              ? `Rp ${Number(
                                  valDetail.nominalBlokir
                                ).toLocaleString("id-ID")}`
                              : "Rp 0"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          nominal tunggakan
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.nominalTunggakan !== 0
                              ? `Rp ${Number(
                                  valDetail.nominalTunggakan
                                ).toLocaleString("id-ID")}`
                              : "Rp 0"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          nominal pencairan
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {isNaN(valDetail.nominalPencairan)
                              ? "-"
                              : valDetail.nominalPencairan !== 0
                              ? `Rp ${Number(
                                  valDetail.nominalPencairan
                                ).toLocaleString("id-ID")}`
                              : "Rp 0"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          tanggal pengajuan
                        </Label>
                        {valDetail.tglPengajuan ? (
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {formatDate(valDetail.tglPengajuan)}
                            </Label>
                          </li>
                        ) : (
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              -
                            </Label>
                          </li>
                        )}
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          tanggal pencairan
                        </Label>
                        {valDetail.tglPencairan ? (
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {formatDate(valDetail.tglPencairan)}
                            </Label>
                          </li>
                        ) : (
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              -
                            </Label>
                          </li>
                        )}
                      </div>

                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          angsuran ke
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.angsuranKe}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          tanggal selesai angsuran
                        </Label>
                        {valDetail.tglSelesaiAngsuran ? (
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {formatDate(valDetail.tglSelesaiAngsuran)}
                            </Label>
                          </li>
                        ) : (
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              -
                            </Label>
                          </li>
                        )}
                      </div>

                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          tenor
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {`${valDetail.tenor} Bulan`}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          status pembayaran
                        </Label>
                        {valDetail.tglPencairan ? (
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {valDetail.statusPembayaran
                                ? valDetail.statusPembayaran
                                : "-"}
                            </Label>
                          </li>
                        ) : (
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              -
                            </Label>
                          </li>
                        )}
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          status pengajuan
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.statusPengajuan}
                          </Label>
                        </li>
                      </div>
                      <div className="col-6">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          tipe pengajuan
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.typePengajuan}
                          </Label>
                        </li>
                      </div>
                    </div>
                  </div>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* <Card>
          {isLoading ? (
            <div
              className="loader-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
              <p>Loading...</p>
            </div>
          ) : (
            <div>
              <DataTable
                columns={colDetailAngsuran}
                data={detailListAngsuran}
                customStyles={customStyles}
                paginationPerPage={totalPerHalaman}
                progressPending={isLoading}
              />
              <div style={{ marginTop: 35 }}>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                ></Row>
              </div>
            </div>
          )}
        </Card> */}

        <Card>
          <Card.Header>
            <Card.Title
              as="h3"
              style={{
                color: "black",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              history pengajuan
              <hr></hr>
            </Card.Title>
          </Card.Header>
          <div
            style={{
              maxHeight: "500px",
              overflowY: "scroll",
            }}
          >
            <VerticalTimeline lineColor="#113946">
              {valDetail.history.map((item, index) => (
                <VerticalTimelineElement
                  className="vertical-timeline-element--education"
                  contentStyle={{
                    borderTop:
                      item.statusPengajuan === "Menunggu"
                        ? "7px solid  rgb(255, 175, 5)"
                        : item.status === "Diproses"
                        ? "7px solid  rgb(250, 93, 2)"
                        : item.status === "Disetujui"
                        ? "7px solid  rgb(90, 201, 103)"
                        : item.status === "Revisi"
                        ? "7px solid  rgb(255, 43, 75)"
                        : "7px solid  rgb(255, 15, 0)",
                  }}
                  key={index}
                  date={moment(item.date).format("DD MMM YYYY HH:mm:ss")}
                  iconStyle={{
                    background:
                      item.status === "Menunggu"
                        ? "#FFAF05"
                        : item.status === "Diproses"
                        ? "#fa5d02"
                        : item.status === "Disetujui"
                        ? "#5AC967"
                        : item.status === "Revisi"
                        ? "#FF2B4B"
                        : "#E10F00",
                    color: "#fff",
                  }}
                >
                  <h4
                    className="vertical-timeline-element-title"
                    style={{ fontWeight: "bold" }}
                  >
                    {item.status}
                  </h4>
                  <div style={{ marginTop: "15px" }}>
                    <Row>
                      <Col sm="3">
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 14,
                            color: "black",
                          }}
                        >
                          Keterangan
                        </label>
                      </Col>
                      <Col sm="o">
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 14,
                            color: "black",
                          }}
                        >
                          :
                        </label>
                      </Col>
                      <Col sm="6">
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 14,
                            color: "black",
                          }}
                        >
                          {!item.keterangan || item.keterangan === ""
                            ? "-"
                            : item.keterangan}
                        </label>
                      </Col>
                    </Row>
                  </div>
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          </div>
        </Card>
      </Container>

      <Modal
        size="lg"
        show={modalUbahSimpanan}
        onHide={() => {
          handleCloseUbahSimpanan();
          setModalUbahSimpanan(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                        Update Status Angsuran
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Card style={{ border: 0 }}>
                      <hr></hr>
                      <Card.Body>
                        <Card.Title
                          style={{ fontWeight: "bold", marginBottom: 8 }}
                        >
                          Detail Angsuran
                        </Card.Title>
                        <div className="col-12">
                          <Row>
                            <Col sm="5">
                              <li>
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 16,
                                    color: "black",
                                  }}
                                >
                                  Nominal Administrasi
                                </label>
                              </li>
                            </Col>
                            <Col sm="o">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  color: "black",
                                }}
                              >
                                :
                              </label>
                            </Col>
                            <Col sm="6">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  color: "black",
                                }}
                              >
                                {tampilAdministrasi !== 0
                                  ? `Rp ${Number(
                                      tampilAdministrasi
                                    ).toLocaleString("id-ID")}`
                                  : "Rp 0"}
                              </label>
                            </Col>
                          </Row>
                        </div>

                        <div className="col-12">
                          <Row>
                            <Col sm="5">
                              <li>
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 16,
                                    color: "black",
                                  }}
                                >
                                  Nominal Angsuran
                                </label>
                              </li>
                            </Col>
                            <Col sm="o">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  color: "black",
                                }}
                              >
                                :
                              </label>
                            </Col>
                            <Col sm="6">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  color: "black",
                                }}
                              >
                                {tampilNominalAngsuran !== 0
                                  ? `Rp ${Number(
                                      tampilNominalAngsuran
                                    ).toLocaleString("id-ID")}`
                                  : "Rp 0"}
                              </label>
                            </Col>
                          </Row>
                        </div>
                        <div className="col-12">
                          <Row>
                            <Col sm="5">
                              <li>
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 16,
                                    color: "black",
                                  }}
                                >
                                  Jatuh Tempo
                                </label>
                              </li>
                            </Col>
                            <Col sm="o">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  color: "black",
                                }}
                              >
                                :
                              </label>
                            </Col>
                            <Col sm="6">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  color: "black",
                                }}
                              >
                                {formatDate(tampilJatuhTempo)}
                              </label>
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                      <hr></hr>
                    </Card>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nominal Pembayaran
                          </label>
                          <p>
                            <CurrencyInput
                              style={{
                                padding: 5,
                                borderRadius: 4,
                                width: "100%",
                                height: "40px",
                                borderWidth: "1px",
                                borderTopColor: "rgba(227, 227, 227, 0.3)",
                                borderLeftColor: "rgba(227, 227, 227, 0.3)",
                                borderBottomColor: "rgba(227, 227, 227, 1)",
                                borderRightColor: "rgba(227, 227, 227, 1)",
                                cursor: "not-allowed",
                              }}
                              name="nominal"
                              placeholder="Masukkan Nominal Pembayaran"
                              prefix="Rp "
                              value={nominalBayar}
                              disabled
                              groupSeparator="."
                              decimalSeparator=","
                              onValueChange={(value) => {
                                setNominalBayar(value);
                              }}
                            />
                          </p>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nominal Bayar Administrasi
                          </label>
                          <p>
                            <CurrencyInput
                              style={{
                                padding: 5,
                                borderRadius: 4,
                                width: "100%",
                                height: "40px",
                                borderWidth: "1px",
                                borderTopColor: "rgba(227, 227, 227, 0.3)",
                                borderLeftColor: "rgba(227, 227, 227, 0.3)",
                                borderBottomColor: "rgba(227, 227, 227, 1)",
                                borderRightColor: "rgba(227, 227, 227, 1)",
                                cursor: "not-allowed",
                              }}
                              name="nominal"
                              placeholder="Masukkan Nominal Bayar Administrasi"
                              prefix="Rp "
                              value={nominalAdmin}
                              disabled
                              groupSeparator="."
                              decimalSeparator=","
                              onValueChange={(value) => {
                                setNominalAdmin(value);
                              }}
                            />
                          </p>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitUpdateAngsuran}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalPelunasan}
        onHide={() => {
          handleClosePelunasan();
          setModalPelunasan(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                        Pelunasan
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Card style={{ border: 0 }}>
                      <hr></hr>
                      <Card.Body>
                        <Card.Title
                          style={{ fontWeight: "bold", marginBottom: 8 }}
                        >
                          Detail Angsuran
                        </Card.Title>
                        <div className="col-12">
                          <Row>
                            <Col sm="5">
                              <li>
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 16,
                                    color: "black",
                                  }}
                                >
                                  Nominal Administrasi
                                </label>
                              </li>
                            </Col>
                            <Col sm="o">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  color: "black",
                                }}
                              >
                                :
                              </label>
                            </Col>
                            <Col sm="6">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  color: "black",
                                }}
                              >
                                {totalAdministrasiBelumTerbayarkan !== 0
                                  ? formattedValue(
                                      totalAdministrasiBelumTerbayarkan
                                    )
                                  : "Rp 0"}
                              </label>
                            </Col>
                          </Row>
                        </div>
                        <div className="col-12">
                          <Row>
                            <Col sm="5">
                              <li>
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 16,
                                    color: "black",
                                  }}
                                >
                                  Nominal Angsuran
                                </label>
                              </li>
                            </Col>
                            <Col sm="o">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  color: "black",
                                }}
                              >
                                :
                              </label>
                            </Col>
                            <Col sm="6">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  color: "black",
                                }}
                              >
                                {totalAngsuranBelumTerbayarkan !== 0
                                  ? formattedValue(
                                      totalAngsuranBelumTerbayarkan
                                    )
                                  : "Rp 0"}
                              </label>
                            </Col>
                          </Row>
                        </div>
                        <div className="col-12">
                          <Row>
                            <Col sm="5">
                              <li>
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 16,
                                    color: "black",
                                  }}
                                >
                                  Jatuh Tempo
                                </label>
                              </li>
                            </Col>
                            <Col sm="o">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  color: "black",
                                }}
                              >
                                :
                              </label>
                            </Col>
                            <Col sm="6">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  color: "black",
                                }}
                              >
                                {formatDate(tampilJatuhTempo)}
                              </label>
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                      <hr></hr>
                    </Card>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nominal Pembayaran
                          </label>
                          <p>
                            <CurrencyInput
                              style={{
                                padding: 5,
                                borderRadius: 4,
                                width: "100%",
                                height: "40px",
                                borderWidth: "1px",
                                borderTopColor: "rgba(227, 227, 227, 0.3)",
                                borderLeftColor: "rgba(227, 227, 227, 0.3)",
                                borderBottomColor: "rgba(227, 227, 227, 1)",
                                borderRightColor: "rgba(227, 227, 227, 1)",
                                cursor: "not-allowed",
                              }}
                              name="nominal"
                              value={totalAngsuranBelumTerbayarkan}
                              placeholder="Masukkan Nominal Pembayaran"
                              prefix="Rp "
                              disabled
                              groupSeparator="."
                              decimalSeparator=","
                              onValueChange={(value) => {
                                setNominalBayar(value);
                              }}
                            />
                          </p>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nominal Bayar Administrasi
                          </label>
                          <p>
                            <CurrencyInput
                              style={{
                                padding: 5,
                                borderRadius: 4,
                                width: "100%",
                                height: "40px",
                                borderWidth: "1px",
                                borderTopColor: "rgba(227, 227, 227, 0.3)",
                                borderLeftColor: "rgba(227, 227, 227, 0.3)",
                                borderBottomColor: "rgba(227, 227, 227, 1)",
                                borderRightColor: "rgba(227, 227, 227, 1)",
                                cursor: "not-allowed",
                              }}
                              name="nominal"
                              value={totalAdministrasiBelumTerbayarkan}
                              placeholder="Masukkan Nominal Bayar Administrasi"
                              prefix="Rp "
                              disabled
                              groupSeparator="."
                              decimalSeparator=","
                              onValueChange={(value) => {
                                setNominalAdmin(value);
                              }}
                            />
                          </p>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitPelunasan}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DetailAngsuran;
