const initialState = {
  listAllPengajuan: null,
  listPengajuanNonCair: null,
  estimasiNonCair: null,
  listAllAngsuran: [],
  detailPengajuan: [],
  modalDetailAngsuran: true,
};

const pengajuanReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_GETALL_PENGAJUAN":
      return {
        ...state,
        listAllPengajuan: data,
      };
    case "SET_GETALL_PENGAJUAN_NON_CAIR":
      return {
        ...state,
        listPengajuanNonCair: data,
      };
    case "SET_NON_CAIR_ESTIMASI":
      return {
        ...state,
        estimasiNonCair: data,
      };
    case "SET_GET_ALL_ANGSURAN":
      return {
        ...state,
        listAllAngsuran: data,
      };
    case "SET_DETAIL_PENGAJUAN":
      return {
        ...state,
        detailPengajuan: data,
      };
    case "SET_MODAL_ANGSURAN":
      return {
        ...state,
        modalDetailAngsuran: data,
      };
    default:
      return state;
  }
};

export default pengajuanReducer;
