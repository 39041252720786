import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";

// export async function getAllAngsuran(dispatch, data, history) {
//   try {
//     const response = await baseAxios.get(
//       `webAngsuran/getAngsuran?idPengajuan=LOAN001-172981700634430330`,
//       {
//         headers: { token: localStorage.getItem("token") },
//       }
//     );

//     dispatch({
//       type: "SET_GETALL_ANGSURAN",
//       data: response.data.data,
//     });
//     console.log(response, "get data angsuran dari rafi");
//     return response.status;
//   } catch (err) {
//     return err.response;
//   }
// }

export async function updateBayarAngsuran(data) {
  try {
    const response = await baseAxios.post("webAngsuran/updateAngsuran", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function pelunasan(data) {
  try {
    const response = await baseAxios.post("webAngsuran/pelunasan", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function updateAllAngsuran(data) {
  try {
    const response = await baseAxios.post(
      "webAngsuran/autoUpdateAngsuran",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
