import { baseAxios, errorHandler } from "../index";

export async function totalPenyaluran(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webDashboard/totalPenyaluran",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_TOTAL_PENYALURAN", data: response.data });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function totalNominalLunas(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webDashboard/totalNominalLunas",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_TOTAL_NOMINAL_LUNAS", data: response.data });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function totalNominalOngoing(dispatch, data, history) {
  // console.log(data, "KKK");
  try {
    const response = await baseAxios.post(
      "webDashboard/totalNominalOngoing",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_TOTAL_NOMINAL_ONGOING", data: response.data });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function totalAllPengajuan(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webDashboard/totalAllPengajuan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_TOTAL_ALL_PENGAJUAN", data: response.data });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function getTotalKaryawan(dispatch, data, history) {
  try {
    const response = await baseAxios.get("webDashboard/totalKaryawan", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_TOTAL_KARYAWAN", data: response.data });
    // console.log(response, "RESPONSE ENPOINT");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function totalSimpanan(dispatch, data, history) {
  // console.log(data, "DATA");
  try {
    const response = await baseAxios.post("webDashboard/totalSimpanan", data, {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_TOTAL_SIMPANAN", data: response.data });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
