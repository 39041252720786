import React from "react";
import ChartistGraph from "react-chartist";
import { VictoryPie, VictoryTooltip, VictoryLabel } from "victory";
import { VectorMap } from "react-jvectormap";
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  OverlayTrigger,
  Table,
  Tooltip,
  Container,
  Row,
  Col,
  Alert,
  Image,
} from "react-bootstrap";
import moment from "moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ColorRing, Dna } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";
import ReactDatetime from "react-datetime";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { totalPenyaluran } from "stores";
import { totalNominalLunas } from "stores";
import { totalNominalOngoing } from "stores";
import { totalAllPengajuan } from "stores";
import { getTotalKaryawan } from "stores";
import { totalSimpanan } from "stores";

function DashboardUtama() {
  const dispatch = useDispatch();
  const reducerDashboard = useSelector((state) => state.dashboardUtamaReducer);
  // console.log(reducerDashboard, "QQQQ");
  const [dariTgl, setDariTgl] = React.useState(() => {
    const twoYearsAgo = new Date();
    twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 1);
    twoYearsAgo.setMonth(0);
    twoYearsAgo.setDate(1);
    twoYearsAgo.setHours(0, 0, 0, 0);
    return twoYearsAgo.getTime();
  });
  const [smpTgl, setSmpTgl] = React.useState(new Date());
  const totalSisaBayarPokokLunas =
    reducerDashboard.ambilTotalNominalLunas.totalPokok;
  const totalSisaBayarAdminLunas =
    reducerDashboard.ambilTotalNominalLunas.totalAdmin;
  const totalJumlahLunas = totalSisaBayarPokokLunas + totalSisaBayarAdminLunas;

  const totalSisaBayarPokok =
    reducerDashboard.ambilTotalNominalOnGoing.totalSisaBayarPokok;
  const totalSisaBayarAdmin =
    reducerDashboard.ambilTotalNominalOnGoing.totalSisaBayarAdmin;
  const totalJumlahOnGoing = totalSisaBayarPokok + totalSisaBayarAdmin;

  const currentYear = new Date().getFullYear();
  const years = [];
  years.push({ value: "semua", label: "Semua" });
  for (let i = currentYear; i >= currentYear - 4; i--) {
    years.push({ value: String(i), label: String(i) });
  }
  const [selectedYear, setSelectedYear] = React.useState(years[0].value);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleYearChange = (selectedOption) => {
    setIsLoading(true);
    setSelectedYear(selectedOption.value);
  };
  const defaultOption = "semua";
  const defaultIndex = years.findIndex((year) => year.value === defaultOption);
  if (defaultIndex !== -1) {
    const defaultYear = years.splice(defaultIndex, 1)[0];
    years.unshift(defaultYear);
  }
  const formatRupiah = (angka) => {
    const numberFormat = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    return numberFormat.format(angka);
  };

  React.useEffect(() => {
    totalPenyaluran(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    totalNominalLunas(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    totalNominalOngoing(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    totalAllPengajuan(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    });
  }, [dariTgl, smpTgl]);

  // React.useEffect(() => {
  //   if (!isLoading) {
  //     totalSimpanan(dispatch, {
  //       tahun: selectedYear,
  //     });
  //   }
  // }, [selectedYear, isLoading]);

  React.useEffect(() => {
    totalSimpanan(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getTotalKaryawan(dispatch);
  }, []);

  return (
    <>
      <Card
        style={{
          // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          border: 0,
          borderRadius: 10,
          marginTop: 15,
        }}
      >
        <div style={{ maxHeight: "800px" }}>
          <Row>
            <Container className="px-2 text-center">
              <Col>
                <Row>
                  <Col className="d-flex align-items-center justify-content-start">
                    <img
                      alt="..."
                      src={require("assets/img/pugar.png").default}
                      width="auto"
                      height="150px"
                    ></img>
                  </Col>
                  <Col className=" justify-content-end">
                    <Row>
                      <Col md="6">
                        <h4
                          className="title mt-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Tanggal Awal
                        </h4>
                        <Form.Group
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          }}
                        >
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(0, 0, 0, 0),
                                setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <h4
                          className="title mt-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Tanggal Akhir
                        </h4>
                        <Form.Group
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          }}
                        >
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(23, 59, 0, 0),
                                setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                            }}
                            value={smpTgl}
                            initialValue={smpTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              {/* <Col
                      lg={{ span: 6, offset: 6 }}
                      style={{
                        marginBottom: 20,
                        // marginLeft: 10,
                        marginRight: 10,
                      }}
                    >
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        // value={status}
                        // onChange={(selectedOption) => setStatus(selectedOption)}
                        // onChange={setStatus}
                        options={[
                          { value: "true", label: "Aktif" },
                          { value: "false", label: "Tidak Aktif" },
                        ]}
                        placeholder="Pilih"
                      />
                    </Col> */}
            </Container>

            <Col md="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: 10,
                  marginLeft: 10,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#7A5DBE",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Card.Title
                    as="h6"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    <b style={{ color: "white", fontSize: 20 }}>
                      Total Penyaluran
                    </b>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <div
                    style={{
                      fontSize: 20,
                      textAlign: "center",
                      marginTop: 10,
                      color: "#212121",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 20,
                        textAlign: "center",
                        marginTop: 10,
                        color: "#212121",
                      }}
                    >
                      {reducerDashboard.ambilTotalPenyaluran.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={200} />
                        </SkeletonTheme>
                      ) : (
                        formatRupiah(
                          reducerDashboard.ambilTotalPenyaluran.totalPenyaluran
                        )
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: 10,
                  marginRight: 10,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#7A5DBE",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Card.Title
                    as="h6"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    <b style={{ color: "white", fontSize: 20 }}>
                      Total Simpanan
                    </b>
                  </Card.Title>
                </Card.Header>
                <Card.Body
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <>
                    <Col
                      md="12"
                      style={{
                        fontSize: 20,
                        color: "#212121",
                        // marginLeft: 20,
                        textAlign: "center",
                      }}
                    >
                      {reducerDashboard.ambilTotalSimpanan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={120} />
                        </SkeletonTheme>
                      ) : (
                        formatRupiah(
                          reducerDashboard.ambilTotalSimpanan
                            .totalNominalSimpanan
                        )
                      )}
                    </Col>

                    {/* <Col md="4">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        options={years}
                        defaultValue={years[0]}
                        onChange={handleYearChange}
                      />
                    </Col> */}
                  </>
                </Card.Body>
              </Card>
            </Col>

            <Col md="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: 10,
                  marginLeft: 10,
                  // marginTop: 20,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#7A5DBE",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Card.Title
                    as="h6"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    <b style={{ color: "white" }}>Total Lunas</b>
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{ textAlign: "center", fontSize: 25 }}>
                  {isNaN(totalJumlahLunas) ? (
                    <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                      <Skeleton height={"100%"} width={120} />
                    </SkeletonTheme>
                  ) : (
                    formatRupiah(totalJumlahLunas)
                  )}
                </Card.Body>
                <Row>
                  <Col
                    className="p-2 d-flex justify-content-center"
                    style={{ fontWeight: "bold" }}
                  >
                    Pokok
                  </Col>

                  <Col
                    className="p-2 d-flex justify-content-center"
                    style={{ fontWeight: "bold" }}
                  >
                    Admin
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="p-2 d-flex justify-content-center"
                    // style={{ fontWeight: "bold" }}
                  >
                    {reducerDashboard.ambilTotalNominalLunas.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={90} />
                      </SkeletonTheme>
                    ) : (
                      formatRupiah(
                        reducerDashboard.ambilTotalNominalLunas.totalPokok
                      )
                    )}
                  </Col>

                  <Col className="p-2 d-flex justify-content-center">
                    {reducerDashboard.ambilTotalNominalLunas.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={90} />
                      </SkeletonTheme>
                    ) : (
                      formatRupiah(
                        reducerDashboard.ambilTotalNominalLunas.totalAdmin
                      )
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: 10,
                  marginRight: 10,
                  // marginTop: 20,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#7A5DBE",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Card.Title
                    as="h6"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    <b style={{ color: "white" }}>Total On Going</b>
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{ textAlign: "center", fontSize: 25 }}>
                  {isNaN(totalJumlahOnGoing) ? (
                    <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                      <Skeleton height={"100%"} width={120} />
                    </SkeletonTheme>
                  ) : (
                    formatRupiah(totalJumlahOnGoing)
                  )}
                </Card.Body>
                <Row>
                  <Col
                    className="p-2 d-flex justify-content-center"
                    style={{ fontWeight: "bold" }}
                  >
                    Pokok
                  </Col>

                  <Col
                    className="p-2 d-flex justify-content-center"
                    style={{ fontWeight: "bold" }}
                  >
                    Admin
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="p-2 d-flex justify-content-center"
                    // style={{ fontWeight: "bold" }}
                  >
                    {reducerDashboard.ambilTotalNominalOnGoing.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={90} />
                      </SkeletonTheme>
                    ) : (
                      formatRupiah(
                        reducerDashboard.ambilTotalNominalOnGoing
                          .totalSisaBayarPokok
                      )
                    )}
                  </Col>
                  <Col
                    className="p-2 d-flex justify-content-center"
                    // style={{ fontWeight: "bold" }}
                  >
                    {reducerDashboard.ambilTotalNominalOnGoing.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={90} />
                      </SkeletonTheme>
                    ) : (
                      formatRupiah(
                        reducerDashboard.ambilTotalNominalOnGoing
                          .totalSisaBayarAdmin
                      )
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Card>

      <Container fluid>
        <Row>
          <Col lg="6" sm="6">
            <h4
              className="title mt-2"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              informasi
              <hr></hr>
            </h4>
            <Row>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#624B99",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Total Karyawan</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {reducerDashboard.totalKaryawan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={50} />
                      </SkeletonTheme>
                    ) : (
                      reducerDashboard.totalKaryawan.totalUser
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#624B99",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Total Lunas</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {reducerDashboard.ambilTotalNominalLunas.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={50} />
                      </SkeletonTheme>
                    ) : (
                      reducerDashboard.ambilTotalNominalLunas
                        .jumlahPengajuanLunas
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#624B99",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Total Angsur</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {reducerDashboard.ambilTotalNominalOnGoing.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={50} />
                      </SkeletonTheme>
                    ) : (
                      reducerDashboard.ambilTotalNominalOnGoing
                        .jumlahPengajuanAngsur
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#624B99",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Total Anggota Koperasi</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {reducerDashboard.totalKaryawan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={50} />
                      </SkeletonTheme>
                    ) : (
                      reducerDashboard.totalKaryawan.anggotaKoperasi
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#624B99",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Bukan Anggota Koperasi</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {reducerDashboard.totalKaryawan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={50} />
                      </SkeletonTheme>
                    ) : (
                      reducerDashboard.totalKaryawan.bukanAnggotaKoperasi
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#624B99",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>User Menunggu Approve</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {reducerDashboard.totalKaryawan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={50} />
                      </SkeletonTheme>
                    ) : (
                      reducerDashboard.totalKaryawan.userMenungguRegister
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#624B99",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>User Lama Belum Register</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {reducerDashboard.totalKaryawan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={50} />
                      </SkeletonTheme>
                    ) : (
                      reducerDashboard.totalKaryawan.userLamaBelumRegister
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg="6" sm="6">
            <h4
              className="title mt-2"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              status pengajuan
              <hr></hr>
            </h4>
            <Row>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#624B99",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Total Pengajuan</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {reducerDashboard.ambilTotalAllPengajuan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={50} />
                      </SkeletonTheme>
                    ) : (
                      reducerDashboard.ambilTotalAllPengajuan.totalPengajuan
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#624B99",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Menunggu</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {reducerDashboard.ambilTotalAllPengajuan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={50} />
                      </SkeletonTheme>
                    ) : (
                      reducerDashboard.ambilTotalAllPengajuan.totalMenunggu
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#624B99",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Diproses</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {reducerDashboard.ambilTotalAllPengajuan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={50} />
                      </SkeletonTheme>
                    ) : (
                      reducerDashboard.ambilTotalAllPengajuan.totalDiproses
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#624B99",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Disetujui</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {reducerDashboard.ambilTotalAllPengajuan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={50} />
                      </SkeletonTheme>
                    ) : (
                      reducerDashboard.ambilTotalAllPengajuan.totalDisetujui
                    )}
                  </Card.Body>
                </Card>
              </Col>

              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#624B99",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Revisi</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {reducerDashboard.ambilTotalAllPengajuan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={50} />
                      </SkeletonTheme>
                    ) : (
                      reducerDashboard.ambilTotalAllPengajuan.totalRevisi
                    )}
                  </Card.Body>
                </Card>
              </Col>

              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#624B99",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Ditolak</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {reducerDashboard.ambilTotalAllPengajuan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={50} />
                      </SkeletonTheme>
                    ) : (
                      reducerDashboard.ambilTotalAllPengajuan.totalDitolak
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Grafik Status Pengajuan</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="8" style={{}}>
                    <div style={{ padding: 5, display: "flex" }}>
                      <VictoryPie
                        style={{
                          labels: { fill: "white", fontWeight: "bold" },
                        }}
                        innerRadius={80}
                        labelRadius={90}
                        // labels={({ datum }) => `${datum.y}%`}
                        labelComponent={<CustomLabel />}
                        // data={totalStatus}
                        colorScale={[
                          "#23CCEF",
                          "#FB404B",
                          "#FFA534",
                          "#9368E9",
                          "#87CB16",
                        ]}
                      />
                    </div>
                  </Col>
                  <Col>
                    <p style={{ fontSize: 14 }}>
                      <i className="fas fa-circle mr-1 text-info"></i>
                      Menunggu
                    </p>
                    <p style={{ fontSize: 14 }}>
                      <i className="fas fa-circle mr-1 text-danger"></i>
                      Ditolak
                    </p>
                    <p style={{ fontSize: 14 }}>
                      <i className="fas fa-circle mr-1 text-warning"></i>
                      Revisi
                    </p>
                    <p style={{ fontSize: 14 }}>
                      <i
                        className="fas fa-circle mr-1"
                        style={{ color: "#9368E9" }}
                      ></i>
                      Diproses
                    </p>
                    <p style={{ fontSize: 14 }}>
                      <i className="fas fa-circle mr-1 text-success"></i>
                      Disetujui
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
      </Container>
    </>
  );
}

export default DashboardUtama;
