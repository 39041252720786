const initialState = {
  listNotif: null,
};

const notifReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_NOTIFICATION":
      return {
        ...state,
        listNotif: data,
      };
    default:
      return state;
  }
};

export default notifReducer;
