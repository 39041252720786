import React from "react";
import Swal from "sweetalert2";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import moment from "moment";
import "moment/locale/id";
import { getAllProfile } from "stores";
import { useDispatch, useSelector } from "react-redux";
// import { ImageCompressor } from "image-compressor";
// import { compress } from "image-compressor";

function UserProfile() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const user = useSelector((state) => state.userReducer);
  // console.log(user, "pppppppppppp");
  const [profileUser, setProfileUser] = React.useState([]);

  React.useEffect(() => {
    let tmp =
      user.userProfile &&
      user.userProfile.data &&
      user.userProfile.data.map((val, index) => {
        return {
          ...val,
        };
      });
    setProfileUser(tmp);
    console.log(tmp, "TMP");
  }, [user.userProfile]);

  React.useEffect(() => {
    getAllProfile(dispatch);
  }, []);
  return (
    <>
      <Modal
        size="lg"
        // show={modalEditFoto}
        // onHide={() => {
        //   // handleModalClose();
        //   setModalEditFoto(false);
        // }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h5"
                style={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontFamily: "sans-serif",
                }}
              >
                Edit Foto Profile
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Row>
                  <Col md="12">
                    <Form.Control
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      //   onChange={(e) => {
                      //     setFoto(e.target.files[0]);
                      //     setSizeFile(e.target.files[0].size / 1000000);
                      //   }}
                    ></Form.Control>
                    <label
                      style={{
                        textTransform: "none",
                        color: "red",
                        fontSize: 12,
                      }}
                    >
                      *Ukuran file harus kurang dari 1 MB
                    </label>
                    <p
                      style={{
                        // marginTop: 5,
                        marginLeft: 5,
                        color: "blue",
                        fontWeight: "bold",
                        fontSize: 14,
                      }}
                    >
                      {/* {sizeFile.toFixed(3)} MB{" "} */}
                    </p>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                //
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                // onClick={submitUpdateFoto}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Container fluid>
        <div className="section-image" data-image="../../assets/img/bg5.jpg">
          {/* you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " */}
          <Row>
            <Col md="12">
              <Card>
                {/* <Card.Header> */}
                {/* <Card.Header> */}
                <Card.Title
                  as="h4"
                  style={{ fontWeight: "bold", marginTop: 10, marginLeft: 15 }}
                >
                  Profile
                  <hr></hr>
                </Card.Title>
                <Card.Body>
                  <Form action="" className="form" method="">
                    <Row>
                      <Col className="pl-3" md="6">
                        <Form.Group>
                          <li>
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#303030",
                                fontSize: 16,
                              }}
                            >
                              Nama
                            </label>
                          </li>
                          <Form.Control
                            style={{ fontWeight: "bold", color: "#888888" }}
                            defaultValue={auth.nama}
                            disabled
                            placeholder="Company"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-3" md="6">
                        <Form.Group>
                          <li>
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#303030",
                                fontSize: 16,
                              }}
                            >
                              Nomor Pegawai
                            </label>
                          </li>
                          <Form.Control
                            style={{ fontWeight: "bold", color: "#888888" }}
                            defaultValue={auth.nopeg}
                            disabled
                            placeholder="Username"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-3" md="6">
                        <Form.Group>
                          <li>
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#303030",
                                fontSize: 16,
                              }}
                            >
                              nik
                            </label>
                          </li>
                          <Form.Control
                            style={{ fontWeight: "bold", color: "#888888" }}
                            value={
                              profileUser &&
                              profileUser[0] &&
                              profileUser[0].nik
                                ? profileUser[0].nik
                                : "-"
                            }
                            disabled
                            placeholder="Email"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-3" md="6">
                        <Form.Group>
                          <li>
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#303030",
                                fontSize: 16,
                              }}
                            >
                              status karyawan
                            </label>
                          </li>
                          <Form.Control
                            style={{ fontWeight: "bold", color: "#888888" }}
                            value={
                              profileUser &&
                              profileUser[0] &&
                              profileUser[0].statusKaryawan
                                ? profileUser[0].statusKaryawan
                                : "-"
                            }
                            disabled
                            placeholder="Email"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-3" md="6">
                        <Form.Group>
                          <li>
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#303030",
                                fontSize: 16,
                              }}
                            >
                              Email
                            </label>
                          </li>
                          <Form.Control
                            style={{ fontWeight: "bold", color: "#888888" }}
                            value={
                              profileUser &&
                              profileUser[0] &&
                              profileUser[0].email
                                ? profileUser[0].email
                                : "-"
                            }
                            disabled
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-3" md="6">
                        <Form.Group>
                          <li>
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#303030",
                                fontSize: 16,
                              }}
                            >
                              perusahaan
                            </label>
                          </li>
                          <Form.Control
                            style={{ fontWeight: "bold", color: "#888888" }}
                            value={
                              profileUser &&
                              profileUser[0] &&
                              profileUser[0].perusahaan.namaPerusahaan
                                ? profileUser[0].perusahaan.namaPerusahaan
                                : "-"
                            }
                            disabled
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-3" md="6">
                        <Form.Group>
                          <li>
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#303030",
                                fontSize: 16,
                              }}
                            >
                              Client
                            </label>
                          </li>
                          <Form.Control
                            style={{ fontWeight: "bold", color: "#888888" }}
                            value={
                              profileUser &&
                              profileUser[0] &&
                              profileUser[0].client.namaClient
                                ? profileUser[0].client.namaClient
                                : "-"
                            }
                            disabled
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-3" md="6">
                        <Form.Group>
                          <li>
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#303030",
                                fontSize: 16,
                              }}
                            >
                              Cabang
                            </label>
                          </li>
                          <Form.Control
                            style={{ fontWeight: "bold", color: "#888888" }}
                            value={
                              profileUser &&
                              profileUser[0] &&
                              profileUser[0].cabang.namaCabang
                                ? profileUser[0].cabang.namaCabang
                                : "-"
                            }
                            disabled
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-3" md="6">
                        <Form.Group>
                          <li>
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#303030",
                                fontSize: 16,
                              }}
                            >
                              Jabatan
                            </label>
                          </li>
                          <Form.Control
                            style={{ fontWeight: "bold", color: "#888888" }}
                            value={
                              profileUser &&
                              profileUser[0] &&
                              profileUser[0].jabatan
                                ? profileUser[0].jabatan
                                : "-"
                            }
                            disabled
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-3" md="6">
                        <Form.Group>
                          <li>
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#303030",
                                fontSize: 16,
                              }}
                            >
                              Nominal Simpanan
                            </label>
                          </li>
                          <Form.Control
                            style={{ fontWeight: "bold", color: "#888888" }}
                            value={
                              profileUser &&
                              profileUser[0] &&
                              profileUser[0].nominalSimpanan
                                ? profileUser[0].nominalSimpanan.toLocaleString(
                                    "id-ID",
                                    {
                                      style: "currency",
                                      currency: "IDR",
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                    }
                                  )
                                : "-"
                            }
                            disabled
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-3" md="6">
                        <Form.Group>
                          <li>
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#303030",
                                fontSize: 16,
                              }}
                            >
                              Nomor Telepon
                            </label>
                          </li>
                          <Form.Control
                            style={{ fontWeight: "bold", color: "#888888" }}
                            value={
                              profileUser &&
                              profileUser[0] &&
                              profileUser[0].noTelp
                                ? profileUser[0].noTelp
                                : "-"
                            }
                            disabled
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-3" md="6">
                        <Form.Group>
                          <li>
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#303030",
                                fontSize: 16,
                              }}
                            >
                              status koperasi
                            </label>
                          </li>
                          <Form.Control
                            style={{ fontWeight: "bold", color: "#888888" }}
                            value={
                              profileUser &&
                              profileUser[0] &&
                              profileUser[0].statusKoperasi
                                ? profileUser[0].statusKoperasi === "nonactive"
                                  ? "Tidak Aktif"
                                  : profileUser[0].statusKoperasi === "active"
                                  ? "Aktif"
                                  : profileUser[0].statusKoperasi === "waiting"
                                  ? "Menunggu"
                                  : "Keluar"
                                : "-"
                            }
                            disabled
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>

                  <div className="clearfix"></div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default UserProfile;
