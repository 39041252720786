const initialState = {
  totalKaryawan: [],
  ambilTotalNominalLunas: [],
  ambilTotalNominalOnGoing: [],
  ambilTotalPenyaluran: [],
  ambilTotalAllPengajuan: [],
  ambilTotalSimpanan: [],
};

const dashboardUtamaReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_TOTAL_KARYAWAN":
      return {
        ...state,
        totalKaryawan: data,
      };
    case "SET_TOTAL_NOMINAL_LUNAS":
      return {
        ...state,
        ambilTotalNominalLunas: data,
      };
    case "SET_TOTAL_NOMINAL_ONGOING":
      return {
        ...state,
        ambilTotalNominalOnGoing: data,
      };
    case "SET_TOTAL_PENYALURAN":
      return {
        ...state,
        ambilTotalPenyaluran: data,
      };
    case "SET_TOTAL_ALL_PENGAJUAN":
      return {
        ...state,
        ambilTotalAllPengajuan: data,
      };
    case "SET_TOTAL_SIMPANAN":
      return {
        ...state,
        ambilTotalSimpanan: data,
      };
    default:
      return state;
  }
};

export default dashboardUtamaReducer;
