import React from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  Container,
  Collapse,
  Dropdown,
  InputGroup,
  Row,
  Col,
  Modal,
  Form,
  Tab,
  Table,
  Image,
  FormGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getListPelunasan } from "stores/pelunasan/function";
import moment from "moment";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { Dna } from "react-loader-spinner";
import { Label } from "reactstrap";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

function DetailPelunsanan(data) {
  // console.log(data, "DATA");
  const dispatch = useDispatch();
  const listPelunasan = useSelector((state) => state.pelunasanReducer);
  const auth = useSelector((state) => state.authReducer);
  const detailAngsuran = listPelunasan.detailPelunasan;
  const history = data.detailPengajuan;
  const semuaVal = data.allVal;

  const [listAngsuran, setListAngsuran] = React.useState([]);
  const [detailListAngsuran, setDetailListAngsuran] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [filterStatus, setFilterStatus] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [isLoading, setIsLoading] = React.useState(true);
  const [listAllPelunasan, setListAllPelunasan] = React.useState([]);

  const handleFilterChange = () => {
    setLoading(true);
    setPage(0);
    getListPelunasan(dispatch, {
      statusPengajuan: pilihOpsi,
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        // setLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const handleBackClick = () => {
    dispatch({ type: "SET_DETAIL_PENGAJUAN_PELUNASAN", data: true });
  };

  React.useEffect(() => {
    let tmp =
      data.data &&
      data.data.map((val, index) => {
        // console.log(val, "VALL");
        return {
          ...val,
          action: <></>,
        };
      });
    setListAngsuran(tmp);
  }, [data.data]);

  React.useEffect(() => {
    let tmp =
      listPelunasan.listAllPelunasan &&
      listPelunasan.listAllPelunasan.docs.map((val, index) => {
        // console.log(val, "VALL");
        return {
          ...val,
          action: (
            <>
              {/* <div className="actions-right">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Detail</Tooltip>}
                >
                  <Button
                    className="mr-2"
                    // variant="info"
                    style={{
                      // marginTop: 5,
                      padding: "6px 15px",
                      fontSize: "18px",
                      backgroundColor: "#73B4E2",
                      border: 0,
                    }}
                    onClick={() => {
                      handleDetailClick(val);
                    }}
                  >
                    <i class="fas fa-info"></i>
                  </Button>
                </OverlayTrigger>
              </div> */}

              {/* <div className="actions-right">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Bukti Transfer</Tooltip>}
                >
                  <Button
                    className="ml-2"
                    style={{
                      marginTop: 5,
                      padding: "6px 15px",
                      fontSize: "18px",
                      marginBottom: 10,
                      border: 0,
                      borderColor: "white",
                    }}
                    onClick={() => {
                      openModal(val.buktiTfUser);
                    }}
                  >
                    <i class="far fa-image"></i>
                  </Button>
                </OverlayTrigger>
              </div> */}
            </>
          ),
        };
      });
    setListAllPelunasan(tmp || []);
  }, [listPelunasan.listAllPelunasan]);

  const getStatus = (status) => {
    switch (status) {
      case "Belum Terbayarkan":
        return {
          fontWeight: "bold",
          backgroundColor: "#FEBEC0",
          textColor: "#FB404B",
        };
      case "Terbayar":
        return {
          fontWeight: "bold",
          backgroundColor: "#DBEFB5",
          textColor: "#87CB16",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colPengajuanPelunasan = [
    {
      name: "No",
      width: "80px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "ID Angsuran",
      minWidth: "240px",
      center: true,
      selector: (row) => row.idAngsuran,
    },
    {
      name: "Jatuh Tempo",
      minWidth: "240px",
      center: true,
      selector: (row) => moment(row.dateTempo).format("DD MMMM YYYY"),
    },
    {
      name: "Nominal Administrasi",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalAdministrasi);
        return formattedValue;
      },
    },
    {
      name: "Nominal Bayar Administrasi",
      minWidth: "250px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalBayarAdministrasi);
        return formattedValue;
      },
    },
    {
      name: "Nominal Angsuran",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalAngsuran);
        return formattedValue;
      },
    },
    {
      name: "Nominal Bayar Angsuran",
      minWidth: "250px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalPembayaran);
        return formattedValue;
      },
    },

    {
      name: "Status Angsuran",
      minWidth: "200px",
      center: true,
      selector: (row) =>
        row.statusAngsuran === "Belum Terbayarkan"
          ? "Belum Terbayar"
          : row.statusAngsuran === "Terbayar"
          ? "Terbayar"
          : row.statusAngsuran,
      cell: (row) => {
        const statusAngsuran =
          row.statusAngsuran === "Belum Terbayarkan"
            ? "Belum Terbayar"
            : row.statusAngsuran === "Terbayar"
            ? "Terbayar"
            : row.statusAngsuran;
        const statusStyle = getStatus(row.statusAngsuran);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {statusAngsuran}
          </div>
        );
      },
    },
    // {
    //   name: "Aksi",
    //   minWidth: "300px",
    //   center: true,
    //   cell: (row) => row.action,
    // },
  ];

  React.useEffect(() => {
    setLoading(true);
    getListPelunasan(dispatch, {
      statusPengajuan:
        filterStatus === null || filterStatus.value === ""
          ? ""
          : filterStatus.value,
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        // setLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [page, totalPerHalaman]);

  return (
    <>
      <Card.Body>
        <Button
          className="btn-transparent mr-2"
          type="button"
          variant="primary"
          size="md"
          style={{
            border: "none",
            backgroundColor: "#00BFFF",
            border: 0,
          }}
          onClick={handleBackClick}
        >
          <i className="fas fa-arrow-left"></i>
        </Button>
      </Card.Body>

      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                >
                  Angsuran
                  <hr></hr>
                </Card.Title>
              </Card.Header>
              <div
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "8px",
                  padding: "10px 20px",
                  boxShadow: "0 3px 8px rgba(0, 1, 0, 0.3)",
                  width: "50%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
              >
                <div
                  style={{
                    textTransform: "uppercase",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Detail Yang Dibayarkan
                  <hr></hr>
                </div>

                <div className="row">
                  <div className="col-12">
                    <Row style={{ marginTop: 10 }}>
                      <Col sm="6">
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 16,
                            color: "black",
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          nominal pokok pelunasan
                        </label>
                      </Col>
                      <Col sm="o">
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          :
                        </label>
                      </Col>
                      <Col sm="5">
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(semuaVal.nominalPokokPelunasan)}
                        </label>
                      </Col>
                      <Col sm="6">
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 16,
                            color: "black",
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          nominal admin pelunasan
                        </label>
                      </Col>
                      <Col sm="o">
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          :
                        </label>
                      </Col>
                      <Col sm="5">
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(semuaVal.nominalAdminPelunasan)}
                        </label>
                      </Col>
                      <Col sm="6">
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 16,
                            color: "black",
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          nominal keseluruhan
                        </label>
                      </Col>
                      <Col sm="o">
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          :
                        </label>
                      </Col>
                      <Col sm="5">
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(semuaVal.nominalTotal)}
                        </label>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <Card.Body>
                <DataTable
                  columns={colPengajuanPelunasan}
                  data={listAngsuran}
                  customStyles={customStyles}
                  paginationPerPage={totalPerHalaman}
                  progressPending={loading}
                />
              </Card.Body>
            </Card>
            <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
              <Row>
                <Col md="6" style={{ margin: 0 }}>
                  <Card style={{ height: "100%" }}>
                    <Card.Header>
                      <Card.Title
                        as="h3"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          textAlign: "center",
                        }}
                      >
                        Bukti Transfer User
                        <hr />
                      </Card.Title>
                    </Card.Header>

                    <Card.Body
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <img
                        src={data.urlTfUser}
                        alt="Bukti Transfer"
                        style={{ maxWidth: "50%", maxHeight: "100%" }}
                      />
                    </Card.Body>
                  </Card>
                </Col>

                <Col md="6" style={{ margin: 0 }}>
                  <Card style={{ height: "100%" }}>
                    <Card.Header>
                      <Card.Title
                        as="h3"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          textAlign: "center",
                        }}
                      >
                        Bukti Transfer Masuk
                        <hr />
                      </Card.Title>
                    </Card.Header>

                    <Card.Body
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {data.urlTfMasuk ? (
                        <img
                          src={data.urlTfMasuk}
                          alt="Bukti Transfer"
                          style={{ maxWidth: "100%", maxHeight: "50%" }}
                        />
                      ) : (
                        <>
                          <div style={{ marginBottom: "1rem" }}>
                            <div
                              style={{
                                backgroundColor: "#E0D5FA",
                                color: "#703BE7",
                                borderRadius: "8px",
                                padding: "10px 15px",
                                boxShadow: "0 3px 8px rgba(0, 1, 0, 0.3)",
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              Maaf, masih belum ada bukti transfer masuk untuk
                              saat ini..
                            </div>
                          </div>
                          <img
                            src={require("assets/img/sorry.jpg").default}
                            alt="Sorry"
                            style={{ maxWidth: "50%", borderRadius: "5px" }}
                          />
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: 20 }}>
              <Card>
                <Card.Header>
                  <Card.Title
                    as="h3"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    Detail Pengajuan
                    <hr></hr>
                  </Card.Title>
                </Card.Header>

                <Card.Body>
                  <Card style={{ border: 0 }}>
                    <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
                      <div className="row">
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            referensi id
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {data.detailPengajuan.idPengajuan}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            referensi id sebelumnya
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {data.detailPengajuan.idPengajuanSebelumnya === ""
                                ? "-"
                                : data.detailPengajuan.idPengajuanSebelumnya}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            nominal pengajuan
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(data.detailPengajuan.plafond)}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            nominal administrasi
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(
                                data.detailPengajuan.nominalAdministrasi
                              )}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            nominal administrasi per bulan
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(
                                data.detailPengajuan.nominalAdministrasiPerBulan
                              )}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            nominal blokir
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(data.detailPengajuan.nominalBlokir)}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            nominal dibayarkan per bulan
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(
                                data.detailPengajuan.nominalDibayarkanPerBulan
                              )}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            nominal pencairan
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(data.detailPengajuan.nominalPencairan)}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            nominal sisa bayar
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(data.detailPengajuan.nominalSisaBayar)}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            nominal sisa bayar admin
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(
                                data.detailPengajuan.nominalSisaBayarAdmin
                              )}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            nominal sisa bayar pokok
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(
                                data.detailPengajuan.nominalSisaBayarPokok
                              )}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            nominal diterima
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(data.detailPengajuan.nominalTerima)}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            nominal tunggakan
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(data.detailPengajuan.nominalTunggakan)}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            status pembayaran
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {data.detailPengajuan.statusPembayaran}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            status pengajuan
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {data.detailPengajuan.statusPengajuan}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            tenor
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {data.detailPengajuan.tenor}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            tanggal pengajuan
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {moment(data.detailPengajuan.tglPengajuan).format(
                                "DD MMMM YYYY"
                              )}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            tanggal pencairan
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {moment(data.detailPengajuan.tglPencairan).format(
                                "DD MMMM YYYY"
                              )}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            tanggal selesai angsuran
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {moment(
                                data.detailPengajuan.tglSelesaiAngsuran
                              ).format("DD MMMM YYYY")}
                            </Label>
                          </li>
                        </div>
                        <div className="col-6">
                          <Label
                            style={{
                              fontSize: 16,
                              marginTop: 15,
                              fontWeight: "bold",
                              color: "#828286",
                              textTransform: "uppercase",
                            }}
                          >
                            tipe pengajuan
                          </Label>
                          <li>
                            <Label
                              style={{
                                fontSize: 14,
                                marginBottom: 15,
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {data.detailPengajuan.typePengajuan}
                            </Label>
                          </li>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <Card.Title
                    as="h3"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    history pengajuan
                    <hr></hr>
                  </Card.Title>
                </Card.Header>
                <div
                  style={{
                    maxHeight: "500px",
                    overflowY: "scroll",
                  }}
                >
                  <VerticalTimeline lineColor="#113946">
                    {history.history.map((item, index) => (
                      <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        contentStyle={{
                          borderTop:
                            item.statusPengajuan === "Menunggu"
                              ? "7px solid  rgb(255, 175, 5)"
                              : item.status === "Diproses"
                              ? "7px solid  rgb(250, 93, 2)"
                              : item.status === "Disetujui"
                              ? "7px solid  rgb(90, 201, 103)"
                              : item.status === "Revisi"
                              ? "7px solid  rgb(255, 43, 75)"
                              : "7px solid  rgb(255, 15, 0)",
                        }}
                        key={index}
                        date={moment(item.date).format("DD MMM YYYY HH:mm:ss")}
                        iconStyle={{
                          background:
                            item.status === "Menunggu"
                              ? "#FFAF05"
                              : item.status === "Diproses"
                              ? "#fa5d02"
                              : item.status === "Disetujui"
                              ? "#5AC967"
                              : item.status === "Revisi"
                              ? "#FF2B4B"
                              : "#E10F00",
                          color: "#fff",
                        }}
                      >
                        <h4
                          className="vertical-timeline-element-title"
                          style={{ fontWeight: "bold" }}
                        >
                          {item.status}
                        </h4>
                        <div style={{ marginTop: "15px" }}>
                          <Row>
                            <Col sm="3">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 14,
                                  color: "black",
                                }}
                              >
                                Keterangan
                              </label>
                            </Col>
                            <Col sm="o">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 14,
                                  color: "black",
                                }}
                              >
                                :
                              </label>
                            </Col>
                            <Col sm="6">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 14,
                                  color: "black",
                                }}
                              >
                                {!item.keterangan || item.keterangan === ""
                                  ? "-"
                                  : item.keterangan}
                              </label>
                            </Col>
                          </Row>
                        </div>
                      </VerticalTimelineElement>
                    ))}
                  </VerticalTimeline>
                </div>
              </Card>
            </div>
          </Col>
          <Col md="12"></Col>
        </Row>
      </Container>
    </>
  );
}

export default DetailPelunsanan;
