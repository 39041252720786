const initialState = {
  getAllPerusahaan: [],
  getDataPartner: [],
};

const dataPartnerReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_GETALL_PERUSAHAAN":
      return {
        ...state,
        getAllPerusahaan: data,
      };
    default:
      return state;
  }
};

export default dataPartnerReducer;
