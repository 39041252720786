import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Tab,
  InputGroup,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Table,
  Image,
  FormGroup,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import CurrencyInput from "react-currency-input-field";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { setConstantValue } from "typescript";
import { Label } from "reactstrap";
import { Dna } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
import {
  getDetailSimpanan,
  ubahSimpanan,
} from "../../stores/simpanan/function";

function DetailSimpanan(data) {
  // console.log(data, "LLLL");
  const dispatch = useDispatch();
  const date = new Date();

  const detailAllSimpanan = useSelector((state) => state.simpananReducer);
  const auth = useSelector((state) => state.authReducer);
  const valDetail = detailAllSimpanan.detailSimpanan;
  const [detailListSimpanan, setDetailListSimpanan] = React.useState([]);
  const [idSimpanan, setIdSimpanan] = React.useState("");
  const [modalUbahSimpanan, setModalUbahSimpanan] = React.useState(false);
  const [nominal, setNominal] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };
  moment.locale("id");
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "id-ID",
      options
    );
    return formattedDate;
  };

  React.useEffect(() => {
    let tmp =
      detailAllSimpanan.detailSimpanan &&
      detailAllSimpanan.detailSimpanan.data.docs.map((val, index) => {
        return {
          ...val,
          action: (
            <div className="actions-right">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Ubah Simpanan</Tooltip>}
              >
                <Button
                  className="mr-2"
                  // variant="warning"
                  disabled={auth.role !== "super admin"}
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                    border: 0,
                    backgroundColor:
                      auth.role === "super admin" ? "#FF7F00" : "grey",
                    cursor:
                      auth.role !== "super admin" ? "not-allowed" : "pointer",
                    color: "#FFF2E6",
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    setIdSimpanan(val.idSimpanan);
                    setModalUbahSimpanan(val);
                    setNominal(val.nominal);
                  }}
                >
                  <i class="fas fa-edit"></i>
                </Button>
              </OverlayTrigger>
            </div>
          ),
        };
      });
    setDetailListSimpanan(tmp);
  }, [detailAllSimpanan]);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colDetailSimpanan = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => {
        const currentPage = page + 1;
        const itemsPerPage = detailAllSimpanan.detailSimpanan.data.limit;
        const calculatedIndex =
          index + currentPage * itemsPerPage - itemsPerPage + 1;

        return calculatedIndex;
      },
    },
    {
      name: "Tanggal Simpanan",
      minWidth: "140px",
      center: true,
      selector: (row) => formatDate(row.tglSimpanan),
    },
    {
      name: "Nominal",
      minWidth: "120px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominal);
        return formattedValue;
      },
    },
    {
      name: "Aksi",
      minWidth: "150px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  const handleBackClick = () => {
    // setIdSimpanan(idSimpanan);
    dispatch({ type: "SET_MODAL_SIMPANAN", data: true });
  };

  const handleCloseUbahSimpanan = () => {
    setNominal("");
  };

  function submitUbahSimpanan(e) {
    e.preventDefault();
    if (nominal === "") {
      Swal.fire({
        title: "Nominal Harus Diisi",
        icon: "warning",
      });
      return;
    }
    ubahSimpanan({
      idSimpanan: idSimpanan,
      nominal: nominal,
      fcmTokenUser: data.dataFcmToken,
    }).then((response) => {
      if (response.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 1500,
        });
        setModalUbahSimpanan(false);
        getDetailSimpanan(dispatch, {
          idUser: data.detailSimpanan.idUser,
          page: page + 1,
          limit: totalPerHalaman,
        });
        dispatch({ type: "SET_MODAL_SIMPANAN", data: true });
      } else if (response.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
        });
      }
    });
  }

  React.useEffect(() => {
    setIsLoading(true);
    getDetailSimpanan(dispatch, {
      idUser: data.detailSimpanan.idUser,
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [page, totalPerHalaman]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card style={{ border: 0 }}>
                <Card.Header style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    className="btn-transparent mr-2"
                    type="button"
                    variant="primary"
                    size="md"
                    style={{
                      border: "none",
                      backgroundColor: "#00BFFF",
                      border: 0,
                    }}
                    onClick={handleBackClick}
                  >
                    <i className="fas fa-arrow-left"></i>
                  </Button>
                  <Card.Title
                    as="h3"
                    style={{
                      marginTop: 10,
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      flex: 1,
                      marginBottom: 15,
                    }}
                  >
                    Detail Simpanan
                  </Card.Title>
                </Card.Header>
              </Card>

              <Card.Body>
                <Card style={{ border: 0 }}>
                  <div className="row">
                    <div className="col-12">
                      <Row>
                        <Col sm="3">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            Nama
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            {data.detailSimpanan.nama}
                          </label>
                        </Col>
                      </Row>
                    </div>
                    <div className="col-12">
                      <Row>
                        <Col sm="3">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            Nopeg
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            {data.detailSimpanan.nopeg}
                          </label>
                        </Col>
                      </Row>
                    </div>
                    <div className="col-12">
                      <Row>
                        <Col sm="3">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            Perusahaan
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            {data.detailSimpanan.namaPerusahaan}
                          </label>
                        </Col>
                      </Row>
                    </div>
                    <div className="col-12">
                      <Row>
                        <Col sm="3">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            Client
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            {data.detailSimpanan.namaClient}
                          </label>
                        </Col>
                      </Row>
                    </div>
                    <div className="col-12">
                      <Row>
                        <Col sm="3">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            Cabang
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            {data.detailSimpanan.namaCabang}
                          </label>
                        </Col>
                      </Row>
                    </div>
                    {/* <div className="col-12">
                      <Row>
                        <Col sm="3">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            Gaji Pokok
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            {data.detailSimpanan.gajiPokok !== 0
                              ? `Rp ${Number(
                                  data.detailSimpanan.gajiPokok
                                ).toLocaleString("id-ID")}`
                              : "Rp 0"}
                          </label>
                        </Col>
                      </Row>
                    </div> */}
                    <div className="col-12">
                      <Row>
                        <Col sm="3">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            Limit Pengajuan
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            {data.detailSimpanan.limitPengajuan !== 0
                              ? `Rp ${Number(
                                  data.detailSimpanan.limitPengajuan
                                ).toLocaleString("id-ID")}`
                              : "Rp 0"}
                          </label>
                        </Col>
                      </Row>
                    </div>
                    <div className="col-12">
                      <Row>
                        <Col sm="3">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            Nominal Simpanan Per Bulan
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            {data.detailSimpanan.nominalSimpananPerBulan !== 0
                              ? `Rp ${Number(
                                  data.detailSimpanan.nominalSimpananPerBulan
                                ).toLocaleString("id-ID")}`
                              : "Rp 0"}
                          </label>
                        </Col>
                      </Row>
                    </div>
                    <div className="col-12">
                      <Row>
                        <Col sm="3">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            Total Simpanan
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 16,
                              color: "black",
                            }}
                          >
                            {data.detailSimpanan.total_simpanan !== 0
                              ? `Rp ${Number(
                                  data.detailSimpanan.total_simpanan
                                ).toLocaleString("id-ID")}`
                              : "Rp 0"}
                          </label>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
                {isLoading ? (
                  <div
                    className="loader-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "30vh",
                    }}
                  >
                    <Dna
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="dna-loading"
                      wrapperStyle={{}}
                      wrapperClass="dna-wrapper"
                    />
                    <p>Loading...</p>
                  </div>
                ) : (
                  <div>
                    <DataTable
                      columns={colDetailSimpanan}
                      data={detailListSimpanan}
                      customStyles={customStyles}
                      paginationPerPage={totalPerHalaman}
                      progressPending={isLoading}
                    />
                    <div style={{ marginTop: 35 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <Row style={{ alignItems: "center" }}>
                            <div
                              style={{
                                paddingRight: 0,
                                marginLeft: 20,
                                marginRight: 10,
                              }}
                            >
                              <label style={{ fontWeight: "bold" }}>
                                Rows per page
                              </label>
                            </div>
                            <Col sm="3" style={{ paddingLeft: 0 }}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Client"
                                value={options.find(
                                  (option) => option.value === totalPerHalaman
                                )}
                                onChange={handlePageSizeChange}
                                options={options}
                                placeholder="Rows per page"
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <ReactPaginate
                            forcePage={page}
                            containerClassName="paginations justify-content-end"
                            previousClassName="pages-items-containers"
                            previousLinkClassName="pages-links-labels"
                            nextClassName="pages-items-containers"
                            nextLinkClassName="pages-links-labels"
                            breakClassName="pages-items-containers"
                            pageCount={
                              detailAllSimpanan.detailSimpanan.data.totalPages
                            }
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageLinkClassName="pages-links-labels"
                            pageClassName="pages-items-containers"
                            activeClassName="actives"
                            activeLinkClassName="activess"
                            nextLabel=" > "
                            previousLabel=" < "
                            initialPage={page}
                            disableInitialCallback={true}
                            onPageChange={(val) => {
                              setPage(val.selected);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modalUbahSimpanan}
        onHide={() => {
          handleCloseUbahSimpanan();
          setModalUbahSimpanan(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                        Ubah Simpanan
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nominal
                          </label>
                          <p>
                            <CurrencyInput
                              style={{
                                padding: 5,
                                borderRadius: 4,
                                width: "100%",
                                height: "40px",
                                borderWidth: "1px",
                                borderTopColor: "rgba(227, 227, 227, 0.3)",
                                borderLeftColor: "rgba(227, 227, 227, 0.3)",
                                borderBottomColor: "rgba(227, 227, 227, 1)",
                                borderRightColor: "rgba(227, 227, 227, 1)",
                              }}
                              name="nominal"
                              placeholder="Masukkan nominal"
                              prefix="Rp "
                              groupSeparator="."
                              decimalSeparator=","
                              value={nominal}
                              onValueChange={(value) => {
                                setNominal(value);
                              }}
                            />
                          </p>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitUbahSimpanan}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DetailSimpanan;
