const initialState = {
  getAllUser: [],
  getNewUser: [],
  modalDetailUser: true,
  detailUser: [],
  userProfile: [],
};

const userReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_GETALL_USER":
      return {
        ...state,
        getAllUser: data,
      };
    case "SET_GET_NEW_USER":
      return {
        ...state,
        getNewUser: data,
      };
    case "SET_MODAL_USER":
      return {
        ...state,
        modalDetailUser: data,
      };
    case "SET_DETAIL_USER":
      return {
        ...state,
        detailUser: data,
      };
    case "SET_PROFILE_USER":
      return {
        ...state,
        userProfile: data,
      };
    default:
      return state;
  }
};

export default userReducer;
