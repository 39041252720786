import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Table,
  Image,
  FormGroup,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { getAllNotif } from "stores/notifikasi/function";
import { updateNotif } from "stores";
import io, { Socket } from "socket.io-client";
import { baseUrl, deleteAllNotif, deleteOneNotif } from "../stores";
// import "../firebase-get-token.js";

function Notifikasi() {
  const dispatch = useDispatch();
  const notif = useSelector((state) => state.notifReducer);
  const ambilNotif = notif.listNotif;
  const [selectedItem, setSelectedItem] = useState(null);
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "id-ID",
      options
    );

    const hours = new Date(dateString).getHours();
    const minutes = new Date(dateString).getMinutes();
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return `${formattedDate} ${formattedTime}`;
  };
  const isNotifEmpty = ambilNotif && ambilNotif.data.response.length === 0;

  const handleDeleteAll = async () => {
    const confirmation = await Swal.fire({
      title: "Apakah Anda yakin ingin menghapus semua notifikasi?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, hapus semua!",
      cancelButtonText: "Batal",
    });
    if (confirmation.isConfirmed) {
      try {
        const response = await deleteAllNotif();
        // console.log("Semua notifikasi berhasil dihapus:", response);
        Swal.fire({
          icon: "success",
          title: "Semua notifikasi berhasil dihapus!",
          showConfirmButton: false,
          timer: 1500,
        });

        getAllNotif(dispatch);
      } catch (error) {
        console.error("Gagal menghapus semua notifikasi:", error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Gagal menghapus semua notifikasi. Silakan coba lagi!",
        });
      }
    }
  };

  const handleDelete = async (idNotif) => {
    const confirmation = await Swal.fire({
      title: "Apakah Anda yakin ingin menghapus notifikasi?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Batal",
    });
    if (confirmation.isConfirmed) {
      try {
        const response = await deleteOneNotif({ idNotif });
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 1500,
          });
          getAllNotif(dispatch);
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.message || "Something went wrong.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        getAllNotif(dispatch);
      } catch (error) {
        console.error("Gagal menghapus notifikasi:", error);
      }
    }
  };

  const handleButtonClick = (item) => {
    setSelectedItem(item.idNotif);
    updateNotif({ idNotif: item.idNotif }).then((response) => {
      if (response.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 1500,
        });
        getAllNotif(dispatch);
      } else if (response.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message || "Something went wrong.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  const isItemUpdated = (itemId) => {
    return selectedItem === itemId;
  };

  React.useEffect(() => {
    const socket = io(baseUrl);
    socket.emit("joinRoom", localStorage.getItem("ID"));
    socket.on("notifikasi", (notif) => {
      getAllNotif(dispatch);
    });
    getAllNotif(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Card>
          <Col md="12">
            <h3
              className="title"
              style={{ fontWeight: "bold", position: "relative" }}
            >
              Notifikasi
              <button
                className="btn btn"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  marginTop: 10,
                  marginRight: 10,
                  padding: "6px 15px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "red",
                  fontWeight: "bold",
                  border: 0,
                  cursor: isNotifEmpty ? "not-allowed" : "pointer",
                }}
                onClick={handleDeleteAll}
                disabled={isNotifEmpty}
              >
                <i
                  className="fas fa-trash-restore"
                  style={{ marginRight: "5px" }}
                ></i>{" "}
                Hapus Semua
              </button>
            </h3>
            <Row style={{ marginTop: 40 }}>
              <Col md="12">
                {ambilNotif &&
                  ambilNotif.data.response.map((item, index) => {
                    // console.log(
                    //   item.statusNotif,
                    //   "??????????????????????????????"
                    // );
                    return (
                      <>
                        <Card
                          key={index}
                          style={{
                            border: 0,
                            marginBottom: 2,
                            position: "relative",
                          }}
                        >
                          <div style={{ fontSize: 18, padding: 8 }}>
                            <Card
                              style={{
                                position: "relative",
                                padding: 10,
                                marginBottom: 10,
                                backgroundColor:
                                  item.statusNotif === true
                                    ? "rgba(252, 252, 252, 1)"
                                    : "rgba(206, 188, 245, 0.4)",
                                boxShadow: "1px 2px 9px #bdbdbd",
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  marginBottom: 15,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: "#FFFFFF",
                                      padding: 10,
                                      width: 50,
                                      height: 50,
                                      textAlign: "center",
                                      borderRadius: 25,
                                      boxShadow: "1px 2px 9px #bdbdbd",
                                      marginRight: 15,
                                    }}
                                  >
                                    <i
                                      style={{
                                        backgroundColor: "#FFFFFF",
                                        marginTop: 7,
                                        marginLeft: 6,
                                      }}
                                      className={
                                        item.typeNotif === "pengajuan"
                                          ? "fas fa-file-contract text-primary mr-2"
                                          : item.typeNotif === "register"
                                          ? "fas fa-address-card text-info mr-2"
                                          : item.typeNotif === "angsuran"
                                          ? "fas fa-money-bil-wave text-success mr-2"
                                          : item.typeNotif === "pinjaman"
                                          ? "fas fa-hand-holding-usd text-warning mr-2"
                                          : "fas fa-wallet text-secondary mr-2"
                                      }
                                    ></i>
                                  </div>
                                  <div>{item.title}</div>
                                </div>
                                {/* Tombol hapus */}
                                <div>
                                  <i
                                    className="fas fa-trash"
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                      position: "absolute",
                                      top: 10,
                                      right: 15,
                                    }}
                                    onClick={() => handleDelete(item.idNotif)}
                                  ></i>
                                </div>
                              </div>
                              <div
                                style={{
                                  fontSize: 16,
                                  paddingLeft: 26,
                                  marginTop: 5,
                                }}
                              >
                                {item.message}
                              </div>
                              <hr></hr>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "14px",
                                    color: "gray",
                                    paddingLeft: 26,
                                    paddingTop: 4,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {formatDate(item.dateNotif)}
                                </div>
                                {/* Tombol tandai setelah dibaca */}
                                {!item.statusNotif && (
                                  <Button
                                    className="mr-2"
                                    style={{
                                      marginTop: 5,
                                      padding: "6px 15px",
                                      fontSize: "16px",
                                      backgroundColor: "#00BFFF",
                                      border: 0,
                                      boxShadow: "1px 2px 9px #bdbdbd",
                                    }}
                                    onClick={() => {
                                      handleButtonClick(item);
                                    }}
                                  >
                                    Tandai Setelah Anda Baca
                                  </Button>
                                )}
                              </div>
                            </Card>
                          </div>
                        </Card>
                      </>
                    );
                  })}
              </Col>
            </Row>
          </Col>
        </Card>
      </Container>
    </>
  );
}

export default Notifikasi;
