import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Dropdown,
  Row,
  Col,
  Tab,
  InputGroup,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Table,
  Image,
  FormGroup,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import CurrencyInput from "react-currency-input-field";
import DataTable from "react-data-table-component";
import {
  getAllSimpanan,
  getDetailSimpanan,
  tambahSimpanan,
  getUserAktifSimpanan,
  updateAllSimpanan,
  exportAllSimpananExsist,
} from "../../stores";
import DetailSimpanan from "./DetailSimpanan";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { setConstantValue } from "typescript";
import { CardBody } from "reactstrap";
import { Dna } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import "./Pagination.css";

function Simpanan() {
  const dispatch = useDispatch();
  const date = new Date();
  const modalDetailSimpanan = useSelector((state) => state.simpananReducer);
  const simpanan = useSelector((state) => state.simpananReducer);
  const auth = useSelector((state) => state.authReducer);
  const [searchText, setSearchText] = React.useState("");
  const [modalTambahSimpanan, setModalTambahSimpanan] = React.useState(false);
  const [modalUpdateSimpanan, setModalUpdateSimpanan] = React.useState(false);
  const [nominal, setNominal] = React.useState("");
  const [tglSimpanan, setTglSimpanan] = React.useState("");
  const [tglUpdateSimpanan, setTglUpdateSimpanan] = React.useState("");
  const [tglAddSimpanan, setTglAddSimpanan] = React.useState("");
  const [listAllSimpanan, setListAllSimpanan] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [multipleSelect, setMultipleSelect] = React.useState([]);
  const [userAktifOptions, setUserAktifOptions] = React.useState([]);
  const [excel, setExcel] = React.useState([]);
  const [kirimFcm, setKirimFcm] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };
  const [detailUser, setDetailUser] = React.useState(null);
  moment.locale("id");
  const formatDate = (dateString) => {
    const options = { month: "long", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "id-ID",
      options
    );
    return formattedDate;
  };
  const kirimTglSimpanan = new Date();
  kirimTglSimpanan.setDate(1);

  const formattedDateSimpanan = kirimTglSimpanan.toISOString().split("T")[0];

  function handleFileChange(e) {
    const selectedFiles = Array.from(e.target.files);
    setExcel(selectedFiles);
  }

  const removeExcel = (index) => {
    setExcel(excel.filter((_, i) => i !== index));
  };

  const handleFilterChange = () => {
    setIsLoading(true);
    setPage(0);
    getAllSimpanan(dispatch, {
      page: 1,
      limit: totalPerHalaman,
      cari: searchText,
    })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Panggil fungsi pencarian di sini
      handleFilterChange();
    }
  };

  function submitTambahSimpanan(e) {
    e.preventDefault();

    if (tglSimpanan === "") {
      Swal.fire({
        title: "Tanggal Simpanan harus diisi !",
        icon: "warning",
      });
      return;
    }

    if (excel.length === 0) {
      Swal.fire({
        title: "Pilih file terlebih dahulu !",
        icon: "warning",
      });
      return;
    }

    Swal.fire({
      title: "Loading ...",
      didOpen() {
        Swal.showLoading();
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
    const formData = new FormData();
    formData.append("tglSimpanan", tglSimpanan);
    excel.forEach((file) => {
      formData.append("excelFile", file);
    });

    tambahSimpanan(formData)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            // timer: 1500,
            showConfirmButton: true,
          });
          setModalTambahSimpanan(false);
          setTglSimpanan(""); // Assuming tglSimpanan is a state variable
          setExcel([]);
          getAllSimpanan(dispatch, {
            cari: "",
            page: page + 1,
            limit: totalPerHalaman,
          });
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terjadi kesalahan saat mengirim data. Silakan coba lagi.",
        });
      });
  }

  function submitUpdateAllSimpanan(e) {
    e.preventDefault();

    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    if (tglUpdateSimpanan === "") {
      Swal.fire({
        title: "Tanggal Simpanan harus diisi !",
        icon: "warning",
      });
      return;
    }

    updateAllSimpanan({
      tglAkhir: tglUpdateSimpanan,
      tglSimpanan: formattedDateSimpanan,
    })
      .then((response) => {
        Swal.close();
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            // timer: 1500,
            showConfirmButton: true,
          });
          setModalUpdateSimpanan(false);
          getAllSimpanan(dispatch, {
            cari: "",
            page: page + 1,
            limit: totalPerHalaman,
          });
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terjadi kesalahan saat mengirim data. Silakan coba lagi.",
        });
      });
  }

  const submitExportAllSimpanan = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Konfirmasi Export User",
      text: `Apakah Anda ingin mengexport ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, Export",
      cancelButtonText: "Batal",
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const data = await exportAllSimpananExsist({
            // tglPencairan: exportTglExcel,
          });
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Export Semua Pencairan.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          Swal.fire({
            icon: "success",
            title: "Data berhasil diexport",
          });
        } catch (error) {
          Swal.fire({
            title: "Terjadi kesalahan",
            text: error.message,
            icon: "error",
          });
        }
      },
    });
  };

  const handleDropdownItemDetail = (val) => {
    // console.log(val, "AAAA");
    dispatch({ type: "SET_MODAL_SIMPANAN", data: false });
    setDetailUser(val);
    setKirimFcm(val.fcmTokenUser);
  };

  React.useEffect(() => {
    let tmp =
      simpanan.listSimpanan &&
      simpanan.listSimpanan.docs.map((val, index) => {
        // console.log(val, "AAAA");
        return {
          ...val,
          action: (
            <div className="actions-right">
              <Dropdown drop="right">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  style={{
                    backgroundColor: "#50A6C1",
                    border: 0,
                    fontWeight: "bold",
                  }}
                >
                  Aksi
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ zIndex: 1000, position: "relative" }}>
                  <Dropdown.Item
                    onClick={() => {
                      handleDropdownItemDetail(val);
                    }}
                  >
                    Detail
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Detail</Tooltip>}
              >
                <Button
                  className="mr-2"
                  variant="info"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    handleDetailClick(val);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger> */}
            </div>
          ),
        };
      });
    // console.log(tmp, "KKKK");
    setListAllSimpanan(tmp);
  }, [simpanan]);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const getStatusStyle = (status) => {
    switch (status) {
      case "Belum":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE6E6",
          textColor: "#FF0000",
        };
      case "Sudah":
        return {
          fontWeight: "bold",
          backgroundColor: "#E6E6FF",
          textColor: "#0000FF",
        };
      default:
        return {};
    }
  };

  const getStatusResignStyle = (status) => {
    switch (status) {
      case "No":
        return {
          fontWeight: "bold",
          backgroundColor: "#E5F6FA",
          textColor: "#21ABCD",
        };
      case "Yes":
        return {
          fontWeight: "bold",
          backgroundColor: "#FF4040",
          textColor: "#FFECEC",
        };
      default:
        return {};
    }
  };

  const getStatusKoperasiStyle = (status) => {
    switch (status) {
      case "nonactive":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE6E6",
          textColor: "#FF0000",
        };
      case "waiting":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFEEE6",
          textColor: "#FF4F00",
        };
      case "active":
        return {
          fontWeight: "bold",
          backgroundColor: "#E1FAEE",
          textColor: "#01D26D",
        };
      case "ex":
        return {
          fontWeight: "bold",
          backgroundColor: "#EA4335",
          textColor: "#FFFFFF",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colSimpanan = [
    {
      name: "No",
      width: "80px",
      center: true,
      // selector: (row) => getAllSimpanan.indexOf(row) + 1,
      selector: (row, index) => index + simpanan.listSimpanan.pagingCounter,
    },
    {
      name: "Nama",
      minWidth: "240px",
      center: true,
      sortable: true,
      selector: (row) => row.nama,
    },
    {
      name: "Nomor Pegawai",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nopeg,
    },
    {
      name: "Status Pegawai",
      minWidth: "200px",
      center: true,
      selector: (row) => row.statusResign,
      cell: (row) => {
        const statusStyle = getStatusResignStyle(row.statusResign);
        const tampilanLabel =
          row.statusResign === "No" ? "Aktif" : "Tidak Aktif";

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
    {
      name: "Tanggal Resign",
      minWidth: "200px",
      center: true,
      selector: (row) =>
        row.tglResign !== ""
          ? moment(row.tglResign).format("DD MMMM YYYY")
          : "-",
    },

    {
      name: "Status Koperasi",
      minWidth: "200px",
      center: true,
      selector: (row) =>
        row.statusKoperasi === "nonactive"
          ? "Tidak Aktif"
          : row.statusKoperasi === "waiting"
          ? "Menunggu"
          : row.statusKoperasi === "active"
          ? "Aktif"
          : row.statusKoperasi === "ex"
          ? "Keluar"
          : row.statusKoperasi,
      cell: (row) => {
        const statusKoperasi =
          row.statusKoperasi === "nonactive"
            ? "Tidak Aktif"
            : row.statusKoperasi === "waiting"
            ? "Menunggu"
            : row.statusKoperasi === "active"
            ? "Aktif"
            : row.statusKoperasi === "ex"
            ? "Keluar"
            : row.statusKoperasi;
        const statusStyle = getStatusKoperasiStyle(row.statusKoperasi);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {statusKoperasi}
          </div>
        );
      },
    },
    {
      name: "Join Koperasi",
      minWidth: "200px",
      center: true,
      selector: (row) =>
        !row.joinDate ? "-" : moment(row.joinDate).format("DD MMMM YYYY"),
    },
    {
      name: "Perusahaan",
      minWidth: "200px",
      center: true,
      selector: (row) => row.namaPerusahaan,
    },
    {
      name: "Client",
      minWidth: "200px",
      center: true,
      selector: (row) => row.namaClient,
    },
    {
      name: "Cabang",
      minWidth: "200px",
      center: true,
      selector: (row) => row.namaCabang,
    },
    {
      name: "Limit Pengajuan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.limitPengajuan);
        return formattedValue;
      },
    },
    {
      name: "Nominal Simpanan Per Bulan",
      minWidth: "270px",
      center: true,
      sortable: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalSimpananPerBulan);
        return formattedValue;
      },
    },
    {
      name: "Total Simpanan",
      minWidth: "200px",
      center: true,
      sortable: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.total_simpanan);
        return formattedValue;
      },
    },
    {
      name: "Bulan Simpanan Terakhir",
      minWidth: "240px",
      center: true,
      sortable: true,
      selector: (row) => {
        const tglTerakhirSimpanan =
          row.list_simpanan.length > 0
            ? row.list_simpanan[0].tglSimpanan
              ? formatDate(row.list_simpanan[0].tglSimpanan)
              : "-"
            : "-";

        return tglTerakhirSimpanan;
      },
    },
    {
      name: "Bulan Yang Harus Dibayar",
      minWidth: "250px",
      center: true,
      sortable: true,
      selector: (row) =>
        !row.startOfMonth ? "-" : moment(row.startOfMonth).format("MMMM YYYY"),
    },
    {
      name: "Status Simpanan Bulan Ini",
      minWidth: "240px",
      center: true,
      selector: (row) => row.status_pembayaran,
      cell: (row) => {
        const statusStyle = getStatusStyle(row.status_pembayaran);
        const tampilanLabel =
          row.status_pembayaran === "Belum" ? "Belum Terbayar" : "Terbayar";

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      cell: (row) => row.action,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.statusResign === "Yes",
      style: {
        backgroundColor: "#FFDBDB",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    // {
    //   when: (row) => row.cancel === true && row.statusPengajuan === "Ditolak",
    //   style: {
    //     backgroundColor: "#FFFFFF",
    //     color: "black",
    //     "&:hover": {
    //       cursor: "pointer",
    //     },
    //   },
    // },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleCloseModalTambah = () => {
    setTglSimpanan("");
    setNominal("");
    setMultipleSelect("");
    setExcel([]);
  };

  const getUserAktif = (date) => {
    getUserAktifSimpanan(dispatch, date)
      .then((response) => {
        // console.log("RESPONSE", response);
        if (response && response.data) {
          const userAktifOptions = response.data.data.map((user) => ({
            value: user._id,
            label: `${user.nopeg} - ${user.nama}`,
            fcmToken: user.fcmToken,
          }));
          setUserAktifOptions(userAktifOptions);
          // console.log("User Aktif", userAktifOptions);
        }
      })
      .catch((error) => {
        // console.error("Error fetching userAktif:", error);
      });
  };

  // const getStatusStyle = (status) => {
  //   switch (status) {
  //     case "Terbayar":
  //       return {
  //         fontWeight: "bold",
  //         backgroundColor: "#DBEFB5",
  //         textColor: "#87CB16",
  //       };
  //     case "Belum Terbayar":
  //       return {
  //         fontWeight: "bold",
  //         backgroundColor: "#FEBEC0",
  //         textColor: "#FB404B",
  //       };

  //     default:
  //       return {};
  //   }
  // };

  React.useEffect(() => {
    setIsLoading(true);
    getAllSimpanan(dispatch, {
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [modalDetailSimpanan.modalDetailSimpanan, page, totalPerHalaman]);

  return (
    <>
      {modalDetailSimpanan.modalDetailSimpanan ? (
        <>
          <Container fluid>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>
                    <Card.Title
                      as="h3"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      list simpanan
                      <hr></hr>
                    </Card.Title>

                    <Tab.Content>
                      <Card
                        style={{
                          border: 0,
                          marginTop: 20,
                          // backgroundColor: "red",
                        }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            marginTop: 15,
                            // justifyContent: "space-between",
                          }}
                        >
                          {auth.role === "super admin" ||
                          auth.role === "admin" ? (
                            <Col md={"8"}>
                              <Button
                                style={{
                                  fontWeight: "bold",
                                  border: 0,
                                  backgroundColor: "#A2CFFE",
                                  color: "black",
                                }}
                                className="btn-wd mr-1"
                                onClick={() => {
                                  // setIdUser(val.idUser);
                                  setModalTambahSimpanan(true);
                                }}
                              >
                                Tambah Simpanan
                              </Button>

                              <Button
                                style={{
                                  fontWeight: "bold",
                                  backgroundColor: "#887191",
                                  border: 0,
                                }}
                                className="btn-wd ml-2"
                                onClick={() => {
                                  // setIdUser(val.idUser);
                                  setModalUpdateSimpanan(true);
                                }}
                              >
                                Update All Simpanan
                              </Button>
                              <Button
                                onClick={submitExportAllSimpanan}
                                style={{
                                  backgroundColor: "#DDDCD9",
                                  color: "#54534D",
                                  border: 0,
                                  fontWeight: "bold",
                                }}
                                className="btn-wd ml-3"
                              >
                                Export All Simpanan
                              </Button>
                            </Col>
                          ) : null}
                        </Row>
                      </Card>
                    </Tab.Content>
                  </Card.Header>
                  <Col
                    md={"6"}
                    className="d-flex justify-content-end"
                    style={{ padding: 0 }}
                  >
                    <Col>
                      <InputGroup>
                        <Form.Control
                          style={{
                            paddingLeft: "10px",
                          }}
                          type="text"
                          value={searchText}
                          onChange={handleSearch}
                          onKeyPress={handleKeyPress}
                          placeholder="Nama/Nopeg"
                        />
                        <InputGroup.Append>
                          <InputGroup.Text
                            style={{ cursor: "pointer" }}
                            onClick={handleClearSearch}
                          >
                            <i className="fas fa-times"></i>
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Col>

                    <Button
                      style={{
                        fontSize: 16,
                        backgroundColor: "#00BFFF",
                        border: 0,
                        paddingTop: 10,
                        paddingBottom: 7,
                      }}
                      onClick={handleFilterChange}
                    >
                      <i className="fas fa-search"></i>
                    </Button>
                  </Col>
                  <Card.Body>
                    {isLoading ? (
                      <div
                        className="loader-container"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "30vh",
                        }}
                      >
                        <Dna
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="dna-loading"
                          wrapperStyle={{}}
                          wrapperClass="dna-wrapper"
                        />
                        <p>Loading...</p>
                      </div>
                    ) : (
                      <div>
                        {listAllSimpanan.length > 0 ? (
                          <DataTable
                            columns={colSimpanan}
                            data={listAllSimpanan}
                            customStyles={customStyles}
                            paginationPerPage={totalPerHalaman}
                            progressPending={isLoading}
                            conditionalRowStyles={conditionalRowStyles}
                          />
                        ) : (
                          <div style={{ textAlign: "center" }}>
                            <p
                              style={{
                                fontWeight: "bold",
                                marginBottom: "1rem",
                              }}
                            >
                              Maaf, belum ada simpanan saat ini.
                            </p>
                            <img
                              src={require("assets/img/sorry.jpg").default}
                              alt="Sorry"
                              style={{
                                maxWidth: "20%",
                                display: "block",
                                margin: "0 auto",
                              }}
                            />
                          </div>
                        )}

                        <div style={{ marginTop: 35 }}>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col>
                              <Row style={{ alignItems: "center" }}>
                                <div
                                  style={{
                                    paddingRight: 0,
                                    marginLeft: 20,
                                    marginRight: 10,
                                  }}
                                >
                                  <label style={{ fontWeight: "bold" }}>
                                    Rows per page
                                  </label>
                                </div>
                                <Col sm="3" style={{ paddingLeft: 0 }}>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="Pilih Client"
                                    value={options.find(
                                      (option) =>
                                        option.value === totalPerHalaman
                                    )}
                                    onChange={handlePageSizeChange}
                                    options={options}
                                    placeholder="Rows per page"
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col>
                              <ReactPaginate
                                forcePage={page}
                                containerClassName="paginations justify-content-end"
                                previousClassName="pages-items-containers"
                                previousLinkClassName="pages-links-labels"
                                nextClassName="pages-items-containers"
                                nextLinkClassName="pages-links-labels"
                                breakClassName="pages-items-containers"
                                pageCount={simpanan.listSimpanan.totalPages}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                pageLinkClassName="pages-links-labels"
                                pageClassName="pages-items-containers"
                                activeClassName="actives"
                                activeLinkClassName="activess"
                                nextLabel=" > "
                                previousLabel=" < "
                                initialPage={page}
                                disableInitialCallback={true}
                                onPageChange={(val) => {
                                  setPage(val.selected);
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

          <Modal
            size="lg"
            show={modalTambahSimpanan}
            onHide={() => {
              handleCloseModalTambah();
              setModalTambahSimpanan(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Row>
                <Col md="12">
                  <Form action="" className="form" method="">
                    <Card>
                      <Card.Header>
                        <Card.Header>
                          <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                            Tambah Simpanan
                          </Card.Title>
                        </Card.Header>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col sm="12">
                            <Form.Group>
                              <label
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  color: "grey",
                                  textTransform: "none",
                                }}
                              >
                                Tanggal Simpanan
                              </label>
                              <Form.Control
                                onChange={(e) => {
                                  setTglSimpanan(e.target.value);
                                  getUserAktif(e.target.value);
                                }}
                                type="date"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="12">
                            <Row style={{ alignItems: "center" }}>
                              <Form.Text
                                // className="text-muted"
                                style={{
                                  color: "blue",
                                  fontWeight: "bold",
                                  marginLeft: 10,
                                }}
                              >
                                *Download format unggah simpanan
                              </Form.Text>
                              <Button
                                style={{
                                  margin: 10,
                                  padding: 5,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  window.open(
                                    "https://docs.google.com/spreadsheets/d/12tTcCcz4HGRQ-vK28h0HmmjOEH99ukqM/edit?usp=sharing&ouid=115920084350641374457&rtpof=true&sd=true",
                                    "_blank"
                                  );
                                }}
                              >
                                Download
                                <i
                                  class="fas fa-download"
                                  style={{ marginLeft: 5 }}
                                ></i>
                              </Button>
                            </Row>
                            <label
                              className="label-input-file btn text-white mb-4"
                              htmlFor="import2"
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "#12E193",
                                border: 0,
                              }}
                            >
                              <div
                                style={{
                                  color: "#02160F",
                                }}
                              >
                                Pilih File
                              </div>

                              <input
                                type="file"
                                id="import2"
                                onClick={(e) => {
                                  e.target.value = "";
                                }}
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                accept=".xls,.xlsx"
                                multiple
                              />
                            </label>
                            {excel.map((file, index) => (
                              <div
                                className="d-flex align-items-center"
                                key={index}
                              >
                                <p className="mb-1 mr-1">{file.name}</p>
                                <button
                                  onClick={() => removeExcel(index)}
                                  className="btn btn-link remove text-danger"
                                >
                                  <i className="fa fa-times" />
                                </button>
                              </div>
                            ))}

                            {/* <Form.Control
                              type="file"
                              accept=".xls,.xlsx"
                              onChange={(e) => {
                                setExcel(e.target.files[0]);
                              }}
                            ></Form.Control> */}
                          </Col>

                          {/* <Col sm="12">
                            <Form.Group>
                              <label
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  color: "grey",
                                  textTransform: "none",
                                }}
                              >
                                Nominal
                              </label>
                              <p>
                                <CurrencyInput
                                  style={{
                                    padding: 5,
                                    borderRadius: 4,
                                    width: "100%",
                                    height: "40px",
                                    borderWidth: "1px",
                                    borderTopColor: "rgba(227, 227, 227, 0.3)",
                                    borderLeftColor: "rgba(227, 227, 227, 0.3)",
                                    borderBottomColor: "rgba(227, 227, 227, 1)",
                                    borderRightColor: "rgba(227, 227, 227, 1)",
                                  }}
                                  name="nominal"
                                  placeholder="Masukkan nominal"
                                  prefix="Rp "
                                  groupSeparator="."
                                  decimalSeparator=","
                                  onValueChange={(value) => {
                                    setNominal(value);
                                  }}
                                />
                              </p>
                            </Form.Group>
                          </Col>
                          <Col sm="12">
                            <Form.Group>
                              <label
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  color: "grey",
                                  textTransform: "none",
                                }}
                              >
                                Nama
                              </label>
                              <div>
                                <Select
                                  className="react-select info"
                                  classNamePrefix="react-select"
                                  placeholder="Pilih Nopeg / Nama"
                                  name="multipleSelect"
                                  closeMenuOnSelect={false}
                                  isMulti
                                  // value={multipleSelect}
                                  // onChange={(value) =>  setMultipleSelect(value)}
                                  onChange={(value) => {
                                    setMultipleSelect(value);
                                  }}
                                  options={[
                                    {
                                      value: "",
                                      label: "Pilih Nopeg / Nama",
                                      isDisabled: true,
                                    },
                                    ...userAktifOptions,
                                  ]}
                                />
                              </div>
                            </Form.Group>
                          </Col> */}
                        </Row>

                        <div className="clearfix"></div>
                      </Card.Body>
                    </Card>
                    <div style={{ marginTop: 30, textAlign: "end" }}>
                      <Button
                        className="btn-fill pull-right"
                        type="submit"
                        // variant="info"
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "#A2CFFE",
                          border: 0,
                          color: "black",
                        }}
                        onClick={submitTambahSimpanan}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
          <Modal
            size="md"
            show={modalUpdateSimpanan}
            onHide={() => {
              handleCloseModalTambah();
              setModalUpdateSimpanan(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Row>
                <Col md="12">
                  <Form action="" className="form" method="">
                    <Card>
                      <Card.Header>
                        <Card.Header>
                          <Card.Title
                            as="h4"
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            Update All Simpanan
                            <hr></hr>
                          </Card.Title>
                        </Card.Header>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col sm="6">
                            <Form.Group>
                              <label
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  color: "grey",
                                  textTransform: "none",
                                }}
                              >
                                Pilih Join Date
                              </label>
                              <Form.Control
                                onChange={(e) => {
                                  setTglUpdateSimpanan(e.target.value);
                                  // getUserAktif(e.target.value);
                                }}
                                type="date"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="clearfix"></div>
                      </Card.Body>
                    </Card>
                    <div style={{ marginTop: 30, textAlign: "end" }}>
                      <Button
                        className="btn-fill pull-right"
                        type="submit"
                        // variant="info"
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "#A2CFFE",
                          border: 0,
                          color: "black",
                        }}
                        onClick={submitUpdateAllSimpanan}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          <DetailSimpanan detailSimpanan={detailUser} dataFcmToken={kirimFcm} />
        </>
      )}
    </>
  );
}

export default Simpanan;
