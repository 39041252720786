const initialState = {
  dataProject: [],
  getDataProject: [],
};

const dataProjectReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_GETALL_PROJECT":
      return {
        ...state,
        dataProject: data,
      };
    default:
      return state;
  }
};

export default dataProjectReducer;
