import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";

export async function updateStatusKoperasi(data) {
  try {
    const response = await baseAxios.post("webUser/approveUser", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function getAllUser(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webUser/getAllUser?page=${data.page}&limit=${data.limit}&cari=${data.cari}&statusKoperasi=${data.statusKoperasi}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({
      type: "SET_GETALL_USER",
      data: response.data,
    });
    // console.log(response, "PP");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getNewUser(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webUser/getNewUser?page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({
      type: "SET_GET_NEW_USER",
      data: response.data,
    });
    // console.log(response, "PPPP");
    return response;
  } catch (err) {
    return err.response;
  }
}

export function downloadDataExcel(data) {
  baseAxios
    .get("/webPurchaseOrder/downloadDataExcel", {
      headers: { token: localStorage.getItem("token") },
      responseType: "blob",
    })
    .then(async (respon) => {
      const url = window.URL.createObjectURL(new Blob([respon.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Data Purchase Order.xlsx`);
      document.body.appendChild(link);
      link.click();
      Swal.close();
    })
    .catch((err) => {
      // console.log(err, "gagal");
    });
}

export async function ubahRole(data) {
  try {
    const response = await baseAxios.post("webUser/ubahRole", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function getAllProfile(dispatch, data, history) {
  try {
    const response = await baseAxios.get("webUser/profile", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_PROFILE_USER", data: response.data });
    // console.log(response, "HHHHHHHHHHH");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getFormRegister(data) {
  try {
    const response = await baseAxios.post("webPdf/formRegister", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response.data;
  } catch (err) {
    console.error("Error fetching PDF:", err);
    throw err;
  }
}

export async function exportUser(data) {
  // console.log(data, "DATAAAA");
  try {
    const response = await baseAxios.post("webUser/exportNewUser", data, {
      headers: { token: localStorage.getItem("token") },
      responseType: "blob",
    });
    // console.log(response, "AAA");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function resetUserLama(data) {
  try {
    const response = await baseAxios.post("webUser/resetUserLama", data, {
      headers: { token: localStorage.getItem("token") },
    });
    // console.log(response, "response");
    return response;
  } catch (error) {
    return error.response;
  }
}
