/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Buttons from "views/Components/Buttons.js";
import GridSystem from "views/Components/GridSystem.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlertPage.js";
import Notifications from "views/Components/Notifications.js";
import Icons from "views/Components/Icons.js";
import Typography from "views/Components/Typography.js";
import RegularForms from "views/Forms/RegularForms.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import Wizard from "views/Forms/Wizard/Wizard.js";
import RegularTables from "views/Tables/RegularTables.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import ReactTables from "views/Tables/ReactTables.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import VectorMap from "views/Maps/VectorMap.js";
import Charts from "views/Charts.js";
import Calendar from "views/Calendar.js";
import UserPage from "views/Pages/UserPage.js";

//Tambahan Menu Baru
import User from "views/User/User";
import ListUser from "views/User/TabUsers/IndexUser";
import Simpanan from "views/Simpanan/Simpanan";
import UpgradeSimpanan from "views/Simpanan/UpgradeSimpanan";
import Pengajuan from "views/Pengajuan/Pengajuan";
import PengajuanPelunasan from "views/PengajuanPelunasan/PengajuanPelunasan";
import Saldo from "views/Saldo/Saldo";
import Notifikasi from "views/Notifikasi";
import DeviceBerhasil from "views/DeviceBerhasil";
import DeviceGagal from "views/DeviceGagal";
import UserProfile from "views/User/UserProfile";
import PrivacyPolicy from "views/PrivacyPolicy";
import TermsConditions from "views/TermsConditions";
import addPengajuan from "views/AddPengajuan/addPengajuan";

//Menu Lama
import LoginPage from "views/Pages/LoginPage.js";
import DashboardUtama from "views/DashboardUtama";

var routes = [
  {
    show: true,
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: DashboardUtama,
  },

  // {
  //   show: true,
  //   path: "/user",
  //   layout: "/admin",
  //   name: "User",
  //   icon: "nc-icon nc-badge",
  //   component: User,
  // },
  {
    show: true,
    path: "/alluser",
    layout: "/admin",
    name: "List User",
    icon: "nc-icon nc-badge",
    component: ListUser,
  },
  // {
  //   show: true,
  //   path: "/simpanan",
  //   layout: "/admin",
  //   name: "Simpanan",
  //   icon: "nc-icon nc-credit-card",
  //   component: Simpanan,
  // },
  {
    collapse: true,
    path: "/simpanan",
    name: "Simpanan",
    state: "openSimpanan",
    icon: "nc-icon nc-credit-card",
    views: [
      {
        show: true,
        path: "/simpanan",
        layout: "/admin",
        name: "List Simpanan",
        mini: "O",
        component: Simpanan,
      },
      {
        show: true,
        path: "/upgrade-simpanan",
        layout: "/admin",
        name: "Upgrade Simpanan",
        mini: "O",
        component: UpgradeSimpanan,
      },
    ],
  },
  {
    show: true,
    path: "/pengajuan",
    layout: "/admin",
    name: "Pengajuan",
    icon: "nc-icon nc-single-copy-04",
    component: Pengajuan,
  },
  {
    show: true,
    path: "/pengajuanPelunasan",
    layout: "/admin",
    name: "Pengajuan Pelunasan",
    icon: "nc-icon nc-single-copy-04",
    component: PengajuanPelunasan,
  },

  // {
  //   show: true,
  //   path: "/perusahaan",
  //   layout: "/admin",
  //   name: "Perusahaan",
  //   icon: "nc-icon nc-single-02",
  //   component: Perusahaan,
  // },
  // {
  //   show: true,
  //   path: "/struktural",
  //   layout: "/admin",
  //   name: "Struktural",
  //   icon: "nc-icon nc-layers-3",
  //   component: Struktural,
  // },

  {
    show: false,
    path: "/saldo",
    layout: "/admin",
    name: "Saldo",
    icon: "nc-icon nc-money-coins",
    component: Saldo,
  },
  {
    show: false,
    path: "/notifikasi",
    layout: "/admin",
    name: "Notifikasi",
    icon: "nc-icon nc-money-coins",
    component: Notifikasi,
  },
  {
    show: false,
    path: "/userprofile",
    layout: "/admin",
    name: "User Profile",
    icon: "nc-icon nc-money-coins",
    component: UserProfile,
  },
  {
    show: false,
    path: "/device-berhasil",
    layout: "/auth",
    name: "Device Berhasil",
    component: DeviceBerhasil,
  },
  {
    show: false,
    path: "/device-gagal",
    layout: "/auth",
    name: "Device Berhasil",
    component: DeviceGagal,
  },
  {
    show: false,
    path: "/privacy",
    layout: "/auth",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    show: false,
    path: "/termsandconditions",
    layout: "/auth",
    name: "Terms & Conditions",
    component: TermsConditions,
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    show: false,
    path: "/inputPengajuan",
    layout: "/auth",
    name: "Device Berhasil",
    component: addPengajuan,
  },
  // {
  //   show: true,
  //   path: "/user",
  //   layout: "/admin",
  //   name: "Data User",
  //   icon: "nc-icon nc-badge",
  //   component: TambahUser,
  // },
  // {
  //   path: "/listordervendor",
  //   layout: "/admin",
  //   name: "List Order Vendor",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: ListOrderVendor,
  // },
  // {
  //   collapse: true,
  //   path: "/pages",
  //   name: "Pages",
  //   state: "openPages",
  //   icon: "nc-icon nc-puzzle-10",
  //   views: [
  //     {
  //       path: "/login-page",
  //       layout: "/auth",
  //       name: "Login Page",
  //       mini: "LP",
  //       component: LoginPage,
  //     },
  //   ],
  // },
  {
    show: false,
    path: "/login-page",
    layout: "/auth",
    name: "Login Page",
    mini: "LP",
    component: LoginPage,
  },
  // {
  //   collapse: true,
  //   path: '/monitoringkebersihan',
  //   name: 'Monitoring',
  //   state: 'openKebersihan',
  //   icon: 'nc-icon nc-puzzle-10',
  //   views: [
  //     {
  //       path: '/dashboardmonitoring',
  //       layout: '/admin',
  //       name: 'Dashboard',
  //       mini: 'D',
  //       component: DashboardMonitoringPage,
  //     },
  //     {
  //       path: '/rekapmonitoring',
  //       layout: '/admin',
  //       name: 'Rekap Monitoring',
  //       mini: 'RM',
  //       component: RekapMonitoringPage,
  //     },
  //     {
  //       path: '/settingarea',
  //       layout: '/admin',
  //       name: 'Setting Area',
  //       mini: 'SA',
  //       component: SettingGedungPage,
  //     },
  //     {
  //       path: '/settingshift',
  //       layout: '/admin',
  //       name: 'Setting Shift',
  //       mini: 'SS',
  //       component: SettingShiftPage,
  //     },
  //   ],
  // },
];
export default routes;
