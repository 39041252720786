import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Collapse,
  Dropdown,
  InputGroup,
  Row,
  Col,
  Modal,
  Form,
  Tab,
  Table,
  Image,
  FormGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import {
  exportAllPengajuanExsist,
  exportAllPengajuanLunas,
  exportPencairan,
  exportPencairanExcel,
  getAllNotif,
  getAllPengajuan,
  getFormPengajuan,
  getPengajuanNonCair,
  updateAllAngsuran,
  updatePencairan,
  updatePengajuan,
} from "../../stores";
import DetailAngsuran from "./DetailAngsuran";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { actions } from "react-table";
import CurrencyInput from "react-currency-input-field";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Dna } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Pengajuan() {
  const dispatch = useDispatch();
  const date = new Date();
  const modalDetailAngsuran = useSelector((state) => state.pengajuanReducer);
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  const estimasi = pengajuan.estimasiNonCair;
  // console.log(pengajuan, "KKKK");

  const auth = useSelector((state) => state.authReducer);

  const [searchText, setSearchText] = React.useState("");
  const [searchTextNonCair, setSearchTextNonCair] = React.useState("");
  const [filterStatus, setFilterStatus] = React.useState(null);
  const [filterStatusNonCair, setFilterStatusNonCair] = React.useState(null);
  const [listPengajuan, setListPengajuan] = React.useState([]);
  const [listPengajuanNonCair, setListPengajuanNonCair] = React.useState([]);
  const [modalUpdateStatus, setModalUpdateStatus] = React.useState(false);
  const [modalExport, setModalExport] = React.useState(false);
  const [modalExportExcel, setModalExportExcel] = React.useState(false);
  const [idUser, setIdUser] = React.useState("");
  const [statusPengajuan, setStatusPengajuan] = React.useState("");
  const [statusKaryawan, setStatusKaryawan] = React.useState("");
  const [nominalPencairan, setNominalPencairan] = React.useState(0);
  const [tglPencairan, setTglPencairan] = React.useState("");
  const [idPengajuan, setIdPengajuan] = React.useState("");
  const [keterangan, setKeterangan] = React.useState("");
  const [tenor, setTenor] = React.useState(0);
  const [plafond, setPlafond] = React.useState("");
  const [fcmTokenUser, setFcmTokenUser] = React.useState("");
  const [nama, setNama] = React.useState("");
  const [nopeg, setNopeg] = React.useState("");
  const [typePengajuan, setTypePengajuan] = React.useState("");
  const [idPengajuanLama, setIdPengajuanLama] = React.useState("");
  const [idAngsuranPelunasan, setIdAngsuranPelunasan] = React.useState("");
  const [nominalBayar, setNominalBayar] = React.useState(0);
  const [nominalAdmin, setNominalAdmin] = React.useState(0);
  const [liststatus, setListStatus] = React.useState([]);
  const [liststatusNonCair, setListStatusNonCair] = React.useState([]);
  const [detailPengajuan, setDetailPengajuan] = React.useState("");
  const [detailPengajuanLama, setDetailPengajuanLama] = React.useState("");

  const userRole = auth.role;
  const [isLoading, setIsLoading] = React.useState(true);
  const [loading, setLoading] = React.useState(true);

  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [totalPerHalamanNonCair, setTotalPerHalamanNonCair] =
    React.useState(10);
  const [page, setPage] = React.useState(0);
  const [pageNonCair, setPageNonCair] = React.useState(0);
  const [pilihOpsi, setPilihOpsi] = React.useState("");
  const [pilihOpsiNonCair, setPilihOpsiNonCair] = React.useState("");
  const [exportTgl, setExportTgl] = React.useState(new Date());
  const [exportTglExcel, setExportTglExcel] = React.useState(new Date());
  const [tglAwal, setTglAwal] = React.useState(() => {
    const twoYearsAgo = new Date();
    twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);
    twoYearsAgo.setMonth(0);
    twoYearsAgo.setDate(1);
    twoYearsAgo.setHours(0, 0, 0, 0);
    return twoYearsAgo.getTime();
  });
  const [tglAkhir, setTglAkhir] = React.useState(new Date());
  const [collapsibleAccordion, setCollapsibleAccordion] = React.useState(-1);
  const [collapsibleAccordion2, setCollapsibleAccordion2] = React.useState(-1);
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };
  const handlePageSizeChangeNonCair = (selectedOption) => {
    setTotalPerHalamanNonCair(selectedOption.value);
  };
  const [detailAngsuran, setDetailAngsuran] = React.useState([]);
  const [statusPembayaran, setStatusPembayaran] = React.useState("");
  const [pencairan, setPencairan] = React.useState("");

  const [kirimFcm, setKirimFcm] = React.useState([]);

  const kirimTglUpdate = new Date();
  kirimTglUpdate.setDate(25);

  const formattedDateUpdate = kirimTglUpdate.toISOString().split("T")[0];
  // console.log(formattedDateUpdate, "LLL");

  const formattedValue = (e) => {
    return e.toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
  };
  const handleFilterChange = () => {
    setIsLoading(true);
    setPage(0);
    getAllPengajuan(dispatch, {
      statusPengajuan: pilihOpsi,
      page: page + 1,
      limit: totalPerHalaman,
      cari: searchText,
      tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
      tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
    })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const handleFilterChangeNonCair = () => {
    setLoading(true);
    setPageNonCair(0);
    getPengajuanNonCair(dispatch, {
      statusPengajuan: pilihOpsiNonCair,
      page: pageNonCair + 1,
      limit: totalPerHalamanNonCair,
      cari: searchTextNonCair,
    })
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const bukaPDF = async (pengajuanId) => {
    try {
      const data = { idPengajuan: pengajuanId };
      const response = await getFormPengajuan(data);
      if (response && response.status === 200 && response.url) {
        window.open(response.url, "_blank");
      } else {
        console.error("invalid pdf :", response);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterChange();
    }
  };
  const handleKeyDownNonCair = (e) => {
    if (e.key === "Enter") {
      handleFilterChangeNonCair();
    }
  };

  function submitUpdateStatus(e) {
    e.preventDefault();
    Swal.fire({
      title: "Konfirmasi",
      text: "Apakah Anda yakin ingin menyimpan perubahan?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    }).then((result) => {
      if (result.isConfirmed) {
        if (!statusPengajuan.value) {
          Swal.fire({
            title: "Status Pengajuan Harus Diisi",
            icon: "warning",
          });
          return;
        }

        const disetujuiTopup =
          statusPengajuan.value === "Diproses" && typePengajuan === "topup";

        if (disetujuiTopup) {
          if (tglPencairan === "" || tenor === 0) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Wajib di isi semua!",
            });
            return;
          } else {
            updatePengajuan({
              idUser: idUser,
              idPengajuan: idPengajuan,
              status: statusPengajuan.value,
              // statusKaryawan: statusKaryawan.value,
              nominalPencairan: parseInt(nominalPencairan),
              tglPencairan: tglPencairan,
              tenor: parseInt(tenor),
              noteReject: keterangan,
              fcmTokenUser: fcmTokenUser,
              nama: nama,
              nopeg: nopeg,
              typePengajuan: typePengajuan,
              idPengajuanSebelumnya: idPengajuanLama,
              nominalPembayaran: parseInt(nominalBayar),
              nominalBayarAdministrasi: parseInt(nominalAdmin),
              idAngsuranPelunasan: idAngsuranPelunasan,
            })
              .then((response) => {
                if (response.status === 200) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: response.message,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  setModalUpdateStatus(false);
                  setStatusPengajuan("");
                  getAllPengajuan(dispatch, {
                    statusPengajuan:
                      filterStatus === null || filterStatus.value === ""
                        ? ""
                        : filterStatus.value,
                    cari: "",
                    page: page + 1,
                    limit: totalPerHalaman,
                    tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
                    tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
                  });
                  getPengajuanNonCair(dispatch, {
                    statusPengajuan: pilihOpsiNonCair,
                    page: pageNonCair + 1,
                    limit: totalPerHalamanNonCair,
                    cari: searchTextNonCair,
                  });
                } else if (response.status === 400) {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response.data.message,
                  });
                }
              })
              .catch((err) => {
                console.error("Gagal:", err);
              });
          }
        } else if (statusPengajuan.value === "Diproses") {
          updatePengajuan({
            idUser: idUser,
            idPengajuan: idPengajuan,
            status: statusPengajuan.value,
            nominalPencairan: parseInt(nominalPencairan),
            tglPencairan: tglPencairan,
            tenor: parseInt(tenor),
            noteReject: keterangan,
            fcmTokenUser: fcmTokenUser,
            nama: nama,
            nopeg: nopeg,
            typePengajuan: typePengajuan,
            idPengajuanSebelumnya: idPengajuanLama,
            nominalPembayaran: parseInt(nominalBayar),
            nominalBayarAdministrasi: parseInt(nominalAdmin),
          }).then((response) => {
            if (response.status === 200) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: response.message,
                showConfirmButton: false,
                timer: 1500,
              });
              setModalUpdateStatus(false);
              setStatusPengajuan("");
              getAllPengajuan(dispatch, {
                statusPengajuan:
                  filterStatus === null || filterStatus.value === ""
                    ? ""
                    : filterStatus.value,
                cari: "",
                page: page + 1,
                limit: totalPerHalaman,
                tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
                tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
              });
              getPengajuanNonCair(dispatch, {
                statusPengajuan: pilihOpsiNonCair,
                page: pageNonCair + 1,
                limit: totalPerHalamanNonCair,
                cari: searchTextNonCair,
              });
            } else if (response.status === 400) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.data.message,
              });
            }
          });
        } else {
          if (
            statusPengajuan.value === "Ditolak" ||
            statusPengajuan.value === "Revisi"
          ) {
            if (keterangan === "") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Keterangan tidak boleh kosong",
              });
              return;
            }
          }
          // if (
          //   statusPengajuan.value === "Diproses" &&
          //   typePengajuan !== "topup" &&
          //   (nominalPencairan === "" || tglPencairan === "")
          // ) {
          //   Swal.fire({
          //     icon: "error",
          //     title: "Oops...",
          //     text: "Wajib di isi semua!",
          //   });
          //   return;
          // }

          updatePengajuan({
            idUser: idUser,
            idPengajuan: idPengajuan,
            status: statusPengajuan.value,
            // statusKaryawan: statusKaryawan.value,
            // nominalPencairan: parseInt(nominalPencairan),
            // tglPencairan: tglPencairan,
            // tenor: parseInt(tenor),
            noteReject: keterangan,
            fcmTokenUser: fcmTokenUser,
            nama: nama,
            nopeg: nopeg,
            typePengajuan: typePengajuan,
            idPengajuanSebelumnya: idPengajuanLama,
            nominalPembayaran: parseInt(nominalBayar),
            nominalBayarAdministrasi: parseInt(nominalAdmin),
            idAngsuranPelunasan: idAngsuranPelunasan,
          })
            .then((response) => {
              if (response.status === 200) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: response.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
                setModalUpdateStatus(false);
                setStatusPengajuan("");
                getAllPengajuan(dispatch, {
                  statusPengajuan:
                    filterStatus === null || filterStatus.value === ""
                      ? ""
                      : filterStatus.value,
                  cari: "",
                  page: page + 1,
                  limit: totalPerHalaman,
                  tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
                  tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
                });
                getPengajuanNonCair(dispatch, {
                  statusPengajuan: pilihOpsiNonCair,
                  page: pageNonCair + 1,
                  limit: totalPerHalamanNonCair,
                  cari: searchTextNonCair,
                });
              } else if (response.status === 400) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: response.data.message,
                });
              }
            })
            .catch((err) => {
              console.error("Gagal:", err);
            });
        }
      }
    });
  }

  // function submitUpdatePencairan(e) {
  //   e.preventDefault();

  //   updatePencairan({
  //     idPengajuan: idPengajuan,
  //     fcmTokenUser: fcmTokenUser,
  //     idUser: idUser,
  //   }).then((response) => {
  //     if (response.status === 200) {
  //       Swal.fire({
  //         position: "center",
  //         icon: "success",
  //         title: response.message,
  //         showConfirmButton: false,
  //         timer: 1500,
  //       });
  //       // setModalUpdateKategori(false);
  //       getAllPengajuan(dispatch, {
  //         statusPengajuan:
  //           filterStatus === null || filterStatus.value === ""
  //             ? ""
  //             : filterStatus.value,
  //         cari: "",
  //         page: page + 1,
  //         limit: totalPerHalaman,
  //         tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
  //         tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
  //       });
  //     } else if (response.status === 400) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: response.data.message,
  //       });
  //     }
  //   });
  // }

  function submitUpdateAllAngsuran(e) {
    e.preventDefault();

    Swal.fire({
      title: "Apakah Anda yakin ingin mengupdate semua angsuran?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        updateAllAngsuran({
          tglUpdate: formattedDateUpdate,
        }).then((response) => {
          Swal.close();
          if (response.status === 200) {
            Swal.fire({
              position: "center",
              // icon: "success",
              title: response.message,
              showConfirmButton: false,
              timer: 2500,
            });
            // setModalUpdateSimpanan(false);
            getAllSimpanan(dispatch, {
              cari: "",
              page: page + 1,
              limit: totalPerHalaman,
            });
          }
          // else if (response.status === 200) {
          //   Swal.fire({
          //     icon: "error",
          //     title: "Oops...",
          //     text: response.data.message,
          //   });
          // }
        });
        // .catch((error) => {
        //   console.error("XXXXXXXXXX", error);
        //   Swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: "Terjadi kesalahan saat mengirim data. Silakan coba lagi.",
        //   });
        // });
      }
    });
  }

  // function submitUpdateAllAngsuran(e) {
  //   e.preventDefault();

  //   Swal.fire({
  //     title: "Apakah Anda yakin ingin mengupdate semua angsuran?",
  //     icon: "question",
  //     showCancelButton: true,
  //     confirmButtonText: "Ya",
  //     cancelButtonText: "Tidak",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       updateAllAngsuran({
  //         tglUpdate: formattedDateUpdate,
  //       })
  //         .then((response) => {
  //           if (response.status === 200) {
  //             Swal.fire({
  //               position: "center",
  //               icon: "success",
  //               title: response.data.message,
  //               showConfirmButton: true,
  //             });
  //             setModalUpdateSimpanan(false);
  //             getAllSimpanan(dispatch, {
  //               cari: "",
  //               page: page + 1,
  //               limit: totalPerHalaman,
  //             });
  //           } else if (response.status === 400) {
  //             Swal.fire({
  //               icon: "error",
  //               title: "Oops...",
  //               text: response.data.message,
  //             });
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error submitting data:", error);
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: "Terjadi kesalahan saat mengirim data. Silakan coba lagi.",
  //           });
  //         });
  //     }
  //   });
  // }

  function submitExport(e) {
    e.preventDefault();

    exportPencairan({ tglPencairan: exportTgl });
  }

  const submitExportExcel = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Konfirmasi Export User",
      text: `Apakah Anda ingin mengexport ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, Export",
      cancelButtonText: "Batal",
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const data = await exportPencairanExcel({
            tglPencairan: exportTglExcel,
          });
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Export Pencairan.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          Swal.fire({
            icon: "success",
            title: "Data berhasil diexport",
          });
        } catch (error) {
          Swal.fire({
            title: "Terjadi kesalahan",
            text: error.message,
            icon: "error",
          });
        }
      },
    });
  };

  const submitExportAllPengajuan = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Konfirmasi Export All Pengajuan",
      text: `Apakah Anda ingin mengexport ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, Export",
      cancelButtonText: "Batal",
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const data = await exportAllPengajuanExsist({
            // tglPencairan: exportTglExcel,
          });
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Export Semua Pengajuan.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          Swal.fire({
            icon: "success",
            title: "Data berhasil diexport",
          });
        } catch (error) {
          Swal.fire({
            title: "Terjadi kesalahan",
            text: error.message,
            icon: "error",
          });
        }
      },
    });
  };

  const submitExportAllPengajuanLunas = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Konfirmasi Export Pengajuan",
      text: `Apakah Anda ingin mengexport ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, Export",
      cancelButtonText: "Batal",
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const data = await exportAllPengajuanLunas({
            // tglPencairan: exportTglExcel,
          });
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Export Pengajuan Lunas.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          Swal.fire({
            icon: "success",
            title: "Data berhasil diexport",
          });
        } catch (error) {
          Swal.fire({
            title: "Terjadi kesalahan",
            text: error.message,
            icon: "error",
          });
        }
      },
    });
  };

  const handleDetailClick = (val) => {
    dispatch({ type: "SET_MODAL_ANGSURAN", data: false });
    setDetailAngsuran(val.idPengajuan);
    setKirimFcm(val.user.fcmToken);
    setStatusPembayaran(val.statusPembayaran);
    setPencairan(val.pencairan);
    dispatch({ type: "SET_DETAIL_PENGAJUAN", data: val });
  };

  // const playNotificationSound = () => {
  //   const sound = new Audio("../../assets/sound/notif.mp3");
  //   sound
  //     .play()
  //     .catch((error) =>
  //       console.error("Gagal memainkan suara notifikasi:", error)
  //     );
  // };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const handleSearchNonCair = (e) => {
    setSearchTextNonCair(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };
  const handleClearSearchNonCair = () => {
    setSearchTextNonCair("");
  };

  const handleCloseModalUpdateStatus = () => {
    setStatusPengajuan("");
    setNominalPencairan(0);
    setTglPencairan("");
    setTenor(0);
    setKeterangan("");
    setNominalBayar(0);
    setNominalAdmin(0);
  };

  const handleCloseModalExport = () => {
    setExportTgl(new Date());
    setExportTglExcel(new Date());
  };

  React.useEffect(() => {
    let tmp =
      pengajuan.listAllPengajuan &&
      pengajuan.listAllPengajuan.docs.map((val, index) => {
        // console.log(val, "BARUU");
        return {
          ...val,
          action: (
            <div className="actions-right">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Detail</Tooltip>}
              >
                <Button
                  className="mr-2"
                  // variant="info"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                    backgroundColor: "#73B4E2",
                    border: 0,
                  }}
                  onClick={() => {
                    // console.log(val, "KKKK");
                    handleDetailClick(val);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Update Pengajuan</Tooltip>}
              >
                <Button
                  disabled={
                    (userRole === "hrd" &&
                      val.statusPengajuan !== "Diproses") ||
                    (userRole === "admin" && val.statusPengajuan !== "Menunggu")
                  }
                  className="mr-2"
                  variant="warning"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                    border: 0,
                    color: "white",
                    cursor:
                      (userRole === "hrd" &&
                        (val.statusPengajuan === "Menunggu" ||
                          val.statusPengajuan === "Revisi" ||
                          val.statusPengajuan === "Ditolak" ||
                          val.statusPengajuan === "Disetujui")) ||
                      (userRole === "admin" &&
                        (val.statusPengajuan !== "Menunggu" ||
                          val.statusPengajuan === "Disetujui"))
                        ? "not-allowed"
                        : "pointer",
                    backgroundColor:
                      (userRole === "hrd" &&
                        (val.statusPengajuan === "Menunggu" ||
                          val.statusPengajuan === "Revisi" ||
                          val.statusPengajuan === "Ditolak" ||
                          val.statusPengajuan === "Disetujui")) ||
                      (userRole === "admin" &&
                        (val.statusPengajuan !== "Menunggu" ||
                          val.statusPengajuan === "Disetujui"))
                        ? "grey"
                        : val.statusPengajuan === "Menunggu" &&
                          userRole === "hrd"
                        ? "grey"
                        : "#ffa534",
                  }}
                  onClick={() => {
                    // console.log(val, "DETAIAL PENGAJUAN");
                    setIdPengajuan(val.idPengajuan);
                    setIdUser(val.idUser);
                    setModalUpdateStatus(true);
                    setDetailPengajuan(val);
                    setDetailPengajuanLama(val.pengajuanLama);
                    setTenor(val.tenor);
                    setNominalPencairan(val.plafond);
                    setFcmTokenUser(val.user.fcmToken);
                    setNama(val.user.nama);
                    setNopeg(val.user.nopeg);
                    setTypePengajuan(val.typePengajuan);
                    setIdPengajuanLama(val.idPengajuanSebelumnya);
                    setPlafond(val.plafond);
                    setIdAngsuranPelunasan(val.idAngsuranPelunasan);
                    setNominalBayar(
                      val.pengajuanLama &&
                        val.pengajuanLama[0] &&
                        val.pengajuanLama[0].nominalSisaBayarPokok
                    );
                    setNominalAdmin(
                      val.pengajuanLama &&
                        val.pengajuanLama[0] &&
                        val.pengajuanLama[0].nominalSisaBayarAdmin
                    );
                  }}
                >
                  <i class="fas fa-edit"></i>
                </Button>
              </OverlayTrigger>
              {userRole === "admin" || userRole === "super admin" ? (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Update Pencairan</Tooltip>}
                  >
                    <Button
                      className="mr-2"
                      disabled={
                        userRole === "hrd" ||
                        val.pencairan === true ||
                        val.cancel === true ||
                        val.statusPengajuan === "Ditolak" ||
                        val.statusPengajuan !== "Disetujui"
                      }
                      style={{
                        marginTop: 5,
                        padding: "6px 15px",
                        fontSize: "18px",
                        border: 0,
                        cursor:
                          userRole === "hrd" ||
                          val.pencairan === true ||
                          val.cancel === true ||
                          val.statusPengajuan === "Ditolak" ||
                          val.statusPengajuan !== "Disetujui"
                            ? "not-allowed"
                            : "pointer",
                        backgroundColor:
                          userRole === "hrd" ||
                          val.pencairan === true ||
                          val.cancel === true ||
                          val.statusPengajuan === "Ditolak" ||
                          val.statusPengajuan !== "Disetujui"
                            ? "grey"
                            : "#63D7B0",
                      }}
                      // onClick={async () => {
                      //   const result = await Swal.fire({
                      //     title: "Update Pencairan",
                      //     text: `Apakah anda yakin nasabah ${val.user.nama} sudah cair ?`,
                      //     icon: "warning",
                      //     showCancelButton: true,
                      //     confirmButtonColor: "#3085d6",
                      //     cancelButtonColor: "#d33",
                      //     confirmButtonText: "Ya",
                      //   });

                      //   if (result.isConfirmed) {
                      //     try {
                      //       const response = await updatePencairan({
                      //         idPengajuan: val.idPengajuan,
                      //         fcmTokenUser: val.user.fcmToken,
                      //         idUser: val.user.idUser,
                      //       });
                      //       Swal.fire({
                      //         title: "Sukses",
                      //         text: response.data.message,
                      //         icon: "success",
                      //         showConfirmButton: false,
                      //         timer: 1500,
                      //       });
                      //       getAllPengajuan(dispatch, {
                      //         statusPengajuan:
                      //           filterStatus === null ||
                      //           filterStatus.value === ""
                      //             ? ""
                      //             : filterStatus.value,
                      //         cari: "",
                      //         page: page + 1,
                      //         limit: totalPerHalaman,
                      //         tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
                      //         tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
                      //       });
                      //       getPengajuanNonCair(dispatch, {
                      //         statusPengajuan: pilihOpsiNonCair,
                      //         page: pageNonCair + 1,
                      //         limit: totalPerHalamanNonCair,
                      //         cari: searchTextNonCair,
                      //       });
                      //     } catch (error) {
                      //       Swal.fire({
                      //         title: "Error",
                      //         text: error.response.data.message,
                      //         icon: "error",
                      //         showConfirmButton: false,
                      //         timer: 1500,
                      //       });
                      //     }
                      //   }
                      // }}
                      onClick={async () => {
                        const loadingSwal = Swal.fire({
                          title: "Loading...",
                          allowOutsideClick: false,
                          showConfirmButton: false,
                          onBeforeOpen: () => {
                            Swal.showLoading();
                          },
                        });

                        try {
                          const result = await Swal.fire({
                            title: "Update Pencairan",
                            text: `Apakah anda yakin nasabah ${val.user.nama} sudah cair ?`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Ya",
                          });

                          if (result.isConfirmed) {
                            const response = await updatePencairan({
                              idPengajuan: val.idPengajuan,
                              fcmTokenUser: val.user.fcmToken,
                              idUser: val.user.idUser,
                            });
                            loadingSwal.close();

                            Swal.fire({
                              title: "Sukses",
                              text: response.data.message,
                              icon: "success",
                              showConfirmButton: false,
                              timer: 1500,
                            });
                            getAllPengajuan(dispatch, {
                              statusPengajuan:
                                filterStatus === null ||
                                filterStatus.value === ""
                                  ? ""
                                  : filterStatus.value,
                              cari: "",
                              page: page + 1,
                              limit: totalPerHalaman,
                              tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
                              tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
                            });
                            getPengajuanNonCair(dispatch, {
                              statusPengajuan: pilihOpsiNonCair,
                              page: pageNonCair + 1,
                              limit: totalPerHalamanNonCair,
                              cari: searchTextNonCair,
                            });
                          }
                        } catch (error) {
                          loadingSwal.close();
                          Swal.fire({
                            title: "Error",
                            text: error.response.data.message,
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                          });
                        }
                      }}
                    >
                      <i
                        class="fas fa-money-check-alt"
                        style={{ marginRight: 5 }}
                      ></i>
                    </Button>
                  </OverlayTrigger>
                </>
              ) : null}

              {userRole === "admin" || userRole === "super admin" ? (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>Download Form Pengajuan Pinjaman</Tooltip>
                    }
                  >
                    <Button
                      className="mr-2"
                      disabled={val.pencairan === false}
                      style={{
                        marginTop: 5,
                        padding: "6px 15px",
                        fontSize: "18px",
                        border: 0,
                        cursor:
                          val.pencairan === false ? "not-allowed" : "pointer",
                        backgroundColor:
                          val.pencairan === false ? "grey" : "#4196F1",
                      }}
                      onClick={() => bukaPDF(val.idPengajuan)}
                    >
                      <i class="fas fa-download"></i>
                    </Button>
                  </OverlayTrigger>
                </>
              ) : null}
            </div>
          ),
        };
      });
    setListPengajuan(tmp);
  }, [pengajuan.listAllPengajuan, userRole]);

  React.useEffect(() => {
    let tmp =
      pengajuan.listPengajuanNonCair &&
      pengajuan.listPengajuanNonCair.docs.map((val, index) => {
        // console.log(val, "VALLL");
        return {
          ...val,
          action: (
            <div className="actions-right">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Detail</Tooltip>}
              >
                <Button
                  className="mr-2"
                  // variant="info"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                    backgroundColor: "#73B4E2",
                    border: 0,
                  }}
                  onClick={() => {
                    handleDetailClick(val);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Update Pengajuan</Tooltip>}
              >
                <Button
                  disabled={
                    (userRole === "hrd" &&
                      val.statusPengajuan !== "Diproses") ||
                    (userRole === "admin" && val.statusPengajuan !== "Menunggu")
                  }
                  className="mr-2"
                  variant="warning"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                    border: 0,
                    color: "white",
                    cursor:
                      (userRole === "hrd" &&
                        (val.statusPengajuan === "Menunggu" ||
                          val.statusPengajuan === "Revisi" ||
                          val.statusPengajuan === "Ditolak" ||
                          val.statusPengajuan === "Disetujui")) ||
                      (userRole === "admin" &&
                        (val.statusPengajuan !== "Menunggu" ||
                          val.statusPengajuan === "Disetujui"))
                        ? "not-allowed"
                        : "pointer",
                    backgroundColor:
                      (userRole === "hrd" &&
                        (val.statusPengajuan === "Menunggu" ||
                          val.statusPengajuan === "Revisi" ||
                          val.statusPengajuan === "Ditolak" ||
                          val.statusPengajuan === "Disetujui")) ||
                      (userRole === "admin" &&
                        (val.statusPengajuan !== "Menunggu" ||
                          val.statusPengajuan === "Disetujui"))
                        ? "grey"
                        : val.statusPengajuan === "Menunggu" &&
                          userRole === "hrd"
                        ? "grey"
                        : "#ffa534",
                  }}
                  onClick={() => {
                    // console.log(val, "DETAIAL PENGAJUAN");
                    setIdPengajuan(val.idPengajuan);
                    setIdUser(val.idUser);
                    setModalUpdateStatus(true);
                    setDetailPengajuan(val);
                    setDetailPengajuanLama(val.pengajuanLama);
                    setTenor(val.tenor);
                    setNominalPencairan(val.plafond);
                    setFcmTokenUser(val.user.fcmToken);
                    setNama(val.user.nama);
                    setNopeg(val.user.nopeg);
                    setTypePengajuan(val.typePengajuan);
                    setIdPengajuanLama(val.idPengajuanSebelumnya);
                    setPlafond(val.plafond);
                    setIdAngsuranPelunasan(val.idAngsuranPelunasan);
                    setNominalBayar(
                      val.pengajuanLama &&
                        val.pengajuanLama[0] &&
                        val.pengajuanLama[0].nominalSisaBayarPokok
                    );
                    setNominalAdmin(
                      val.pengajuanLama &&
                        val.pengajuanLama[0] &&
                        val.pengajuanLama[0].nominalSisaBayarAdmin
                    );
                  }}
                >
                  <i class="fas fa-edit"></i>
                </Button>
              </OverlayTrigger>
              {userRole === "admin" || userRole === "super admin" ? (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Update Pencairan</Tooltip>}
                  >
                    <Button
                      className="mr-2"
                      disabled={
                        userRole === "hrd" ||
                        val.pencairan === true ||
                        val.cancel === true ||
                        val.statusPengajuan === "Ditolak" ||
                        val.statusPengajuan !== "Disetujui"
                      }
                      style={{
                        marginTop: 5,
                        padding: "6px 15px",
                        fontSize: "18px",
                        border: 0,
                        cursor:
                          userRole === "hrd" ||
                          val.pencairan === true ||
                          val.cancel === true ||
                          val.statusPengajuan === "Ditolak" ||
                          val.statusPengajuan !== "Disetujui"
                            ? "not-allowed"
                            : "pointer",
                        backgroundColor:
                          userRole === "hrd" ||
                          val.pencairan === true ||
                          val.cancel === true ||
                          val.statusPengajuan === "Ditolak" ||
                          val.statusPengajuan !== "Disetujui"
                            ? "grey"
                            : "#63D7B0",
                      }}
                      onClick={async () => {
                        const loadingSwal = Swal.fire({
                          title: "Loading...",
                          allowOutsideClick: false,
                          showConfirmButton: false,
                          onBeforeOpen: () => {
                            Swal.showLoading();
                          },
                        });

                        try {
                          const result = await Swal.fire({
                            title: "Update Pencairan",
                            text: `Apakah anda yakin nasabah ${val.user.nama} sudah cair ?`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Ya",
                          });

                          if (result.isConfirmed) {
                            const response = await updatePencairan({
                              idPengajuan: val.idPengajuan,
                              fcmTokenUser: val.user.fcmToken,
                              idUser: val.user.idUser,
                            });
                            loadingSwal.close(); // Menutup loading Swal setelah panggilan berhasil

                            Swal.fire({
                              title: "Sukses",
                              text: response.data.message,
                              icon: "success",
                              showConfirmButton: false,
                              timer: 1500,
                            });

                            getAllPengajuan(dispatch, {
                              statusPengajuan:
                                filterStatus === null ||
                                filterStatus.value === ""
                                  ? ""
                                  : filterStatus.value,
                              cari: "",
                              page: page + 1,
                              limit: totalPerHalaman,
                              tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
                              tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
                            });
                            getPengajuanNonCair(dispatch, {
                              statusPengajuan: pilihOpsiNonCair,
                              page: pageNonCair + 1,
                              limit: totalPerHalamanNonCair,
                              cari: searchTextNonCair,
                            });
                          }
                        } catch (error) {
                          loadingSwal.close(); // Menutup loading Swal jika terjadi kesalahan
                          Swal.fire({
                            title: "Error",
                            text: error.response.data.message,
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                          });
                        }
                      }}
                    >
                      <i
                        class="fas fa-money-check-alt"
                        style={{ marginRight: 5 }}
                      ></i>
                    </Button>
                  </OverlayTrigger>
                </>
              ) : null}

              {userRole === "admin" || userRole === "super admin" ? (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>Download Form Pengajuan Pinjaman</Tooltip>
                    }
                  >
                    <Button
                      className="mr-2"
                      disabled={val.pencairan === false}
                      style={{
                        marginTop: 5,
                        padding: "6px 15px",
                        fontSize: "18px",
                        border: 0,
                        cursor:
                          val.pencairan === false ? "not-allowed" : "pointer",
                        backgroundColor:
                          val.pencairan === false ? "grey" : "#4196F1",
                      }}
                      onClick={() => bukaPDF(val.idPengajuan)}
                    >
                      <i class="fas fa-download"></i>
                    </Button>
                  </OverlayTrigger>
                </>
              ) : null}
            </div>
          ),
        };
      });
    setListPengajuanNonCair(tmp);
  }, [pengajuan.listPengajuanNonCair, userRole]);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const optionsNonCair = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const getTypePengajuanStyle = (pengajuanType) => {
    switch (pengajuanType) {
      case "topup":
        return {
          fontWeight: "bold",
          backgroundColor: "#D7E8EC",
          textColor: "#376B8A",
        };
      case "new":
        return {
          fontWeight: "bold",
          backgroundColor: "#FBF6EC",
          textColor: "#AD8945",
        };
      default:
        return {};
    }
  };

  const getStatusPembayaranStyle = (statusPembayaran) => {
    switch (statusPembayaran) {
      case "Angsur":
        return {
          fontWeight: "bold",
          backgroundColor: "#FAEBE9",
          textColor: "#D14C40",
        };
      case "Lunas":
        return {
          fontWeight: "bold",
          backgroundColor: "#E3F8F7",
          textColor: "#18B9B4",
        };
      default:
        return {};
    }
  };

  // const getStatusPencairan = (statusPencairan) => {
  //   switch (statusPencairan) {
  //     case false:
  //       return {
  //         fontWeight: "bold",
  //         backgroundColor: "#F7DFE9",
  //         textColor: "#B0054B",
  //       };
  //     case true:
  //       return {
  //         fontWeight: "bold",
  //         backgroundColor: "#E3EEF9",
  //         textColor: "#1569C7",
  //       };
  //     default:
  //       return {};
  //   }
  // };

  const getStatusPencairan = (statusPencairan, isCanceled) => {
    if (isCanceled) {
      return {
        fontWeight: "bold",
        backgroundColor: "#FF000D",
        textColor: "white",
      };
    } else {
      switch (statusPencairan) {
        case false:
          return {
            fontWeight: "bold",
            backgroundColor: "#F7DFE9",
            textColor: "#B0054B",
          };
        case true:
          return {
            fontWeight: "bold",
            backgroundColor: "#E3EEF9",
            textColor: "#1569C7",
          };
        default:
          return {};
      }
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Diproses":
        return {
          fontWeight: "bold",
          backgroundColor: "#D8C8F8",
          textColor: "#9368E9",
        };
      case "Menunggu":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFF2E6",
          textColor: "#FFA534",
        };
      case "Disetujui":
        return {
          fontWeight: "bold",
          backgroundColor: "#E4F5C9",
          textColor: "#87CB16",
        };
      case "Revisi":
        return {
          fontWeight: "bold",
          backgroundColor: "#FEBEC0",
          textColor: "#FB404B",
        };
      case "Ditolak":
        return {
          fontWeight: "bold",
          backgroundColor: "#E10F00",
          textColor: "#FFFFFF",
        };
      default:
        return {};
    }
  };

  const getStatusKaryawan = (status) => {
    switch (status) {
      case "No":
        return {
          fontWeight: "bold",
          backgroundColor: "#E6F2FF",
          textColor: "#007FFF",
        };
      case "Yes":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE6E6",
          textColor: "#FF0000",
        };
      default:
        return {};
    }
  };

  const getStatusKaryawanBaru = (status) => {
    switch (status) {
      case "No":
        return {
          fontWeight: "bold",
          backgroundColor: "#E6F2FF",
          textColor: "#007FFF",
        };
      case "Yes":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE6E6",
          textColor: "#FF0000",
        };
      default:
        return {};
    }
  };

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: false,
      },
      {
        value: "Menunggu",
        label: "Menunggu",
      },
      {
        value: "Diproses",
        label: "Diproses",
      },

      {
        value: "Disetujui",
        label: "Disetujui",
      },
      {
        value: "Ditolak",
        label: "Ditolak",
      },
      {
        value: "Revisi",
        label: "Revisi",
      },
      {
        value: "Angsur",
        label: "Angsur",
      },
      {
        value: "Macet",
        label: "Macet",
      },
      {
        value: "Lunas",
        label: "Lunas",
      },
    ];
    setListStatus(tmp);
  }, [pengajuan.listAllPengajuan, page, totalPerHalaman]);

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: false,
      },
      {
        value: "Menunggu",
        label: "Menunggu",
      },
      {
        value: "Diproses",
        label: "Diproses",
      },

      {
        value: "Disetujui",
        label: "Disetujui",
      },
      {
        value: "Ditolak",
        label: "Ditolak",
      },
      {
        value: "Revisi",
        label: "Revisi",
      },
    ];
    setListStatusNonCair(tmp);
  }, [pengajuan.listPengajuanNonCair, pageNonCair, totalPerHalamanNonCair]);

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colPengajuan = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) =>
        index + pengajuan.listAllPengajuan.pagingCounter,
    },
    {
      name: "Referensi ID",
      minWidth: "240px",
      center: true,
      selector: (row) => row.idPengajuan,
    },
    {
      name: "Nama",
      minWidth: "250px",
      center: true,
      selector: (row) => row.user.nama,
    },
    {
      name: "Nomor Pegawai",
      minWidth: "180px",
      center: true,
      selector: (row) => row.user.nopeg,
    },
    {
      name: "Status Karyawan",
      minWidth: "180px",
      center: true,
      selector: (row) => row.user.resign,
      cell: (row) => {
        const statusStyle = getStatusKaryawan(row.user.resign);
        const tampilanLabel = row.user.resign;

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
    {
      name: "Tanggal Resign",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        if (!row.user.tglResign || row.user.tglResign === "") {
          return "-";
        } else {
          return moment(row.user.tglResign).format("DD MMMM YYYY");
        }
      },
    },
    {
      name: "Nominal Permohonan",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.plafond);
        return formattedValue;
      },
    },
    {
      name: "Tanggal Pencairan",
      minWidth: "180px",
      center: true,
      selector: (row) =>
        !row.tglPencairan
          ? "-"
          : moment(row.tglPencairan).format("DD MMMM YYYY"),
    },

    {
      name: "Nominal Administrasi",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalAdministrasi);
        return formattedValue;
      },
    },
    {
      name: "Nominal Pencairan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const nominalPencairan = isNaN(row.nominalPencairan)
          ? 0
          : row.nominalPencairan;

        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(nominalPencairan);

        return formattedValue;
      },
    },
    {
      name: "Tenor",
      minWidth: "180px",
      center: true,
      selector: (row) => (!row.tenor ? "-" : `${row.tenor} Bulan`),
    },
    {
      name: "Angsuran",
      minWidth: "180px",
      center: true,
      selector: (row) =>
        row.listAngsuran.length === 0 ? 0 : row.listAngsuran.length,
    },
    {
      name: "Angsuran Ke",
      minWidth: "180px",
      center: true,
      selector: (row) => row.angsuranKe,
    },
    {
      name: "Status Pembayaran",
      minWidth: "200px",
      center: true,
      selector: (row) => (row.statusPembayaran ? row.statusPembayaran : "-"),
      cell: (row) => {
        const statusStyle = getStatusPembayaranStyle(row.statusPembayaran);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusPembayaran ? row.statusPembayaran : "-"}
          </div>
        );
      },
    },
    {
      name: "Pengajuan Cancel",
      minWidth: "180px",
      center: true,
      selector: (row) => (row.cancel === true ? "Cancel" : "-"),
      cell: (row) => (
        <div
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
            color: row.cancel === true ? "red" : "black",
          }}
        >
          {row.cancel === true ? "Cancel" : "-"}
        </div>
      ),
    },
    {
      name: "Tipe Pengajuan",
      minWidth: "180px",
      center: true,
      selector: (row) => row.typePengajuan,
      cell: (row) => {
        const statusStyle = getTypePengajuanStyle(row.typePengajuan);
        const tampilanLabel = row.typePengajuan === "new" ? "New" : "Top Up";

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
    {
      name: "Status Pengajuan",
      minWidth: "200px",
      center: true,
      selector: (row) => row.statusPengajuan,
      cell: (row) => {
        const statusStyle = getStatusStyle(row.statusPengajuan);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusPengajuan}
          </div>
        );
      },
    },
    {
      name: "Pencairan",
      minWidth: "180px",
      center: true,
      selector: (row) =>
        row.pencairan === false ? "Belum Pencairan" : "Sudah Pencairan",
      cell: (row) => {
        const statusStyle = getStatusPencairan(row.pencairan, row.cancel);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.pencairan === false ? "Belum Pencairan" : "Sudah Pencairan"}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "300px",
      center: true,
      cell: (row) => row.action,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.cancel === true && row.statusPengajuan !== "Ditolak",
      style: {
        backgroundColor: "#FFDBDB",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.cancel === true && row.statusPengajuan === "Ditolak",
      style: {
        backgroundColor: "#FFFFFF",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) =>
        row.listAngsuran.length != row.tenor &&
        (row.statusPengajuan === "Diproses" ||
          row.statusPengajuan === "Disetujui"),
      style: {
        backgroundColor: "#B3C6FF",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const customStylesBaru = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colPengajuanBaru = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) =>
        index + pengajuan.listPengajuanNonCair.pagingCounter,
    },
    {
      name: "Referensi ID",
      minWidth: "240px",
      center: true,
      selector: (row) => row.idPengajuan,
    },
    {
      name: "Nama",
      minWidth: "250px",
      center: true,
      selector: (row) => row.user.nama,
    },
    {
      name: "Nomor Pegawai",
      minWidth: "180px",
      center: true,
      selector: (row) => row.user.nopeg,
    },
    {
      name: "Status Karyawan",
      minWidth: "180px",
      center: true,
      selector: (row) => row.user.resign,
      cell: (row) => {
        const statusStyle = getStatusKaryawanBaru(row.user.resign);
        const tampilanLabel = row.user.resign;

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
    {
      name: "Tanggal Resign",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        if (!row.user.tglResign || row.user.tglResign === "") {
          return "-";
        } else {
          return moment(row.user.tglResign).format("DD MMMM YYYY");
        }
      },
    },
    {
      name: "Nominal Permohonan",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.plafond);
        return formattedValue;
      },
    },
    {
      name: "Nominal Administrasi",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalAdministrasi);
        return formattedValue;
      },
    },
    {
      name: "Nominal Pencairan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const nominalPencairan = isNaN(row.nominalPencairan)
          ? 0
          : row.nominalPencairan;

        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(nominalPencairan);

        return formattedValue;
      },
    },
    {
      name: "Tenor",
      minWidth: "180px",
      center: true,
      selector: (row) => (!row.tenor ? "-" : `${row.tenor} Bulan`),
    },
    {
      name: "Status Pembayaran",
      minWidth: "200px",
      center: true,
      selector: (row) => (row.statusPembayaran ? row.statusPembayaran : "-"),
      cell: (row) => {
        const statusStyle = getStatusPembayaranStyle(row.statusPembayaran);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusPembayaran ? row.statusPembayaran : "-"}
          </div>
        );
      },
    },
    {
      name: "Pengajuan Cancel",
      minWidth: "180px",
      center: true,
      selector: (row) => (row.cancel === true ? "Cancel" : "-"),
      cell: (row) => (
        <div
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
            color: row.cancel === true ? "red" : "black",
          }}
        >
          {row.cancel === true ? "Cancel" : "-"}
        </div>
      ),
    },
    {
      name: "Tipe Pengajuan",
      minWidth: "180px",
      center: true,
      selector: (row) => row.typePengajuan,
      cell: (row) => {
        const statusStyle = getTypePengajuanStyle(row.typePengajuan);
        const tampilanLabel = row.typePengajuan === "new" ? "New" : "Top Up";

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
    {
      name: "Status Pengajuan",
      minWidth: "200px",
      center: true,
      selector: (row) => row.statusPengajuan,
      cell: (row) => {
        const statusStyle = getStatusStyle(row.statusPengajuan);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusPengajuan}
          </div>
        );
      },
    },
    // {
    //   name: "Pencairan",
    //   minWidth: "180px",
    //   center: true,
    //   selector: (row) =>
    //     row.pencairan === false ? "Belum Pencairan" : "Sudah Pencairan",
    //   cell: (row) => {
    //     const statusStyle = getStatusPencairan(row.pencairan);
    //     return (
    //       <div
    //         style={{
    //           fontWeight: statusStyle.fontWeight,
    //           backgroundColor: statusStyle.backgroundColor,
    //           color: statusStyle.textColor,
    //           padding: "10px",
    //           borderRadius: "5px",
    //         }}
    //       >
    //         {row.pencairan === false ? "Belum Pencairan" : "Sudah Pencairan"}
    //       </div>
    //     );
    //   },
    // },
    {
      name: "Pencairan",
      minWidth: "180px",
      center: true,
      selector: (row) =>
        row.pencairan === false ? "Belum Pencairan" : "Sudah Pencairan",
      cell: (row) => {
        const statusStyle = getStatusPencairan(row.pencairan, row.cancel);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.pencairan === false ? "Belum Pencairan" : "Sudah Pencairan"}
          </div>
        );
      },
    },
    {
      name: "Tanggal Pencairan",
      minWidth: "180px",
      center: true,
      selector: (row) =>
        !row.tglPencairan
          ? "-"
          : moment(row.tglPencairan).format("DD MMMM YYYY"),
    },
    {
      name: "Aksi",
      minWidth: "300px",
      center: true,
      cell: (row) => row.action,
    },
  ];

  const conditionalRowStylesBaru = [
    {
      when: (row) => row.cancel === true && row.statusPengajuan !== "Ditolak",
      style: {
        backgroundColor: "#FFDBDB",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.cancel === true && row.statusPengajuan === "Ditolak",
      style: {
        backgroundColor: "#FFFFFF",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const optionStatusPengajuan = [
    {
      value: "",
      label: "Pilih Status",
      isDisabled: true,
    },
    {
      value: "Diproses",
      label: "Diproses",
    },
    {
      value: "Revisi",
      label: "Revisi",
    },
    {
      value: "Ditolak",
      label: "Ditolak",
    },
    {
      value: "Disetujui",
      label: "Disetujui",
    },
  ];

  const filteredOptions = (userRole, cancel) => {
    if (cancel) {
      return [{ value: "Ditolak", label: "Ditolak" }];
    }
    if (userRole === "hrd") {
      return optionStatusPengajuan.filter(
        (option) =>
          option.value === "Disetujui" ||
          option.value === "Ditolak" ||
          option.value === "Revisi"
      );
    } else if (userRole === "admin") {
      return optionStatusPengajuan.filter(
        (option) =>
          option.value === "Diproses" ||
          option.value === "Ditolak" ||
          option.value === "Revisi"
      );
    } else {
      return optionStatusPengajuan;
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    getAllPengajuan(dispatch, {
      statusPengajuan:
        filterStatus === null || filterStatus.value === ""
          ? ""
          : filterStatus.value,
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
      tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
      tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
    })
      .then((response) => {
        // setLoading(false);
        setIsLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [modalDetailAngsuran.modalDetailAngsuran, page, totalPerHalaman]);

  React.useEffect(() => {
    setLoading(true);
    getPengajuanNonCair(dispatch, {
      statusPengajuan:
        filterStatusNonCair === null || filterStatusNonCair.value === ""
          ? ""
          : filterStatusNonCair.value,
      cari: searchTextNonCair,
      page: pageNonCair + 1,
      limit: totalPerHalamanNonCair,
    })
      .then((response) => {
        // setLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [
    modalDetailAngsuran.modalDetailAngsuran,
    pageNonCair,
    totalPerHalamanNonCair,
  ]);

  return (
    <>
      <Modal
        size="md"
        show={modalExportExcel}
        onHide={() => {
          handleCloseModalExport();
          setModalExportExcel(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card style={{ border: 0 }}>
                  <Card.Header>
                    <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                      Export Pencairan (Excel)
                    </Card.Title>
                  </Card.Header>
                  <hr></hr>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Pilih Tanggal Pencairan
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              // console.log(e.target.value, "PPP");
                              setExportTglExcel(e.target.value);
                            }}
                            type="date"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitExportExcel}
                      // onClick={submitExportExcelNonCair}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {modalDetailAngsuran.modalDetailAngsuran ? (
        <>
          <Container fluid>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>
                    <Card.Title
                      as="h3"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      List Pengajuan Baru
                      <hr></hr>
                    </Card.Title>
                    <Tab.Content>
                      <Card
                        style={{
                          border: 0,
                          marginTop: 20,
                        }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          <Col md={"4"} style={{ marginTop: 15 }}>
                            <InputGroup>
                              <Form.Control
                                style={{
                                  paddingLeft: "10px",
                                }}
                                type="text"
                                value={searchTextNonCair}
                                onChange={handleSearchNonCair}
                                onKeyDown={handleKeyDownNonCair}
                                placeholder="Referensi ID/Nopeg/Nama"
                              />
                              <InputGroup.Append>
                                <InputGroup.Text
                                  style={{ cursor: "pointer" }}
                                  onClick={handleClearSearchNonCair}
                                >
                                  <i class="fas fa-times"></i>
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </Col>

                          <Col md={"3"} style={{ marginTop: 15 }}>
                            <div>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Status"
                                value={filterStatusNonCair}
                                onChange={(selectedOption) => {
                                  setFilterStatusNonCair(selectedOption);
                                  setPilihOpsiNonCair(selectedOption.value);
                                }}
                                options={[
                                  { value: "", label: "All" },
                                  ...liststatusNonCair,
                                ]}
                                onKeyDown={handleKeyDownNonCair}
                                placeholder="Pilih Status Pengajuan"
                              />
                            </div>
                          </Col>
                          <Col>
                            <Button
                              // variant="info"
                              style={{
                                fontSize: 16,
                                backgroundColor: "#00BFFF",
                                border: 0,
                                paddingTop: 10,
                                paddingBottom: 7,
                                marginTop: 15,
                              }}
                              onClick={handleFilterChangeNonCair}
                            >
                              <i class="fas fa-search"></i>
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                      <Row>
                        <div style={{ marginTop: 15 }}>
                          <Button
                            className="btn-wd "
                            style={{
                              // fontSize: 15,
                              backgroundColor: "#FF355E",
                              border: 0,
                              paddingTop: 10,
                              paddingBottom: 7,
                              marginLeft: 15,
                              fontWeight: "bold",
                            }}
                            onClick={() => {
                              setModalExport(true);
                            }}
                          >
                            <i
                              className="fas fa-file-pdf fa-lg"
                              style={{ marginRight: 10 }}
                            ></i>
                            Export Pencairan (Pdf)
                          </Button>
                        </div>
                        <div style={{ marginTop: 15, marginLeft: 15 }}>
                          <Button
                            className="btn-wd "
                            onClick={(e) => {
                              setModalExportExcel(true);
                            }}
                            // variant="success"
                            style={{
                              backgroundColor: "#6BD98A",
                              border: 0,
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            <i
                              className="fas fa-file-excel fa-lg"
                              style={{ marginRight: 10 }}
                            ></i>
                            Export Pencairan (Excel)
                          </Button>
                        </div>
                      </Row>
                      {/* </Col> */}
                      <div
                        style={{
                          position: "absolute",
                          top: "185px",
                          right: "20px",
                          backgroundColor: "#ffffff",
                          borderRadius: "8px",
                          padding: "10px 20px",
                          boxShadow: "0 3px 8px rgba(0, 1, 0, 0.3)",
                        }}
                      >
                        {estimasi !== undefined &&
                        estimasi !== null &&
                        estimasi !== "" ? (
                          <span style={{ fontSize: "16px", color: "#527BB6" }}>
                            <strong>Estimasi :</strong>{" "}
                            <strong>
                              Rp{" "}
                              {estimasi.toLocaleString("id-ID", {
                                minimumFractionDigits: 0,
                              })}
                            </strong>
                          </span>
                        ) : (
                          <Skeleton width={150} height={20} />
                        )}
                      </div>
                    </Tab.Content>
                  </Card.Header>

                  <Card.Body>
                    {loading ? (
                      <div
                        className="loader-container"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "30vh",
                        }}
                      >
                        <Dna
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="dna-loading"
                          wrapperStyle={{}}
                          wrapperClass="dna-wrapper"
                        />
                        <p>Loading...</p>
                      </div>
                    ) : (
                      <div>
                        {listPengajuanNonCair.length > 0 ? (
                          <DataTable
                            columns={colPengajuanBaru}
                            data={listPengajuanNonCair}
                            customStyles={customStylesBaru}
                            paginationPerPage={totalPerHalaman}
                            progressPending={loading}
                            conditionalRowStyles={conditionalRowStylesBaru}
                          />
                        ) : (
                          <div style={{ textAlign: "center" }}>
                            <p
                              style={{
                                fontWeight: "bold",
                                marginBottom: "1rem",
                              }}
                            >
                              Maaf, belum ada pengajuan baru saat ini.
                            </p>
                            <img
                              src={require("assets/img/sorry.jpg").default}
                              alt="Sorry"
                              style={{
                                maxWidth: "20%",
                                display: "block",
                                margin: "0 auto",
                              }}
                            />
                          </div>
                        )}
                        <div style={{ marginTop: 35 }}>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col>
                              <Row style={{ alignItems: "center" }}>
                                <div
                                  style={{
                                    paddingRight: 0,
                                    marginLeft: 20,
                                    marginRight: 10,
                                  }}
                                >
                                  <label style={{ fontWeight: "bold" }}>
                                    Rows per page
                                  </label>
                                </div>
                                <Col sm="3" style={{ paddingLeft: 0 }}>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="Pilih Client"
                                    value={optionsNonCair.find(
                                      (option) =>
                                        option.value === totalPerHalamanNonCair
                                    )}
                                    onChange={handlePageSizeChangeNonCair}
                                    options={optionsNonCair}
                                    placeholder="Rows per page"
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col>
                              <ReactPaginate
                                forcePage={pageNonCair}
                                containerClassName="paginations justify-content-end"
                                previousClassName="pages-items-containers"
                                previousLinkClassName="pages-links-labels"
                                nextClassName="pages-items-containers"
                                nextLinkClassName="pages-links-labels"
                                breakClassName="pages-items-containers"
                                pageCount={
                                  pengajuan.listPengajuanNonCair.totalPages
                                }
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                pageLinkClassName="pages-links-labels"
                                pageClassName="pages-items-containers"
                                activeClassName="actives"
                                activeLinkClassName="activess"
                                nextLabel=" > "
                                previousLabel=" < "
                                initialPage={page}
                                disableInitialCallback={true}
                                onPageChange={(val) => {
                                  setPageNonCair(val.selected);
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

          <Container fluid>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>
                    <Card.Title
                      as="h3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "black",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      <span style={{ flex: "1" }}>List Semua Pengajuan</span>
                      {auth.role !== "hrd" ? (
                        <>
                          <Button
                            onClick={submitUpdateAllAngsuran}
                            style={{
                              marginRight: 20,
                              backgroundColor: "#C7D2AE",
                              color: "#171F04",
                              border: 0,
                              fontWeight: "bold",
                            }}
                          >
                            Update All Angsuran
                          </Button>
                        </>
                      ) : null}

                      <Button
                        onClick={submitExportAllPengajuan}
                        style={{
                          backgroundColor: "#F3DADA",
                          color: "#840000",
                          border: 0,
                          fontWeight: "bold",
                        }}
                      >
                        Export All Pengajuan
                      </Button>

                      <Button
                        onClick={submitExportAllPengajuanLunas}
                        style={{
                          backgroundColor: "#E4DAD3",
                          marginLeft: 18,
                          color: "#795D4C",
                          border: 0,
                          fontWeight: "bold",
                        }}
                      >
                        Export Pengajuan Lunas
                      </Button>
                    </Card.Title>
                    <hr />

                    <Tab.Content>
                      <Card
                        style={{
                          border: 0,
                          marginTop: 20,
                        }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          <Col md={2}>
                            <h5
                              className="title mt-2"
                              style={{ fontWeight: "bold" }}
                            >
                              Tanggal Awal
                            </h5>
                            <div>
                              <ReactDatetime
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Date Picker Here",
                                  readOnly: true,
                                  style: {
                                    cursor: "default",
                                    color: "black",
                                    backgroundColor: "white",
                                  },
                                }}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => {
                                  e.toDate().setHours(0, 0, 0, 0);
                                  setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                                }}
                                value={tglAwal}
                                initialValue={tglAwal}
                                timeFormat={false}
                              />
                            </div>
                          </Col>
                          <Col md={2}>
                            <h5
                              className="title mt-2"
                              style={{ fontWeight: "bold" }}
                            >
                              Tanggal Akhir
                            </h5>
                            <div>
                              <ReactDatetime
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Date Picker Here",
                                  readOnly: true,
                                  style: {
                                    cursor: "default",
                                    color: "black",
                                    backgroundColor: "white",
                                  },
                                }}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => {
                                  e.toDate().setHours(0, 0, 0, 0);
                                  setTglAkhir(e.toDate().setHours(0, 0, 0, 0));
                                }}
                                value={tglAkhir}
                                initialValue={tglAkhir}
                                timeFormat={false}
                              />
                            </div>
                          </Col>
                          <Col md={"4"} style={{ marginTop: 45 }}>
                            <InputGroup>
                              <Form.Control
                                style={{
                                  paddingLeft: "10px",
                                }}
                                type="text"
                                value={searchText}
                                onChange={handleSearch}
                                onKeyDown={handleKeyDown}
                                placeholder="Referensi ID/Nopeg/Nama"
                              />
                              <InputGroup.Append>
                                <InputGroup.Text
                                  style={{ cursor: "pointer" }}
                                  onClick={handleClearSearch}
                                >
                                  <i class="fas fa-times"></i>
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </Col>

                          <Col md={"3"} style={{ marginTop: 45 }}>
                            <div>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Status"
                                value={filterStatus}
                                onChange={(selectedOption) => {
                                  setFilterStatus(selectedOption);
                                  setPilihOpsi(selectedOption.value);
                                }}
                                options={[
                                  { value: "", label: "All" },
                                  ...liststatus,
                                ]}
                                onKeyDown={handleKeyDown}
                                placeholder="Pilih Status Pengajuan"
                              />
                            </div>
                          </Col>
                          <Col>
                            <Button
                              // variant="info"
                              style={{
                                fontSize: 16,
                                backgroundColor: "#00BFFF",
                                border: 0,
                                paddingTop: 10,
                                paddingBottom: 7,
                                marginTop: 45,
                              }}
                              onClick={handleFilterChange}
                            >
                              <i class="fas fa-search"></i>
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                      {/* <Button
                        style={{
                          // fontSize: 15,
                          backgroundColor: "#FF355E",
                          border: 0,
                          paddingTop: 10,
                          paddingBottom: 7,
                        }}
                        onClick={() => {
                          setModalExport(true);
                        }}
                      >
                        <i
                          className="fas fa-file-pdf fa-lg"
                          style={{ marginRight: 10 }}
                        ></i>
                        Export Pencairan
                      </Button> */}
                      {/* </Col> */}
                    </Tab.Content>
                  </Card.Header>
                  <Card.Body>
                    {isLoading ? (
                      <div
                        className="loader-container"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "30vh",
                        }}
                      >
                        <Dna
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="dna-loading"
                          wrapperStyle={{}}
                          wrapperClass="dna-wrapper"
                        />
                        <p>Loading...</p>
                      </div>
                    ) : (
                      <div>
                        <DataTable
                          columns={colPengajuan}
                          data={listPengajuan}
                          customStyles={customStyles}
                          paginationPerPage={totalPerHalaman}
                          progressPending={isLoading}
                          conditionalRowStyles={conditionalRowStyles}
                        />
                        <div style={{ marginTop: 35 }}>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col>
                              <Row style={{ alignItems: "center" }}>
                                <div
                                  style={{
                                    paddingRight: 0,
                                    marginLeft: 20,
                                    marginRight: 10,
                                  }}
                                >
                                  <label style={{ fontWeight: "bold" }}>
                                    Rows per page
                                  </label>
                                </div>
                                <Col sm="3" style={{ paddingLeft: 0 }}>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="Pilih Client"
                                    value={options.find(
                                      (option) =>
                                        option.value === totalPerHalaman
                                    )}
                                    onChange={handlePageSizeChange}
                                    options={options}
                                    placeholder="Rows per page"
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col>
                              <ReactPaginate
                                forcePage={page}
                                containerClassName="paginations justify-content-end"
                                previousClassName="pages-items-containers"
                                previousLinkClassName="pages-links-labels"
                                nextClassName="pages-items-containers"
                                nextLinkClassName="pages-links-labels"
                                breakClassName="pages-items-containers"
                                pageCount={
                                  pengajuan.listAllPengajuan.totalPages
                                }
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                pageLinkClassName="pages-links-labels"
                                pageClassName="pages-items-containers"
                                activeClassName="actives"
                                activeLinkClassName="activess"
                                nextLabel=" > "
                                previousLabel=" < "
                                initialPage={page}
                                disableInitialCallback={true}
                                onPageChange={(val) => {
                                  setPage(val.selected);
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

          <Modal
            size="md"
            show={modalExport}
            onHide={() => {
              handleCloseModalExport();
              setModalExport(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Row>
                <Col md="12">
                  <Form action="" className="form" method="">
                    <Card style={{ border: 0 }}>
                      <Card.Header>
                        <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                          Export Pencairan
                        </Card.Title>
                      </Card.Header>
                      <hr></hr>
                      <Card.Body>
                        <Row>
                          <Col sm="12">
                            <Form.Group>
                              <label
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  color: "grey",
                                  textTransform: "none",
                                }}
                              >
                                Pilih Tanggal Pencairan
                              </label>
                              {/* <div>
                                <ReactDatetime
                                  inputProps={{
                                    className: "form-control",
                                    placeholder: "Date Picker Here",
                                    readOnly: true,
                                    style: {
                                      cursor: "default",
                                      color: "black",
                                      backgroundColor: "white",
                                    },
                                  }}
                                  onChange={(e) => {
                                    setExportTgl(e);
                                  }}
                                  value={exportTgl}
                                  initialValue={exportTgl}
                                  timeFormat={false}
                                />
                              </div> */}
                              <Form.Control
                                onChange={(e) => {
                                  // console.log(e.target.value, "PPP");
                                  setExportTgl(e.target.value);
                                }}
                                type="date"
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          onClick={submitExport}
                        >
                          Submit
                        </Button>
                        <div className="clearfix"></div>
                      </Card.Body>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>

          <Modal
            size="lg"
            show={modalUpdateStatus}
            onHide={() => {
              handleCloseModalUpdateStatus();
              setModalUpdateStatus(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Row>
                <Col md="12">
                  <Form action="" className="form" method="">
                    <Card style={{ border: 0 }}>
                      <Card.Header>
                        <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                          Update Status Pengajuan
                        </Card.Title>
                      </Card.Header>
                      <hr></hr>
                      <Card.Body>
                        {detailPengajuan.cancel ? (
                          <div className="row">
                            <div className="col-sm-12">
                              <Card
                                style={{
                                  backgroundColor: "#FFC4C4",
                                  borderRadius: 10,
                                  padding: 0,
                                  position: "relative",
                                  display: "flex",
                                }}
                              >
                                <i
                                  class="fas fa-info-circle"
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "10px",
                                    fontSize: "25px",
                                    transform: "translateY(-50%)",
                                    color: "#FF5E5E",
                                  }}
                                ></i>
                                <label
                                  style={{
                                    color: "#FF5E5E",
                                    marginLeft: 50,
                                    fontWeight: "bold",
                                    fontSize: 16,
                                    marginTop: 10,
                                  }}
                                >
                                  {detailPengajuan.cancel ? "Cancel" : null}
                                </label>
                                {/* {valDetail.statusPengajuan === "Menunggu Konfirmasi" && ( */}
                                <p
                                  style={{
                                    marginLeft: 50,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {detailPengajuan.noteCancel}
                                </p>
                                {/* )} */}
                              </Card>
                            </div>
                          </div>
                        ) : null}
                        {typePengajuan === "topup" ? (
                          <Card style={{ border: 0 }}>
                            <Card.Body style={{ padding: 0 }}>
                              <div className="accordions" id="accordion">
                                <Card>
                                  <Card.Header>
                                    <Card.Title as="h4">
                                      <a
                                        aria-expanded={
                                          collapsibleAccordion === 1
                                        }
                                        data-toggle="collapse"
                                        href="#pablo"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          collapsibleAccordion === 1
                                            ? setCollapsibleAccordion(-1)
                                            : setCollapsibleAccordion(1);
                                        }}
                                      >
                                        Detail Pengajuan{" "}
                                        <b className="caret"></b>
                                      </a>
                                    </Card.Title>
                                  </Card.Header>
                                  <Collapse
                                    className="card-collapse"
                                    id="collapseOneHover"
                                    in={collapsibleAccordion === 1}
                                  >
                                    {/* <Card> */}
                                    <Card.Body>
                                      <div className="col-12">
                                        <Row>
                                          <Col sm="6">
                                            <li>
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                Nama
                                              </label>
                                            </li>
                                          </Col>
                                          <Col sm="o">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              :
                                            </label>
                                          </Col>
                                          <Col sm="5">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              {nama}
                                            </label>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <li>
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                Nopeg
                                              </label>
                                            </li>
                                          </Col>
                                          <Col sm="o">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              :
                                            </label>
                                          </Col>
                                          <Col sm="5">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              {detailPengajuan.user.nopeg}
                                            </label>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <li>
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                Kategori Karyawan
                                              </label>
                                            </li>
                                          </Col>
                                          <Col sm="o">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              :
                                            </label>
                                          </Col>
                                          <Col sm="5">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              {detailPengajuan.user.internal ===
                                              "1"
                                                ? "Internal"
                                                : "Non-Internal"}
                                            </label>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <li>
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                NIK
                                              </label>
                                            </li>
                                          </Col>
                                          <Col sm="o">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              :
                                            </label>
                                          </Col>
                                          <Col sm="5">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              {detailPengajuan.user.nik}
                                            </label>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <li>
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                Perusahaan
                                              </label>
                                            </li>
                                          </Col>
                                          <Col sm="o">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              :
                                            </label>
                                          </Col>
                                          <Col sm="5">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              {
                                                detailPengajuan.perusahaan
                                                  .namaPerusahaan
                                              }
                                            </label>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <li>
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                Client
                                              </label>
                                            </li>
                                          </Col>
                                          <Col sm="o">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              :
                                            </label>
                                          </Col>
                                          <Col sm="5">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              {
                                                detailPengajuan.client
                                                  .namaClient
                                              }
                                            </label>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <li>
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                Cabang
                                              </label>
                                            </li>
                                          </Col>
                                          <Col sm="o">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              :
                                            </label>
                                          </Col>
                                          <Col sm="5">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              {
                                                detailPengajuan.cabang
                                                  .namaCabang
                                              }
                                            </label>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <li>
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                  // fontWeight: "bold",
                                                }}
                                              >
                                                Nominal Permohonan
                                              </label>
                                            </li>
                                          </Col>
                                          <Col sm="o">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              :
                                            </label>
                                          </Col>
                                          <Col sm="5">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              {detailPengajuan.plafond !== 0
                                                ? `Rp ${Number(
                                                    detailPengajuan.plafond
                                                  ).toLocaleString("id-ID")}`
                                                : "Rp 0"}
                                            </label>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <li>
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                  // fontWeight: "bold",
                                                }}
                                              >
                                                Nominal Administrasi
                                              </label>
                                            </li>
                                          </Col>
                                          <Col sm="o">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              :
                                            </label>
                                          </Col>
                                          <Col sm="5">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              {detailPengajuan.nominalAdministrasi !==
                                              0
                                                ? `Rp ${Number(
                                                    detailPengajuan.nominalAdministrasi
                                                  ).toLocaleString("id-ID")}`
                                                : "Rp 0"}
                                            </label>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <li>
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                  // fontWeight: "bold",
                                                }}
                                              >
                                                Nominal Pencairan
                                              </label>
                                            </li>
                                          </Col>
                                          <Col sm="o">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              :
                                            </label>
                                          </Col>
                                          <Col sm="5">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              {isNaN(
                                                detailPengajuan.nominalPencairan
                                              )
                                                ? "-"
                                                : detailPengajuan.nominalPencairan !==
                                                  0
                                                ? `Rp ${Number(
                                                    detailPengajuan.nominalPencairan
                                                  ).toLocaleString("id-ID")}`
                                                : "Rp 0"}
                                            </label>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="6">
                                            <li>
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                  // fontWeight: "bold",
                                                }}
                                              >
                                                Nominal Diterima
                                              </label>
                                            </li>
                                          </Col>
                                          <Col sm="o">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              :
                                            </label>
                                          </Col>
                                          <Col sm="5">
                                            <label
                                              style={{
                                                textTransform: "none",
                                                fontSize: 16,
                                                color: "black",
                                              }}
                                            >
                                              {isNaN(
                                                detailPengajuan.nominalTerima
                                              )
                                                ? "-"
                                                : detailPengajuan.nominalTerima !==
                                                  0
                                                ? `Rp ${Number(
                                                    detailPengajuan.nominalTerima
                                                  ).toLocaleString("id-ID")}`
                                                : "Rp 0"}
                                            </label>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card.Body>
                                    {/* </Card> */}
                                  </Collapse>
                                </Card>
                                <Card>
                                  <Card.Header>
                                    <Card.Title as="h4">
                                      <a
                                        aria-expanded={
                                          collapsibleAccordion2 === 1
                                        }
                                        data-toggle="collapse"
                                        href="#pablo"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          collapsibleAccordion2 === 1
                                            ? setCollapsibleAccordion2(-1)
                                            : setCollapsibleAccordion2(1);
                                        }}
                                      >
                                        Detail Pengajuan Lama{" "}
                                        <b className="caret"></b>
                                      </a>
                                    </Card.Title>
                                  </Card.Header>
                                  <Collapse
                                    className="card-collapse"
                                    id="collapseTwoHover"
                                    in={collapsibleAccordion2 === 1}
                                  >
                                    <Card
                                      style={{
                                        maxHeight: "350px",
                                        overflowY: "scroll",
                                      }}
                                    >
                                      <Card.Body>
                                        <div className="col-12">
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                  }}
                                                >
                                                  Nama
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {nama}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                  }}
                                                >
                                                  Nopeg
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {detailPengajuan.user.nopeg}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                  }}
                                                >
                                                  Kategori Karyawan
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {detailPengajuan.user
                                                  .internal === "1"
                                                  ? "internal"
                                                  : "Non-Internal"}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                  }}
                                                >
                                                  NIK
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {detailPengajuan.user.nik}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                  }}
                                                >
                                                  Perusahaan
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {
                                                  detailPengajuan.perusahaan
                                                    .namaPerusahaan
                                                }
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                  }}
                                                >
                                                  Client
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {
                                                  detailPengajuan.client
                                                    .namaClient
                                                }
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                  }}
                                                >
                                                  Cabang
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {
                                                  detailPengajuan.cabang
                                                    .namaCabang
                                                }
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                  }}
                                                >
                                                  ID Pengajuan Lama
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {detailPengajuan.idPengajuanSebelumnya ===
                                                ""
                                                  ? "-"
                                                  : detailPengajuan.idPengajuanSebelumnya}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                    // fontWeight: "bold",
                                                  }}
                                                >
                                                  Nominal Permohonan
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {detailPengajuan
                                                  .pengajuanLama[0].plafond !==
                                                0
                                                  ? `Rp ${Number(
                                                      detailPengajuan
                                                        .pengajuanLama[0]
                                                        .plafond
                                                    ).toLocaleString("id-ID")}`
                                                  : "Rp 0"}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                    // fontWeight: "bold",
                                                  }}
                                                >
                                                  Nominal Administrasi
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {detailPengajuan
                                                  .pengajuanLama[0]
                                                  .nominalAdministrasi !== 0
                                                  ? `Rp ${Number(
                                                      detailPengajuan
                                                        .pengajuanLama[0]
                                                        .nominalAdministrasi
                                                    ).toLocaleString("id-ID")}`
                                                  : "Rp 0"}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                  }}
                                                >
                                                  Nominal Administrasi Per Bulan
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="4">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {detailPengajuan
                                                  .pengajuanLama[0]
                                                  .nominalAdministrasiPerBulan !==
                                                0
                                                  ? `Rp ${Number(
                                                      detailPengajuan
                                                        .pengajuanLama[0]
                                                        .nominalAdministrasiPerBulan
                                                    ).toLocaleString("id-ID")}`
                                                  : "Rp 0"}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                    // fontWeight: "bold",
                                                  }}
                                                >
                                                  Nominal Bayar Per Bulan
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {detailPengajuan
                                                  .pengajuanLama[0]
                                                  .nominalDibayarkanPerBulan !==
                                                0
                                                  ? `Rp ${Number(
                                                      detailPengajuan
                                                        .pengajuanLama[0]
                                                        .nominalDibayarkanPerBulan
                                                    ).toLocaleString("id-ID")}`
                                                  : "Rp 0"}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                    // fontWeight: "bold",
                                                  }}
                                                >
                                                  Nominal Sisa Bayar
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {detailPengajuan
                                                  .pengajuanLama[0]
                                                  .nominalSisaBayar !== 0
                                                  ? `Rp ${Number(
                                                      detailPengajuan
                                                        .pengajuanLama[0]
                                                        .nominalSisaBayar
                                                    ).toLocaleString("id-ID")}`
                                                  : "Rp 0"}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                    // fontWeight: "bold",
                                                  }}
                                                >
                                                  Nominal Pencairan
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {isNaN(
                                                  detailPengajuan
                                                    .pengajuanLama[0]
                                                    .nominalPencairan
                                                )
                                                  ? "-"
                                                  : detailPengajuan
                                                      .pengajuanLama[0]
                                                      .nominalPencairan !== 0
                                                  ? `Rp ${Number(
                                                      detailPengajuan
                                                        .pengajuanLama[0]
                                                        .nominalPencairan
                                                    ).toLocaleString("id-ID")}`
                                                  : "Rp 0"}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                    // fontWeight: "bold",
                                                  }}
                                                >
                                                  Nominal Diterima
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {isNaN(
                                                  detailPengajuan
                                                    .pengajuanLama[0]
                                                    .nominalTerima
                                                )
                                                  ? "-"
                                                  : detailPengajuan
                                                      .pengajuanLama[0]
                                                      .nominalTerima !== 0
                                                  ? `Rp ${Number(
                                                      detailPengajuan
                                                        .pengajuanLama[0]
                                                        .nominalTerima
                                                    ).toLocaleString("id-ID")}`
                                                  : "Rp 0"}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                    // fontWeight: "bold",
                                                  }}
                                                >
                                                  Nominal Blokir Pengajuan
                                                  Sebelumnya
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {isNaN(
                                                  detailPengajuan
                                                    .pengajuanLama[0]
                                                    .nominalBlokir
                                                )
                                                  ? "-"
                                                  : detailPengajuan
                                                      .pengajuanLama[0]
                                                      .nominalBlokir !== 0
                                                  ? `Rp ${Number(
                                                      detailPengajuan
                                                        .pengajuanLama[0]
                                                        .nominalBlokir
                                                    ).toLocaleString("id-ID")}`
                                                  : "Rp 0"}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                    // fontWeight: "bold",
                                                  }}
                                                >
                                                  Nominal Sisa Bayar Pokok
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {isNaN(
                                                  detailPengajuan
                                                    .pengajuanLama[0]
                                                    .nominalSisaBayarPokok
                                                )
                                                  ? "-"
                                                  : detailPengajuan
                                                      .pengajuanLama[0]
                                                      .nominalSisaBayarPokok !==
                                                    0
                                                  ? `Rp ${Number(
                                                      detailPengajuan
                                                        .pengajuanLama[0]
                                                        .nominalSisaBayarPokok
                                                    ).toLocaleString("id-ID")}`
                                                  : "Rp 0"}
                                              </label>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="6">
                                              <li>
                                                <label
                                                  style={{
                                                    textTransform: "none",
                                                    fontSize: 16,
                                                    color: "black",
                                                    // fontWeight: "bold",
                                                  }}
                                                >
                                                  Nominal Sisa Bayar Admin
                                                </label>
                                              </li>
                                            </Col>
                                            <Col sm="o">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                :
                                              </label>
                                            </Col>
                                            <Col sm="5">
                                              <label
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: 16,
                                                  color: "black",
                                                }}
                                              >
                                                {isNaN(
                                                  detailPengajuan
                                                    .pengajuanLama[0]
                                                    .nominalSisaBayarAdmin
                                                )
                                                  ? "-"
                                                  : detailPengajuan
                                                      .pengajuanLama[0]
                                                      .nominalSisaBayarAdmin !==
                                                    0
                                                  ? `Rp ${Number(
                                                      detailPengajuan
                                                        .pengajuanLama[0]
                                                        .nominalSisaBayarAdmin
                                                    ).toLocaleString("id-ID")}`
                                                  : "Rp 0"}
                                              </label>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Collapse>
                                </Card>
                              </div>
                            </Card.Body>
                          </Card>
                        ) : null}
                        {typePengajuan === "new" ? (
                          <Card style={{ border: 0 }}>
                            {/* <Card.Body> */}
                            <Card.Title
                              style={{ fontWeight: "bold", marginBottom: 8 }}
                            >
                              Detail Pengajuan
                            </Card.Title>
                            <div className="col-12">
                              <Row>
                                <Col sm="4">
                                  <li>
                                    <label
                                      style={{
                                        textTransform: "none",
                                        fontSize: 16,
                                        color: "black",
                                      }}
                                    >
                                      Nama
                                    </label>
                                  </li>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="6">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    {nama}
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="4">
                                  <li>
                                    <label
                                      style={{
                                        textTransform: "none",
                                        fontSize: 16,
                                        color: "black",
                                      }}
                                    >
                                      Nopeg
                                    </label>
                                  </li>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="4">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    {detailPengajuan.user.nopeg}
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="4">
                                  <li>
                                    <label
                                      style={{
                                        textTransform: "none",
                                        fontSize: 16,
                                        color: "black",
                                      }}
                                    >
                                      Kategori Karyawan
                                    </label>
                                  </li>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="4">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    {detailPengajuan.user.internal === "1"
                                      ? "Internal"
                                      : "Non-Internal"}
                                  </label>
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="4">
                                  <li>
                                    <label
                                      style={{
                                        textTransform: "none",
                                        fontSize: 16,
                                        color: "black",
                                      }}
                                    >
                                      Perusahaan
                                    </label>
                                  </li>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="6">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    {detailPengajuan.perusahaan.namaPerusahaan}
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="4">
                                  <li>
                                    <label
                                      style={{
                                        textTransform: "none",
                                        fontSize: 16,
                                        color: "black",
                                      }}
                                    >
                                      Client
                                    </label>
                                  </li>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="6">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    {detailPengajuan.client.namaClient}
                                  </label>
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="4">
                                  <li>
                                    <label
                                      style={{
                                        textTransform: "none",
                                        fontSize: 16,
                                        color: "black",
                                      }}
                                    >
                                      Cabang
                                    </label>
                                  </li>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="6">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    {detailPengajuan.cabang.namaCabang}
                                  </label>
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="4">
                                  <li>
                                    <label
                                      style={{
                                        textTransform: "none",
                                        fontSize: 16,
                                        color: "black",
                                        // fontWeight: "bold",
                                      }}
                                    >
                                      Nominal Permohonan
                                    </label>
                                  </li>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="6">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    {detailPengajuan.plafond !== 0
                                      ? `Rp ${Number(
                                          detailPengajuan.plafond
                                        ).toLocaleString("id-ID")}`
                                      : "Rp 0"}
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="4">
                                  <li>
                                    <label
                                      style={{
                                        textTransform: "none",
                                        fontSize: 16,
                                        color: "black",
                                        // fontWeight: "bold",
                                      }}
                                    >
                                      Nominal Administrasi
                                    </label>
                                  </li>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="6">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    {detailPengajuan.nominalAdministrasi !== 0
                                      ? `Rp ${Number(
                                          detailPengajuan.nominalAdministrasi
                                        ).toLocaleString("id-ID")}`
                                      : "Rp 0"}
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="4">
                                  <li>
                                    <label
                                      style={{
                                        textTransform: "none",
                                        fontSize: 16,
                                        color: "black",
                                        // fontWeight: "bold",
                                      }}
                                    >
                                      Nominal Pencairan
                                    </label>
                                  </li>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="6">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    {detailPengajuan.nominalPencairan
                                      ? formattedValue(
                                          detailPengajuan.nominalPencairan
                                        )
                                      : "Rp 0"}
                                    {/* {isNaN(detailPengajuan.nominalPencairan)
                                      ? "-"
                                      : detailPengajuan.nominalPencairan !==
                                        0
                                      ? `Rp ${Number(
                                          detailPengajuan.nominalPencairan
                                        ).toLocaleString("id-ID")}`
                                      : "Rp 0"} */}
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="4">
                                  <li>
                                    <label
                                      style={{
                                        textTransform: "none",
                                        fontSize: 16,
                                        color: "black",
                                        // fontWeight: "bold",
                                      }}
                                    >
                                      Nominal Diterima
                                    </label>
                                  </li>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="6">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                  >
                                    {isNaN(detailPengajuan.nominalTerima)
                                      ? "-"
                                      : detailPengajuan.nominalTerima !== 0
                                      ? `Rp ${Number(
                                          detailPengajuan.nominalTerima
                                        ).toLocaleString("id-ID")}`
                                      : "Rp 0"}
                                  </label>
                                </Col>
                              </Row>
                            </div>
                            {/* </Card.Body> */}
                          </Card>
                        ) : null}
                        <Row>
                          <Col
                            sm={statusPengajuan.value === "Diproses" ? 6 : 12}
                          >
                            <Form.Group>
                              <label
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  color: "grey",
                                  textTransform: "none",
                                }}
                              >
                                Status Pengajuan
                              </label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="singleStatus"
                                // onChange={(value) => setStatusPengajuan(value)}
                                onChange={(value) => {
                                  // console.log(
                                  //   "Status Pengajuan dipilih:",
                                  //   value
                                  // );
                                  setStatusPengajuan(value);
                                }}
                                options={filteredOptions(
                                  userRole,
                                  detailPengajuan.cancel
                                )}
                                placeholder="Pilih Status"
                              />
                            </Form.Group>
                          </Col>
                          {statusPengajuan.value === "Ditolak" ||
                          statusPengajuan.value === "Revisi" ? (
                            <Col sm="12">
                              <Form.Group>
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    color: "grey",
                                    textTransform: "none",
                                    fontSize: 14,
                                  }}
                                >
                                  Keterangan
                                </label>
                                <textarea
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                  rows={4}
                                  className="form-control"
                                />
                              </Form.Group>
                            </Col>
                          ) : null}
                          {statusPengajuan.value === "Diproses" ? (
                            <Col sm="6">
                              <Form.Group>
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    color: "grey",
                                    textTransform: "none",
                                  }}
                                >
                                  Nominal Permohonan
                                </label>
                                <Row>
                                  <Col sm="12">
                                    <Form.Control
                                      min={1}
                                      value={plafond.toLocaleString("id-ID", {
                                        style: "currency",
                                        currency: "IDR",
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      })}
                                      disabled={true}
                                      // onChange={(e) => {
                                      //   setTenor(e.target.value);
                                      // }}
                                      type="text"
                                    ></Form.Control>
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          ) : null}
                          {/* {statusPengajuan.value === "Disetujui" ? (
                            <Col sm="6">
                              <Form.Group>
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    color: "grey",
                                    textTransform: "none",
                                  }}
                                >
                                  Status Karyawan
                                </label>
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  name="singleStatus"
                                  onChange={(value) => setStatusKaryawan(value)}
                                  options={optionStatusKaryawan}
                                  placeholder="Pilih Status"
                                />
                              </Form.Group>
                            </Col>
                          ) : null} */}
                          {statusPengajuan.value === "Diproses" ? (
                            <Col sm="6">
                              <Form.Group>
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    color: "grey",
                                    textTransform: "none",
                                  }}
                                >
                                  Tanggal Pencairan
                                </label>
                                <Form.Control
                                  onChange={(e) => {
                                    setTglPencairan(e.target.value);
                                  }}
                                  type="date"
                                  min={new Date().toISOString().split("T")[0]}
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                          ) : null}

                          {statusPengajuan.value === "Diproses" ? (
                            <Col sm="6">
                              <Form.Group>
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    color: "grey",
                                    textTransform: "none",
                                  }}
                                >
                                  Tenor
                                </label>
                                <Row>
                                  <Col>
                                    <Form.Control
                                      min={1}
                                      value={tenor}
                                      disabled={true}
                                      onChange={(e) => {
                                        setTenor(e.target.value);
                                      }}
                                      type="number"
                                    ></Form.Control>
                                  </Col>
                                  <Col sm="8">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon2"
                                    >
                                      Bulan
                                    </span>
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          ) : null}
                          {statusPengajuan.value === "Diproses" ? (
                            <Col sm="6">
                              <Form.Group>
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    color: "grey",
                                    textTransform: "none",
                                  }}
                                >
                                  Nominal Pencairan
                                </label>
                                <p>
                                  <CurrencyInput
                                    style={{
                                      padding: 5,
                                      borderRadius: 4,
                                      width: "100%",
                                      height: "40px",
                                      borderWidth: "1px",
                                      borderTopColor:
                                        "rgba(227, 227, 227, 0.3)",
                                      borderLeftColor:
                                        "rgba(227, 227, 227, 0.3)",
                                      borderBottomColor:
                                        "rgba(227, 227, 227, 1)",
                                      borderRightColor:
                                        "rgba(227, 227, 227, 1)",
                                      cursor: "not-allowed",
                                    }}
                                    name="nominal"
                                    placeholder="Masukkan nominal pencairan"
                                    prefix="Rp "
                                    value={nominalPencairan}
                                    disabled
                                    groupSeparator="."
                                    decimalSeparator=","
                                    onValueChange={(value) => {
                                      setNominalPencairan(value);
                                    }}
                                  />
                                </p>
                              </Form.Group>
                            </Col>
                          ) : null}

                          {/* {statusPengajuan.value === "Diproses" &&
                          typePengajuan === "topup" ? (
                            <>
                              <Col sm="6">
                                <Form.Group>
                                  <label
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 14,
                                      color: "grey",
                                      textTransform: "none",
                                    }}
                                  >
                                    Nominal Pembayaran
                                  </label>
                                  <p>
                                    <CurrencyInput
                                      style={{
                                        padding: 5,
                                        borderRadius: 4,
                                        width: "100%",
                                        height: "40px",
                                        borderWidth: "1px",
                                        borderTopColor:
                                          "rgba(227, 227, 227, 0.3)",
                                        borderLeftColor:
                                          "rgba(227, 227, 227, 0.3)",
                                        borderBottomColor:
                                          "rgba(227, 227, 227, 1)",
                                        borderRightColor:
                                          "rgba(227, 227, 227, 1)",
                                        cursor: "not-allowed",
                                      }}
                                      name="nominal"
                                      placeholder="Masukkan Nominal Pembayaran"
                                      prefix="Rp "
                                      disabled={true}
                                      value={nominalBayar}
                                      groupSeparator="."
                                      decimalSeparator=","
                                      onChange={(e) => {
                                        setNominalBayar(e.target.value);
                                      }}
                                    />
                                  </p>
                                </Form.Group>
                              </Col>
                              <Col sm="6">
                                <Form.Group>
                                  <label
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 14,
                                      color: "grey",
                                      textTransform: "none",
                                    }}
                                  >
                                    Nominal Bayar Administrasi
                                  </label>
                                  <p>
                                    <CurrencyInput
                                      style={{
                                        padding: 5,
                                        borderRadius: 4,
                                        width: "100%",
                                        height: "40px",
                                        borderWidth: "1px",
                                        borderTopColor:
                                          "rgba(227, 227, 227, 0.3)",
                                        borderLeftColor:
                                          "rgba(227, 227, 227, 0.3)",
                                        borderBottomColor:
                                          "rgba(227, 227, 227, 1)",
                                        borderRightColor:
                                          "rgba(227, 227, 227, 1)",
                                        cursor: "not-allowed",
                                      }}
                                      name="nominal"
                                      placeholder="Masukkan Nominal Bayar Administrasi"
                                      prefix="Rp "
                                      disabled={true}
                                      value={nominalAdmin}
                                      groupSeparator="."
                                      decimalSeparator=","
                                      onChange={(e) => {
                                        setNominalAdmin(e.target.value);
                                      }}
                                    />
                                  </p>
                                </Form.Group>
                              </Col>
                            </>
                          ) : null} */}
                        </Row>

                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          onClick={submitUpdateStatus}
                        >
                          Submit
                        </Button>
                        <div className="clearfix"></div>
                      </Card.Body>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          <DetailAngsuran
            data={detailAngsuran}
            dataFcmToken={kirimFcm}
            statusPembayaran={statusPembayaran}
            pencairan={pencairan}
          />
        </>
      )}
    </>
  );
}

export default Pengajuan;
