import React from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { getNewUser } from "stores";
import {
  Button,
  Card,
  Container,
  Row,
  InputGroup,
  Col,
  Modal,
  Form,
  Tab,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { getFormRegister } from "stores";
import { updateStatusKoperasi } from "stores";
import { ubahRole } from "stores";
import { exportUser } from "stores";
import { Dna } from "react-loader-spinner";
import { getAllUser } from "stores";

function UserNew() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const modalDetailUser = useSelector((state) => state.userReducer);
  const userRole = auth.role;
  const user = useSelector((state) => state.userReducer);
  const [modalDetail, setModalDetail] = React.useState(false);
  const [modalExport, setModalExport] = React.useState(false);
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());
  const [modalUpdateStatus, setModalUpdateStatus] = React.useState(false);
  const [modalValidasi, setModalValidasi] = React.useState(false);
  const [modalUbahRole, setModalUbahRole] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [filterStatus, setFilterStatus] = React.useState(null);
  const [detailUser, setDetailUser] = React.useState([]);
  const [previewPDF, setPreviewPDF] = React.useState(false);
  const [idUpdateStatus, setIdUpdateStatus] = React.useState("");
  const [idUbahRole, setIdUbahRole] = React.useState("");
  const [listUser, setListUser] = React.useState([]);
  const [listStatus, setListStatus] = React.useState([]);
  const [statusKoperasi, setStatusKoperasi] = React.useState("");
  const [role, setRole] = React.useState("");
  const [keterangan, setKeterangan] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingExport, setLoadingExport] = React.useState(true);
  const [statusValidasi, setStatusValidasi] = React.useState("");
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [pilihOpsi, setPilihOpsi] = React.useState("");
  const [newOption, setNewOption] = React.useState([]);
  const [newOptionRole, setNewOptionRole] = React.useState([]);
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const handleFilterChange = () => {
    setIsLoading(true);
    setPage(0);
    getNewUser(dispatch, {
      page: 1,
      limit: totalPerHalaman,
      cari: searchText,
    })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const submitExport = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Konfirmasi Export User",
      text: `Apakah Anda ingin mengexport ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, Export",
      cancelButtonText: "Batal",
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          Swal.fire({
            title: "Sedang Mengexport, Ditunggu yaa..",
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });

          const response = await exportUser({
            tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
            tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
          });

          // Mengaktifkan unduhan setelah menerima respons dari server
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Export User.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Tampilkan swal.fire setelah unduhan selesai
          Swal.fire({
            icon: "success",
            title: "Data berhasil diexport",
          });
        } catch (error) {
          // Menampilkan pesan kesalahan jika terjadi kesalahan saat ekspor
          Swal.fire({
            title: "Terjadi kesalahan",
            text: error.message,
            icon: "error",
          });
        }
      },
    });
  };

  function submitUpdateStatus(e) {
    e.preventDefault();
    if (statusKoperasi === "") {
      Swal.fire({
        title: "Status Harus Diisi",
        icon: "warning",
      });
      return;
    }
    updateStatusKoperasi({
      idUser: idUpdateStatus,
      statusKoperasi: statusKoperasi.value,
    }).then((response) => {
      if (response.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 1500,
        });
        setModalUpdateStatus(false);
        getAllUser(dispatch, {
          statusKoperasi:
            filterStatus === null || filterStatus.value === ""
              ? ""
              : filterStatus.value,
          cari: "",
          page: page + 1,
          limit: totalPerHalaman,
        });
        getNewUser(dispatch, {
          cari: "",
          page: page + 1,
          limit: totalPerHalaman,
        });
      } else if (response.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.message,
        });
      }
    });
  }

  function submitValidasi(e) {
    e.preventDefault();

    Swal.fire({
      title: "Konfirmasi",
      text: "Apakah Anda yakin ingin menyimpan perubahan?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if (statusValidasi.value === "nonactive" && keterangan === "") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Keterangan tidak boleh kosong",
          });
          return;
        }
        Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        updateStatusKoperasi({
          idUser: idUpdateStatus,
          statusKoperasi: statusValidasi.value,
          kategoriKaryawan: "2",
          keterangan: statusValidasi.value === "nonactive" ? keterangan : "",
        }).then((response) => {
          if (response.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.message,
              showConfirmButton: false,
              timer: 1500,
            });
            setModalValidasi(false);
            getAllUser(dispatch, {
              statusKoperasi:
                filterStatus === null || filterStatus.value === ""
                  ? ""
                  : filterStatus.value,
              cari: "",
              page: page + 1,
              limit: totalPerHalaman,
            });
            getNewUser(dispatch, {
              page: 1,
              limit: totalPerHalaman,
              cari: searchText,
            });
          } else if (response.status === 400) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.message,
            });
          }
        });
      }
    });
  }

  function submitUbahRole(e) {
    e.preventDefault();
    if (role === "") {
      Swal.fire({
        title: "Role Harus Diisi",
        icon: "warning",
      });
      return;
    }
    ubahRole({
      idUser: idUbahRole,
      role: role.value,
    }).then((response) => {
      if (response.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 1500,
        });
        setModalUbahRole(false);
        getNewUser(dispatch, {
          cari: "",
          page: page + 1,
          limit: totalPerHalaman,
        });
      } else if (response.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.message,
        });
      }
    });
  }

  const bukaPDF = async (userId) => {
    try {
      const data = { idUser: userId };
      const response = await getFormRegister(data);
      if (response && response.status === 200 && response.url) {
        window.open(response.url, "_blank");
      } else {
        console.error("invalid pdf :", response);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterChange();
    }
  };

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const handleDropdownItemDetail = (val) => {
    setDetailUser(val);
    dispatch({ type: "SET_MODAL_USER", data: false });
    dispatch({ type: "SET_DETAIL_USER", data: val });
  };
  const handleDropdownItemUpdate = () => {
    setModalUpdateStatus(true);
  };
  const handleDropdownItemValidasi = () => {
    setModalValidasi(true);
  };

  const handleDropdownItemUbahRole = () => {
    setModalUbahRole(true);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleCloseModalUpdatStatus = () => {
    setStatusKoperasi("");
  };

  const handleCloseModalValidasi = () => {
    setStatusValidasi("");
    setStatusKoperasi("");
    setKeterangan("");
  };

  const handleCloseModalUbahRole = () => {
    setRole("");
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleExportPengajuan = () => {
    setTglAwal(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
        0,
        0,
        0,
        0
      )
    );
    setTglAkhir(new Date());
  };

  const formattedValue = (e) => {
    return e.toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
  };

  React.useEffect(() => {
    let tmp =
      user.getNewUser &&
      user.getNewUser.docs &&
      user.getNewUser.docs.map((val, index) => {
        let actions;
        if (userRole === "super admin" || userRole === "admin") {
          actions = (
            <>
              <div>
                <Dropdown drop="right">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{
                      backgroundColor: "#50A6C1",
                      border: 0,
                      fontWeight: "bold",
                    }}
                  >
                    Aksi
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ zIndex: 1000, position: "relative" }}>
                    <Dropdown.Item
                      onClick={() => {
                        setDetailUser(val);
                        handleDropdownItemDetail(val);
                      }}
                    >
                      Detail
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => {
                        let newTmpOption = [];
                        let statusKoperasi = val.statusKoperasi;
                        let tmpOption = [
                          {
                            value: "",
                            label: "Pilih Status",
                            isDisabled: true,
                          },
                          {
                            value: "nonactive",
                            label: "Tidak Aktif",
                          },
                          {
                            value: "active",
                            label: "Aktif",
                          },

                          {
                            value: "ex",
                            label: "Keluar",
                          },
                        ];
                        if (statusKoperasi === "active") {
                          newTmpOption.push(
                            tmpOption.find((option) => option.value === "ex")
                          );
                        }
                        setNewOption(newTmpOption);
                        handleDropdownItemUpdate();
                        setIdUpdateStatus(val.idUser);
                      }}
                      style={{
                        display:
                          val.statusKoperasi === "active" ? "block" : "none",
                      }}
                    >
                      Update Status
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setDetailUser(val);
                        handleDropdownItemValidasi();
                        setIdUpdateStatus(val.idUser);
                      }}
                      style={{
                        display:
                          val.statusKoperasi === "waiting" ? "block" : "none",
                      }}
                    >
                      Validasi
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        let newTmpOptionRole = [];
                        let role = val.role;
                        let tmpOptionRole = [
                          {
                            value: "",
                            label: "Pilih Role",
                            isDisabled: true,
                          },
                          {
                            value: "super admin",
                            label: "Super Admin",
                          },
                          {
                            value: "admin",
                            label: "Admin",
                          },
                          {
                            value: "user",
                            label: "User",
                          },
                          {
                            value: "hrd",
                            label: "HRD",
                          },
                        ];

                        let filteredRoles = tmpOptionRole.filter(
                          (option) => option.value !== role
                        );
                        newTmpOptionRole.push(...filteredRoles);
                        setNewOptionRole(newTmpOptionRole);
                        handleDropdownItemUbahRole();
                        setIdUbahRole(val.idUser);
                      }}
                      style={{
                        display:
                          val.statusKoperasi === "active" ? "block" : "none",
                      }}
                    >
                      Ubah Role
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="actions-right">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Download Pdf</Tooltip>}
                >
                  <Button
                    className="ml-2"
                    style={{
                      marginTop: 5,
                      padding: "6px 15px",
                      fontSize: "18px",
                      marginBottom: 10,
                      backgroundColor:
                        val.statusKoperasi === "active" ? "#F75D59" : "grey",
                      border: 0,
                      cursor:
                        val.statusKoperasi === "active"
                          ? "pointer"
                          : "not-allowed",
                    }}
                    disabled={val.statusKoperasi === "nonactive" ? true : false}
                    onClick={() => bukaPDF(val.idUser)}
                  >
                    <i class="fas fa-file-download"></i>
                  </Button>
                </OverlayTrigger>
              </div>
              {/* <div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Ubah Kategori Karyawan</Tooltip>}
                  >
                    <Button
                      className="ml-2"
                      style={{
                        marginTop: 5,
                        padding: "6px 15px",
                        fontSize: "18px",
                        marginBottom: 10,
                        backgroundColor:
                          val.statusKoperasi === "active" ? "#BAA6E1" : "grey",
                        border: 0,
                        cursor:
                          val.statusKoperasi === "active"
                            ? "pointer"
                            : "not-allowed",
                      }}
                      disabled={val.statusKoperasi === "nonactive" ? true : false}
                      onClick={() => {
                        setUbahKategoriKaryawan(val.idUser);
                        setDetailUser(val);
                        setModalUpdateKategori(!modalUpdateKategori);
                      }}
                    >
                      <i class="fas fa-user-edit"></i>
                    </Button>
                  </OverlayTrigger>
                </div> */}
            </>
          );
        } else if (userRole === "hrd") {
          // Jika user adalah hrd, tampilkan hanya tombol Detail
          actions = (
            <div className="actions-right">
              <Dropdown drop="right">
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  Aksi
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setDetailUser(val);
                      handleDropdownItemDetail(val);
                    }}
                  >
                    Detail
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        }

        return {
          ...val,
          actions: actions,
        };
      });

    setListUser(tmp);
  }, [user.getNewUser, userRole]);

  const getStatusKoperasiStyle = (status) => {
    switch (status) {
      case "nonactive":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE6E6",
          textColor: "#FF0000",
        };
      case "waiting":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFEEE6",
          textColor: "#FF4F00",
        };
      case "active":
        return {
          fontWeight: "bold",
          backgroundColor: "#E1FAEE",
          textColor: "#01D26D",
        };
      case "ex":
        return {
          fontWeight: "bold",
          backgroundColor: "#EA4335",
          textColor: "#FFFFFF",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colUser = [
    {
      name: "No",
      width: "100px",
      center: true,
      // selector: (row) => listUser.indexOf(row) + 1,
      selector: (row, index) => index + user.getNewUser.pagingCounter,
    },
    {
      name: "Nama",
      minWidth: "280px",
      center: true,
      sortable: true,
      selector: (row) => row.nama,
    },
    {
      name: "Nomor Pegawai",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nopeg,
    },
    {
      name: "Client",
      minWidth: "250px",
      center: true,
      selector: (row) => row.client.namaClient,
    },
    {
      name: "Cabang",
      minWidth: "200px",
      center: true,
      selector: (row) => row.cabang.namaCabang,
    },
    {
      name: "Nominal Simpanan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const nominalSimpanan = isNaN(row.nominalSimpanan)
          ? 0
          : row.nominalSimpanan;

        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(nominalSimpanan);

        return formattedValue;
      },
    },
    {
      name: "Jabatan",
      minWidth: "160px",
      center: true,
      selector: (row) => (row.jabatan ? row.jabatan : "-"),
    },
    // {
    //   name: "Gaji Pokok",
    //   minWidth: "160px",
    //   center: true,
    //   selector: (row) => {
    //     const formattedValue = new Intl.NumberFormat("id-ID", {
    //       style: "currency",
    //       currency: "IDR",
    //       minimumFractionDigits: 0,
    //     }).format(row.gajiPokok);
    //     return formattedValue;
    //   },
    // },
    {
      name: "Role",
      minWidth: "160px",
      center: true,
      selector: (row) =>
        row.role === "super admin"
          ? "Super Admin"
          : row.role === "admin"
          ? "Admin"
          : row.role === "hrd"
          ? "HRD"
          : row.role === "user"
          ? "User"
          : row.role,
    },
    {
      name: "Status Karyawan",
      minWidth: "180px",
      center: true,
      selector: (row) => row.statusKaryawan,
    },
    {
      name: "Status Koperasi",
      minWidth: "200px",
      center: true,
      selector: (row) =>
        row.statusKoperasi === "nonactive"
          ? "Tidak Aktif"
          : row.statusKoperasi === "waiting"
          ? "Menunggu"
          : row.statusKoperasi === "active"
          ? "Aktif"
          : row.statusKoperasi === "ex"
          ? "Keluar"
          : row.statusKoperasi,
      cell: (row) => {
        const statusKoperasi =
          row.statusKoperasi === "nonactive"
            ? "Tidak Aktif"
            : row.statusKoperasi === "waiting"
            ? "Menunggu"
            : row.statusKoperasi === "active"
            ? "Aktif"
            : row.statusKoperasi === "ex"
            ? "Keluar"
            : row.statusKoperasi;
        const statusStyle = getStatusKoperasiStyle(row.statusKoperasi);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {statusKoperasi}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      cell: (row) => row.actions,
    },
  ];

  React.useEffect(() => {
    setIsLoading(true);
    getNewUser(dispatch, {
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [modalDetailUser.modalDetailUser, page, totalPerHalaman]);

  React.useEffect(() => {
    exportUser(dispatch);
  }, []);

  return (
    <>
      <Modal
        size="lg"
        show={modalExport}
        onHide={() => {
          setModalExport(false);
          handleExportPengajuan();
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card style={{ border: 0 }}>
                  <Card.Title
                    as="h4"
                    style={{ fontWeight: "bold", textTransform: "none" }}
                  >
                    Export Pengajuan
                    <hr></hr>
                  </Card.Title>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Tanggal Awal
                          </label>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(0, 0, 0, 0),
                                setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={tglAwal}
                            initialValue={tglAwal}
                            timeFormat={false}
                          ></ReactDatetime>
                          {/* <Form.Control
                            onChange={(e) => {
                              setTglAwal(e.target.value);
                            }}
                            type="date"
                          /> */}
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Tanggal Akhir
                          </label>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(23, 59, 0, 0),
                                setTglAkhir(e.toDate().setHours(23, 59, 0, 0));
                            }}
                            value={tglAkhir}
                            initialValue={tglAkhir}
                            timeFormat={false}
                          ></ReactDatetime>
                          {/* <Form.Control
                            onChange={(e) => {
                              setTglAkhir(e.target.value);
                            }}
                            type="date"
                          /> */}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      // variant="info"
                      style={{
                        border: 0,
                        backgroundColor: "#77CA2A",
                        fontWeight: "bold",
                        fontSize: 15,
                      }}
                      onClick={submitExport}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalValidasi}
        onHide={() => {
          handleCloseModalValidasi();
          setModalValidasi(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h4"
                style={{
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Validasi Anggota Koperasi
                <div style={{ color: "blue" }}>
                  {detailUser.simpanan && detailUser.simpanan.length === 0
                    ? "Anggota Baru"
                    : "Anggota Lama"}
                </div>
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Nama
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailUser.nama}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      NIK
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailUser.nik}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Nomor Pegawai
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailUser.nopeg}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Perusahaan
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailUser &&
                      detailUser.perusahaan &&
                      detailUser.perusahaan.namaPerusahaan
                        ? detailUser.perusahaan.namaPerusahaan
                        : "-"}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Client
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailUser &&
                      detailUser.client &&
                      detailUser.client.namaClient
                        ? detailUser.client.namaClient
                        : "-"}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Penempatan Cabang
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailUser &&
                      detailUser.cabang &&
                      detailUser.cabang.namaCabang
                        ? detailUser.cabang.namaCabang
                        : "-"}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Status Karyawan
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailUser.statusKaryawan}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Jabatan
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailUser.jabatan}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Nomor Telepon
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailUser.noTelp}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Nominal Simpanan
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailUser.nominalSimpanan !== 0
                        ? `Rp ${Number(
                            detailUser.nominalSimpanan
                          ).toLocaleString("id-ID")}`
                        : "Rp 0"}
                    </label>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12">
                    <Form.Group>
                      <label
                        style={{
                          fontWeight: "bold",
                          color: "#212121",
                          textTransform: "none",
                          fontSize: 14,
                        }}
                      >
                        Pilih Validasi
                      </label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleStatus"
                        onChange={(value) => setStatusValidasi(value)}
                        options={[
                          {
                            value: "",
                            label: "Pilih Validasi",
                            isDisabled: true,
                          },
                          {
                            value: "active",
                            label: "Disetujui",
                          },

                          {
                            value: "nonactive",
                            label: "Ditolak",
                          },
                        ]}
                        placeholder="Pilih Validasi"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  {statusValidasi.value === "nonactive" ? (
                    <Col sm="12">
                      <Form.Group>
                        <label
                          style={{
                            fontWeight: "bold",
                            color: "#212121",
                            textTransform: "none",
                            fontSize: 14,
                          }}
                        >
                          Keterangan
                        </label>
                        <textarea
                          onChange={(e) => {
                            setKeterangan(e.target.value);
                          }}
                          rows={4}
                          className="form-control"
                        />
                      </Form.Group>
                    </Col>
                  ) : null}
                  {/* {statusValidasi.value === "active" ? (
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "capitalize",
                            }}
                          >
                            pilih kategori karyawan
                          </label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleStatus"
                            onChange={(value) => setStatusKaryawan(value)}
                            options={[
                              {
                                value: "",
                                label: "Pilih Validasi",
                                isDisabled: true,
                              },
                              {
                                value: "1",
                                label: "Internal",
                              },

                              {
                                value: "2",
                                label: "Non-Internal",
                              },
                            ]}
                            placeholder="Pilih Kategori Karyawan"
                          />
                        </Form.Group>
                      </Col>
                    ) : null} */}
                </Row>
                {statusValidasi.value === "nonactive" ||
                statusValidasi.value === "active" ? (
                  <Col>
                    <Button
                      variant="info"
                      style={{
                        margin: 0,
                      }}
                      onClick={submitValidasi}
                    >
                      Submit
                    </Button>
                  </Col>
                ) : null}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalUbahRole}
        onHide={() => {
          handleCloseModalUbahRole();
          setModalUbahRole(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                        Ubah Role
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Role</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleStatus"
                            onChange={(value) => setRole(value)}
                            // options={[{
                            //     value: "",
                            //     label: "Pilih Role",
                            //     isDisabled: true,
                            //   },
                            //   {
                            //     value: "super admin",
                            //     label: "Super Admin",
                            //   },
                            //   {
                            //     value: "admin",
                            //     label: "Admin",
                            //   },
                            //   {
                            //     value: "user",
                            //     label: "User",
                            //   },
                            //   {
                            //     value: "hrd",
                            //     label: "HRD",
                            //   },]}
                            options={newOptionRole}
                            placeholder="Pilih Role"
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col sm="12">
                      <Form.Group>
                        <label>Keterangan</label>
                        <textarea
                          onChange={(e) => {
                            setKeterangan(e.target.value);
                          }}
                          rows={4}
                          className="form-control"
                        />
                      </Form.Group>
                    </Col> */}
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitExport}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalUpdateStatus}
        onHide={() => {
          handleCloseModalUpdatStatus();
          setModalUpdateStatus(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                        Update Status Koperasi
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Status</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleStatus"
                            onChange={(value) => setStatusKoperasi(value)}
                            options={newOption}
                            placeholder="Pilih Status"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitUpdateStatus}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Container fluid>
        <Tab.Content>
          <Card style={{ border: 0, marginTop: 20 }}>
            <Row
              style={{
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Col md={"4"}>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown}
                    placeholder="Nama/Nopeg"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
              <Col>
                <Button
                  // variant="info"
                  style={{
                    fontSize: 16,
                    backgroundColor: "#00BFFF",
                    border: 0,
                    paddingTop: 10,
                    paddingBottom: 7,
                  }}
                  onClick={handleFilterChange}
                >
                  <i class="fas fa-search"></i>
                </Button>
              </Col>
            </Row>
            <div style={{ marginTop: 15 }}>
              <Button
                className="btn-wd "
                onClick={() => {
                  setModalExport(true);
                }}
                // variant="success"
                style={{
                  backgroundColor: "#6BD98A",
                  border: 0,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                <i
                  className="fas fa-file-excel fa-lg"
                  style={{ marginRight: 10 }}
                ></i>
                Export User Baru
              </Button>
            </div>
          </Card>
        </Tab.Content>

        <div style={{ zIndex: -100 }}>
          <DataTable
            columns={colUser}
            data={listUser}
            customStyles={customStyles}
            paginationPerPage={totalPerHalaman}
            progressPending={isLoading}
          />
          {/* </Col> */}
          <div style={{ marginTop: 35 }}>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Col>
                <Row style={{ alignItems: "center" }}>
                  <div
                    style={{
                      paddingRight: 0,
                      marginLeft: 20,
                      marginRight: 10,
                    }}
                  >
                    <label style={{ fontWeight: "bold" }}>Rows per page</label>
                  </div>
                  <Col sm="3" style={{ paddingLeft: 0 }}>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="Pilih Client"
                      value={options.find(
                        (option) => option.value === totalPerHalaman
                      )}
                      onChange={handlePageSizeChange}
                      options={options}
                      placeholder="Rows per page"
                    />
                  </Col>
                </Row>
              </Col>

              <Col>
                <ReactPaginate
                  forcePage={page}
                  containerClassName="paginations justify-content-end"
                  previousClassName="pages-items-containers"
                  previousLinkClassName="pages-links-labels"
                  nextClassName="pages-items-containers"
                  nextLinkClassName="pages-links-labels"
                  breakClassName="pages-items-containers"
                  pageCount={user.getNewUser.totalPages}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  pageLinkClassName="pages-links-labels"
                  pageClassName="pages-items-containers"
                  activeClassName="actives"
                  activeLinkClassName="activess"
                  nextLabel=" > "
                  previousLabel=" < "
                  initialPage={page}
                  disableInitialCallback={true}
                  onPageChange={(val) => {
                    setPage(val.selected);
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
}

export default UserNew;
