import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";

// export function tambahPartner(data) {
//   Swal.fire({
//     title: "Loading ...",
//     didOpen() {
//       Swal.showLoading();
//     },
//     allowOutsideClick: false,
//     allowEscapeKey: false,
//     allowEnterKey: false,
//   });
//   baseAxios
//     .post("/webPartner/tambahPartner", data, {
//       headers: { token: localStorage.getItem("token") },
//     })
//     .then(async (respon) => {
//       if (respon.status === 200) {
//         Swal.fire({
//           title: respon.data.message,
//           icon: "success",
//           showConfirmButton: false,
//           timer: 1500,
//         }).then(() => {
//           window.location.reload();
//         });
//       } else {
//         Swal.fire({
//           title: respon.data.message,
//           icon: "error",
//           showConfirmButton: false,
//           timer: 1500,
//         });
//       }
//     })
//     .catch(errorHandler);
// }

export async function getAllPerusahaan(dispatch) {
  try {
    const response = await baseAxios.get("/webPerusahaan/getAllPerusahaan");
    dispatch({
      type: "SET_GETALL_PERUSAHAAN",
      data: response.data,
    });
    return response.status;
  } catch (err) {
    return err.response;
  }
}
