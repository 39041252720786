import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";

export async function tambahSimpanan(data) {
  // console.log(data, "LLLLL");
  try {
    const response = await baseAxios.post("webSimpanan/tambahSimpanan", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updateAllSimpanan(data) {
  try {
    const response = await baseAxios.post("webSimpanan/autoAddSimpanan", data, {
      headers: { token: localStorage.getItem("token") },
    });
    // console.log(response, "LLLLL");
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getAllSimpanan(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webSimpanan/getSimpanan?page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_GETALL_SIMPANAN", data: response.data });
    // console.log(response, "PPP");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getDetailSimpanan(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      `webSimpanan/detailSimpanan?page=${data.page}&limit=${data.limit}`,
      { idUser: data.idUser },
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_DETAIL_SIMPANAN", data: response.data });

    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getUserAktifSimpanan(dispatch, data, history) {
  // console.log(data, "PPP");
  try {
    const response = await baseAxios.get(
      `webSimpanan/getUserAktifSimpanan?tglSimpanan=${data}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // console.log(response, "LLLL");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function ubahSimpanan(data) {
  try {
    const response = await baseAxios.patch("webSimpanan/ubahSimpanan", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function getAllUpdateSimpanan(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webSimpanan/getUpdateSimpananBulanan?page=${data.page}&limit=${data.limit}&cari=${data.cari}&statusPengajuan=${data.statusPengajuan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_GETALL_UPDATE_SIMPANAN", data: response.data });
    // console.log(response, "GET ALL UPDATE SIMPANAN");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function upgradeSimpanan(dispatch, data, history) {
  // console.log(data, "LLL");
  try {
    const response = await baseAxios.patch(
      "webSimpanan/updatePengajuanUpgradeSimpanan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function exportUpgradeSimpanan(data) {
  try {
    const response = await baseAxios.post(
      "webSimpanan/exportUpgradeSimpanan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
        responseType: "blob",
      }
    );
    // console.log(response, "AAA");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function exportAllSimpananExsist(data) {
  try {
    const response = await baseAxios.post(
      "webSimpanan/exportAllSimpanan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
        responseType: "blob",
      }
    );
    // console.log(response, "AAA");
    return response.data;
  } catch (err) {
    return err.response;
  }
}
