import React from "react";
import { Button, Card } from "react-bootstrap";
import AnimationSuccess from "assets/img/animation_success.gif";

const DeviceBerhasil = () => {
  return (
    <div
      style={{
        // backgroundColor: "#F5F5F5",
        display: "grid",
        placeItems: "center",
        height: "100vh",
      }}
    >
      <Card
        style={{
          borderWidth: 0,
          alignItems: "center",
          width: "100%",
          padding: 20,
          borderRadius: 10,
        }}
      >
        <img
          style={{ width: "300px" }}
          src={AnimationSuccess}
          alt="Icon Success"
        />
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 24,
            paddingTop: "20px",
          }}
        >
          Horee perangkat kamu berhasil direset
        </p>
        <p
          style={{
            textAlign: "center",
            fontWeight: "400",
            fontSize: 18,
          }}
        >
          Bisa dicoba login ya
        </p>
      </Card>
    </div>
  );
};

export default DeviceBerhasil;
