import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from "../../stores";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Col,
  Row,
} from "react-bootstrap";
import Wave from "assets/img/wave.png";
import SignIn from "assets/img/sign_in_illustration_2.svg";

function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPass, setShowPass] = React.useState(false);
  const toggleShowPass = () => {
    setShowPass(!showPass);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login(dispatch, { email, password }, history);
    }
  };

  return (
    <>
      <div>
        <img
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            height: "100%",
            zIndex: -1,
          }}
          className="wave"
          src={Wave}
          alt="wave"
        />
        <Container fluid>
          <Row>
            <Col md={6} className="d-none d-md-block">
              <div
                className="img"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: 100,
                  marginRight: 50,
                }}
              >
                <img style={{ width: "500px" }} src={SignIn} alt="background" />
              </div>
            </Col>
            <Col xs={12} md={3} style={{ marginTop: 160 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <div style={{ width: "100%" }}>
                  <h2
                    className="title"
                    style={{
                      color: "#333",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      marginBottom: 40,
                    }}
                  >
                    Welcome
                  </h2>
                  <div
                    className="div"
                    style={{
                      borderBottom: "2px solid #6B2FE0",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 32,
                    }}
                  >
                    <i
                      className="fas fa-envelope"
                      style={{ color: "#6B2FE0" }}
                    ></i>
                    <Form.Control
                      style={{
                        border: "none",
                        outline: "none",
                        background: "none",
                        fontSize: "14px",
                        color: "#555",
                        fontWeight: "sans-serif",
                      }}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      onKeyDown={handleKeyDown}
                      placeholder=" Email"
                      type="email"
                    />
                  </div>

                  <div
                    className="input-div pass"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "2px solid #6B2FE0",
                    }}
                  >
                    <i
                      className="fas fa-lock"
                      style={{
                        color: "#6B2FE0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></i>
                    <Form.Control
                      style={{
                        border: "none",
                        outline: "none",
                        background: "none",
                        fontSize: "14px",
                        color: "#555",
                        fontWeight: "sans-serif",
                      }}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      onKeyDown={handleKeyDown}
                      placeholder=" Password"
                      type={showPass ? "text" : "password"}
                    />
                    <div
                      className="password-icon"
                      onClick={toggleShowPass}
                      style={{ fontSize: 12 }}
                    >
                      {showPass ? (
                        <i
                          className="fas fa-eye"
                          style={{
                            color: "#6B2FE0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-eye-slash"
                          style={{
                            color: "#6B2FE0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        ></i>
                      )}
                    </div>
                  </div>
                  <Button
                    onClick={() => {
                      login(dispatch, { email, password }, history);
                    }}
                    className="btn-wd"
                    type="submit"
                    style={{
                      display: "block",
                      width: "100%",
                      height: "50px",
                      borderRadius: "25px",
                      outline: "none",
                      border: "none",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      margin: "1rem 0",
                      color: "#FFF",
                      borderColor: "#6B2FE0",
                      backgroundColor: "#6B2FE0",
                      cursor: "pointer",
                    }}
                  >
                    Login
                  </Button>
                  <div
                    sm="6"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <a
                      href="/auth/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#6B2FE0",
                        fontSize: 14,
                        textDecoration: "none",
                      }}
                    >
                      Privacy Policy
                    </a>
                    <a
                      href="/auth/termsandconditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#6B2FE0",
                        fontSize: 14,
                        textDecoration: "none",
                      }}
                    >
                      Terms & Conditions
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LoginPage;
