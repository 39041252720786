const initialState = {
  listSimpanan: null,
  detailSimpanan: null,
  userAktifSimpanan: [],
  listUpdateSimpanan: null,
  modalDetailSimpanan: true,
};

const simpananReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_GETALL_SIMPANAN":
      return {
        ...state,
        listSimpanan: data,
      };
    case "SET_DETAIL_SIMPANAN":
      return {
        ...state,
        detailSimpanan: data,
      };
    case "SET_USER_AKTIF":
      return {
        ...state,
        userAktifSimpanan: data,
      };
    case "SET_GETALL_UPDATE_SIMPANAN":
      return {
        ...state,
        listUpdateSimpanan: data,
      };
    case "SET_MODAL_SIMPANAN":
      return {
        ...state,
        modalDetailSimpanan: data,
      };
    default:
      return state;
  }
};

export default simpananReducer;
