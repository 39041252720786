import React from "react";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import Swal from "sweetalert2";

const addPengajuan = () => {
  const [ktpNumber, setKtpNumber] = React.useState("");
  const [isKtpValid, setIsKtpValid] = React.useState(false);

  const handleCekKtp = () => {
    const ktpRegex = /^\d{16}$/;
    if (ktpRegex.test(ktpNumber)) {
      setIsKtpValid(true);
      Swal.fire({
        icon: "success",
        title: "KTP Valid!",
        text: "Pengecekan KTP sukses.",
      });
    } else {
      setIsKtpValid(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Nomor KTP harus berisi tepat 16 digit angka.",
      });
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Card
          className="text-center"
          style={{ maxWidth: "700px", padding: "20px" }}
        >
          <Card.Body>
            <Card.Title style={{ fontWeight: "bold" }}>Cek KTP</Card.Title>
            <Card.Text style={{ fontWeight: "bold" }}>
              Silakan masukkan nomor KTP Anda untuk melakukan pengecekan.
            </Card.Text>
            <Col>
              <Form.Group controlId="formKtpNumber">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Nomor KTP"
                    value={ktpNumber}
                    onChange={(e) =>
                      setKtpNumber(e.target.value.substring(0, 16))
                    }
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>{ktpNumber.length}/16</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Col>
            <Button variant="primary" onClick={handleCekKtp}>
              Cek KTP
            </Button>
            {isKtpValid && (
              <div className="mt-3">
                <p className="text-success mt-2">KTP Valid!</p>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default addPengajuan;
