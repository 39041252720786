import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

function getRouting(routes, val) {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].name === val) {
      return routes[i].layout + routes[i].path;
    }
    if (routes[i].collapse) {
      return getRouting(routes[i].views, val);
    }
  }
  return "/";
}

export function login(dispatch, data, history) {
  Swal.fire({
    title: "Tunggu ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("webLogin/loginUser", data)
    .then(async (respon) => {
      // console.log(respon, "AQQ");
      let { nama, email, nopeg, idPerusahaan, role, idUser, token } =
        respon.data;
      // console.log(
      //   nama,
      //   email,
      //   nopeg,
      //   idPerusahaan,
      //   role,
      //   "<<<<<<<<<<<<<<<<<<<<<<<<<<<,,"
      // );
      Swal.close();
      localStorage.setItem("token", token);
      localStorage.setItem("ID", idUser);
      // console.log(respon, "RRRR");
      dispatch({
        type: "SET_IDENTITY",
        data: { nama, email, nopeg, idPerusahaan, role, idUser },
      });
      history.push("/admin/dashboard");
    })
    .catch(errorHandler);
}

export function refresh(dispatch) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .get("webLogin/refreshUser", {
      headers: { token: localStorage.getItem("token") },
    })
    .then(async (respon) => {
      let { nama, email, nopeg, idPerusahaan, role, idUser, token } =
        respon.data;
      // console.log(nama, username, role, "PPPPPPPP");
      localStorage.setItem("token", token);
      localStorage.setItem("ID", idUser);
      dispatch({
        type: "SET_IDENTITY",
        data: { nama, email, nopeg, idPerusahaan, role, idUser },
      });
      Swal.close();
    })
    .catch(errorHandler);
}

export function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("ID");
  window.location.replace("/auth/login-page");
}
