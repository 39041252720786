import React from "react";
import { Card, Nav, Container, Row, Col, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import AllUser from "./AllUser";
import UserNew from "./UserNew";
import DetailUser from "../DetailUser";
import { getNewUser } from "stores";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getTotalKaryawan } from "stores";
import PengajuanKeluarKoperasi from "./PengajuanKeluar";

function IndexUser() {
  const dispatch = useDispatch();
  const modalDetailUser = useSelector((state) => state.userReducer);

  React.useEffect(() => {
    getTotalKaryawan(dispatch);
  }, []);
  return (
    <>
      {modalDetailUser.modalDetailUser ? (
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title
                    as="h3"
                    style={{
                      color: "black",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    List User
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Tab.Container
                    id="plain-tabs-example"
                    defaultActiveKey={"user"}
                  >
                    <Nav role="tablist" variant="tabs">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="user"
                          style={{ color: "#893BFF", fontWeight: "bold" }}
                        >
                          Semua User
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="userNew"
                          style={{ color: "#893BFF", fontWeight: "bold" }}
                        >
                          User Baru Join{" "}
                          {modalDetailUser.getNewUser.length === 0 ? (
                            // <Skeleton circle={true} width={30} height={20} />
                            <SkeletonTheme
                              color="#BDC7C9"
                              highlightColor="#BDC7C9"
                            >
                              <Skeleton
                                circle={true}
                                height={"100%"}
                                width={30}
                              />
                            </SkeletonTheme>
                          ) : (
                            <span
                              style={{
                                borderRadius: "50%",
                                backgroundColor: "red",
                                color: "white",
                                padding: "0.2em 0.5em",
                                marginLeft: "0.2em",
                              }}
                            >
                              {modalDetailUser.getNewUser.totalDocs}
                            </span>
                          )}
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link
                          eventKey="pengajuanKeluar"
                          style={{ color: "#893BFF", fontWeight: "bold" }}
                        >
                          Pengajuan Keluar Koperasi
                        </Nav.Link>
                      </Nav.Item> */}
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="user">
                        <AllUser />
                      </Tab.Pane>

                      <Tab.Pane eventKey="userNew">
                        <UserNew />
                      </Tab.Pane>
                      <Tab.Pane eventKey="pengajuanKeluar">
                        <PengajuanKeluarKoperasi />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <DetailUser />
        </>
      )}
    </>
  );
}

export default IndexUser;
