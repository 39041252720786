import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";

export async function getAllNotif(dispatch, data, history) {
  try {
    const response = await baseAxios.get("webNotif/getNotif", {
      headers: { token: localStorage.getItem("token") },
    });

    dispatch({
      type: "SET_NOTIFICATION",
      data: response.data,
    });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function updateNotif(data) {
  try {
    const response = await baseAxios.patch("webNotif/updateNotif", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function deleteOneNotif(data) {
  try {
    const response = await baseAxios.patch("webNotif/deleteNotif", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function deleteAllNotif(data) {
  try {
    const response = await baseAxios.patch("webNotif/deleteAllNotif", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
