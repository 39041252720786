import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Tab,
  InputGroup,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Table,
  Image,
  FormGroup,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import CurrencyInput from "react-currency-input-field";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { setConstantValue } from "typescript";
import { Label } from "reactstrap";
import { Dna } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
import { detailUser } from "../../stores";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function DetailUser(data) {
  // console.log(data, "CCC");
  const dispatch = useDispatch();
  const date = new Date();

  const detailAllUser = useSelector((state) => state.userReducer);
  const valDetail = detailAllUser.detailUser;

  const [detailListUser, setDetailListUser] = React.useState([]);
  const [idUser, setIdUser] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };
  moment.locale("id");

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "id-ID",
      options
    );
    return formattedDate;
  };

  // React.useEffect(() => {
  //   let tmp =
  //     detailAllUser.detailUser &&
  //     detailAllUser.detailUser.docs &&
  //     detailAllUser.detailUser.docs.map((val, index) => {
  //       return {
  //         ...val,
  //         action: <div className="actions-right"></div>,
  //       };
  //     });
  //   setDetailListUser(tmp);
  // }, [detailAllUser]);

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const handleBackClick = (idUser) => {
    setIdUser(idUser);
    dispatch({ type: "SET_MODAL_USER", data: true });
  };

  // React.useEffect(() => {
  //   setIsLoading(true);
  //   detailUser(dispatch, {
  //     idUser: data.data.idUser,
  //     page: page + 1,
  //     limit: totalPerHalaman,
  //   })
  //     .then((response) => {
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       Swal.fire({
  //         icon: "error",
  //         title: "Error",
  //         text: "Terjadi kesalahan saat memuat data.",
  //       });
  //     });
  // }, [page, totalPerHalaman]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card style={{ border: 0 }}>
                <Card.Header style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    className="btn-transparent mr-2"
                    type="button"
                    variant="primary"
                    size="md"
                    style={{
                      border: "none",
                      backgroundColor: "#00BFFF",
                      border: 0,
                    }}
                    onClick={handleBackClick}
                  >
                    <i className="fas fa-arrow-left"></i>
                  </Button>
                  <Card.Title
                    as="h3"
                    style={{
                      marginTop: 10,
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      flex: 1,
                      marginBottom: 15,
                    }}
                  >
                    Detail User
                  </Card.Title>
                </Card.Header>
              </Card>

              <Card.Body>
                <Card style={{ border: 0 }}>
                  <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
                    <div className="row">
                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          nama
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "none",
                            }}
                          >
                            {valDetail.nama}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          nopeg
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.nopeg}
                          </Label>
                        </li>
                      </div>
                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          no telp
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.noTelp}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          email
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "none",
                            }}
                          >
                            {valDetail.email ? valDetail.email : "-"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          perusahaan
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.perusahaan.namaPerusahaan}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          client
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.client.namaClient}
                          </Label>
                        </li>
                      </div>
                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          alamat ktp
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.alamatKtp}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          cabang
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.cabang.namaCabang}
                          </Label>
                        </li>
                      </div>
                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          rt ktp
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.rtKtp !== "" ? valDetail.rtKtp : "-"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          rw ktp
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.rwKtp !== "" ? valDetail.rwKtp : "-"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          kecamatan ktp
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.kecamatanKtp !== ""
                              ? valDetail.kecamatanKtp
                              : "-"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          kelurahan ktp
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.kelurahanKtp !== ""
                              ? valDetail.kelurahanKtp
                              : "-"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          kota ktp
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.kotaKtp !== "" ? valDetail.kotaKtp : "-"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          provinsi ktp
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.provinsiKtp !== ""
                              ? valDetail.provinsiKtp
                              : "-"}
                          </Label>
                        </li>
                      </div>

                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          awal bekerja
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {formatDate(valDetail.awalBekerja)}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          mulai pkwt
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {formatDate(valDetail.mulaiPkwt)}
                          </Label>
                        </li>
                      </div>
                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          akhir pkwt
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {formatDate(valDetail.akhirPkwt)}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          status karyawan
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.statusKaryawan}
                          </Label>
                        </li>
                      </div>
                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          status koperasi
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.statusKoperasi === "active"
                              ? "Aktif"
                              : "Tidak Aktif"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          jabatan
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.jabatan}
                          </Label>
                        </li>
                      </div>
                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          nominal simpanan
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {typeof valDetail.nominalSimpanan === "number" &&
                            !isNaN(valDetail.nominalSimpanan)
                              ? `Rp ${Number(
                                  valDetail.nominalSimpanan
                                ).toLocaleString("id-ID")}`
                              : "Rp 0"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          nik
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.nik}
                          </Label>
                        </li>
                      </div>
                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          alamat domisili
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.alamatDomisili !== ""
                              ? valDetail.alamatDomisili
                              : "-"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          rt domisili
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.rtDomisili !== ""
                              ? valDetail.rtDomisili
                              : "-"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          rw domisili
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.rwDomisili !== ""
                              ? valDetail.rwDomisili
                              : "-"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          kecamatan domisili
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.kecamatanDomisili !== ""
                              ? valDetail.kecamatanDomisili
                              : "-"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          kelurahan domisili
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.kelurahanDomisili !== ""
                              ? valDetail.kelurahanDomisili
                              : "-"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          kota domisili
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.kotaDomisili !== ""
                              ? valDetail.kotaDomisili
                              : "-"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-8">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          provinsi domisili
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.provinsiDomisili !== ""
                              ? valDetail.provinsiDomisili
                              : "-"}
                          </Label>
                        </li>
                      </div>
                      <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          tanggal gajian
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.tglGajian}
                          </Label>
                        </li>
                      </div>
                      {/* <div className="col-4">
                        <Label
                          style={{
                            fontSize: 16,
                            marginTop: 15,
                            fontWeight: "bold",
                            color: "#828286",
                            textTransform: "uppercase",
                          }}
                        >
                          gaji pokok
                        </Label>
                        <li>
                          <Label
                            style={{
                              fontSize: 14,
                              marginBottom: 15,
                              color: "black",
                              textTransform: "capitalize",
                            }}
                          >
                            {valDetail.gajiPokok !== 0
                              ? `Rp ${Number(
                                  valDetail.gajiPokok
                                ).toLocaleString("id-ID")}`
                              : "Rp 0"}
                          </Label>
                        </li>
                      </div> */}
                    </div>
                  </div>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {valDetail.history && valDetail.history.length > 0 ? (
          <Card>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                history user
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <div
              style={{
                maxHeight: "500px",
                overflowY: "scroll",
              }}
            >
              <VerticalTimeline lineColor="#113946">
                {valDetail.history.map((item, index) => (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    contentStyle={{
                      borderTop:
                        item.statusPengajuan === "Menunggu"
                          ? "7px solid  rgb(255, 175, 5)"
                          : item.status === "Diproses"
                          ? "7px solid  rgb(250, 93, 2)"
                          : item.status === "Disetujui"
                          ? "7px solid  rgb(90, 201, 103)"
                          : item.status === "Revisi"
                          ? "7px solid  rgb(255, 43, 75)"
                          : "7px solid  rgb(255, 15, 0)",
                    }}
                    key={index}
                    date={moment(item.date).format("DD MMM YYYY HH:mm:ss")}
                    iconStyle={{
                      background:
                        item.status === "Menunggu"
                          ? "#FFAF05"
                          : item.status === "Diproses"
                          ? "#fa5d02"
                          : item.status === "Disetujui"
                          ? "#5AC967"
                          : item.status === "Revisi"
                          ? "#FF2B4B"
                          : "#E10F00",
                      color: "#fff",
                    }}
                  >
                    <h3 className="vertical-timeline-element-title">
                      {item.status}
                    </h3>
                    <div style={{ marginTop: "15px" }}>
                      <Row>
                        <Col sm="3">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            Keterangan
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {!item.keterangan || item.keterangan === ""
                              ? "-"
                              : item.keterangan}
                          </label>
                        </Col>
                      </Row>
                    </div>
                  </VerticalTimelineElement>
                ))}
              </VerticalTimeline>
            </div>
          </Card>
        ) : null}
      </Container>
    </>
  );
}

export default DetailUser;
