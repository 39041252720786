import React from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  Container,
  Collapse,
  Dropdown,
  InputGroup,
  Row,
  Col,
  Modal,
  Form,
  Tab,
  Table,
  Image,
  FormGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getListPelunasan } from "stores/pelunasan/function";
import moment from "moment";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import DetailPelunsanan from "./DetailPelunasan";
import { Label } from "reactstrap";
import { approvePelunasan } from "stores";
import { exportPengajuanPelunasan } from "stores";

function PengajuanPelunasan() {
  const dispatch = useDispatch();
  const listPelunasan = useSelector((state) => state.pelunasanReducer);
  const detailPelunsanan = useSelector((state) => state.pelunasanReducer);
  const auth = useSelector((state) => state.authReducer);
  const totalPage = listPelunasan.listAllPelunasan;
  const isHRD = auth.role === "hrd";
  // console.log(detailPelunsanan, "LLL");

  const [modalOpenPict, setModalOpenPict] = React.useState(false);
  const [modalApprovePengajuan, setModalApprovePengajuan] =
    React.useState(false);
  const [listAllPelunasan, setListAllPelunasan] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [modalExportExcel, setModalExportExcel] = React.useState(false);
  const [filterStatus, setFilterStatus] = React.useState(null);
  const [angsuran, setAngsuran] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [liststatus, setListStatus] = React.useState([]);
  const [pilihOpsi, setPilihOpsi] = React.useState("");
  const [buktiTfUser, setBuktiTfUser] = React.useState("");
  const [buktiTfMasuk, setBuktiTfMasuk] = React.useState("");
  const [pengajuanDetail, setPengajuanDetail] = React.useState("");
  const [semuaVal, setSemuaVal] = React.useState("");
  const [statusPengajuan, setStatusPengajuan] = React.useState("");
  const [buktiTfAdmin, setBuktiTfAdmin] = React.useState("");
  const [idPengajuan, setIdPengajuan] = React.useState("");
  const [idPelunasan, setIdPelunasan] = React.useState("");
  const [noteReject, setNoteReject] = React.useState("");
  const [idUser, setIdUser] = React.useState("");
  const [fcmToken, setFcmToken] = React.useState("");
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());

  const handleFilterChange = () => {
    setLoading(true);
    setPage(0);
    getListPelunasan(dispatch, {
      statusPengajuan: pilihOpsi,
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        // setLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterChange();
    }
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const handleDetailClick = (val) => {
    // console.log(val, "PPPPPP");
    setSemuaVal(val);
    setPengajuanDetail(val.detailPengajuan);
    setBuktiTfMasuk(val.buktiTfMasuk);
    setBuktiTfUser(val.buktiTfUser);
    setAngsuran(val.listAngsuran);
    dispatch({ type: "SET_DETAIL_PENGAJUAN_PELUNASAN", data: false });
  };

  const openModal = (buktiTfUserUrl) => {
    setModalOpenPict(true);
  };

  function handleFileChange(e) {
    const selectedFiles = Array.from(e.target.files);
    setBuktiTfAdmin(selectedFiles);
  }

  const handleCloseModalUpdateStatus = () => {
    setNoteReject("");
    setStatusPengajuan("");
  };

  const removeBuktiTfAdmin = (index) => {
    setBuktiTfAdmin(buktiTfAdmin.filter((_, i) => i !== index));
  };

  const handleExportPengajuan = () => {
    setTglAwal(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
        0,
        0,
        0,
        0
      )
    );
    setTglAkhir(new Date());
  };

  const submitExportPengajuanPelunasan = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Konfirmasi Export Pengajuan Pelunasan",
      text: `Apakah Anda ingin mengexport ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, Export",
      cancelButtonText: "Batal",
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          Swal.fire({
            title: "Sedang Mengexport, Ditunggu yaa..",
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });

          const response = await exportPengajuanPelunasan({
            tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
            tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
          });

          // Mengaktifkan unduhan setelah menerima respons dari server
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Export Pengajuan Pelunasan.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Tampilkan swal.fire setelah unduhan selesai
          Swal.fire({
            icon: "success",
            title: "Data berhasil diexport",
            showConfirmButton: false,
            timer: 2000,
          });
        } catch (error) {
          // Menampilkan pesan kesalahan jika terjadi kesalahan saat ekspor
          Swal.fire({
            title: "Terjadi kesalahan",
            text: error.message,
            icon: "error",
          });
        }
      },
    });
  };

  function submitApprovalPengajuan(e) {
    e.preventDefault();
    if (!statusPengajuan) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Status Pengajuan Harus Diisi !",
      });
      return;
    }
    if (statusPengajuan.value === "Disetujui" && buktiTfAdmin === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Bukti Transfer Admin Harus Diisi !",
      });
      return;
    }

    // Validasi noteReject
    if (statusPengajuan.value === "Ditolak" && !noteReject.trim()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Note Harus Diisi !",
      });
      return;
    }

    Swal.fire({
      title: "Konfirmasi",
      text: "Apakah Anda yakin ingin menyimpan perubahan?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        let dataToSend = new FormData();
        dataToSend.append("idPengajuan", idPengajuan);
        dataToSend.append("idPelunasan", idPelunasan);
        dataToSend.append("statusPengajuan", statusPengajuan.value);
        dataToSend.append("noteReject", noteReject);
        dataToSend.append("idUser", idUser);
        dataToSend.append("fcmTokenUser", fcmToken);

        if (statusPengajuan.value === "Disetujui") {
          dataToSend.append("buktiTfAdmin", buktiTfAdmin);
        }

        approvePelunasan(dataToSend)
          .then((response) => {
            // console.log(response, "HHH");
            if (response.status === 200) {
              Swal.close();
              Swal.fire({
                position: "center",
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              setModalApprovePengajuan(false);
              setStatusPengajuan("");
              getListPelunasan(dispatch, {
                statusPengajuan:
                  filterStatus === null || filterStatus.value === ""
                    ? ""
                    : filterStatus.value,
                cari: searchText,
                page: page + 1,
                limit: totalPerHalaman,
              });
            } else if (response.status === 400) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.data.message,
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }

  React.useEffect(() => {
    let tmp =
      listPelunasan.listAllPelunasan &&
      listPelunasan.listAllPelunasan.docs.map((val, index) => {
        // console.log(val, "QQQQ");
        return {
          ...val,
          action: (
            <>
              <div className="actions-right">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Detail</Tooltip>}
                >
                  <Button
                    className="mr-2"
                    // variant="info"
                    style={{
                      // marginTop: 5,
                      padding: "6px 15px",
                      fontSize: "18px",
                      backgroundColor: "#73B4E2",
                      border: 0,
                    }}
                    onClick={() => {
                      handleDetailClick(val);
                    }}
                  >
                    <i class="fas fa-info"></i>
                  </Button>
                </OverlayTrigger>
              </div>

              <div className="actions-right">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Approval Pengajuan</Tooltip>}
                >
                  <Button
                    className="mr-2"
                    disabled={
                      isHRD ||
                      val.statusPengajuan === "Ditolak" ||
                      val.statusPengajuan === "Disetujui"
                    }
                    style={{
                      cursor:
                        isHRD ||
                        val.statusPengajuan === "Ditolak" ||
                        val.statusPengajuan === "Disetujui"
                          ? "not-allowed"
                          : "pointer",
                      padding: "6px 15px",
                      fontSize: "18px",
                      // backgroundColor: isHRD ? "grey" : "#DC381F",
                      backgroundColor: "#DC381F",
                      border: 0,
                    }}
                    onClick={() => {
                      // console.log(val, "LLL");
                      setModalApprovePengajuan(true);
                      setIdPelunasan(val.idPelunasan);
                      setIdPengajuan(val.idPengajuan);
                      setIdUser(val.idUser);
                      setFcmToken(val.detailUser.fcmToken);
                    }}
                  >
                    <i class="far fa-edit" style={{ fontWeight: "bold" }}></i>
                  </Button>
                </OverlayTrigger>
              </div>

              {/* <div className="actions-right">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Bukti Transfer</Tooltip>}
                >
                  <Button
                    className="ml-2"
                    style={{
                      marginTop: 5,
                      padding: "6px 15px",
                      fontSize: "18px",
                      marginBottom: 10,
                      border: 0,
                      borderColor: "white",
                    }}
                    onClick={() => {
                      openModal(val.buktiTfUser);
                    }}
                  >
                    <i class="far fa-image"></i>
                  </Button>
                </OverlayTrigger>
              </div> */}
            </>
          ),
        };
      });
    setListAllPelunasan(tmp || []);
  }, [listPelunasan.listAllPelunasan]);

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: false,
      },
      {
        value: "Menunggu",
        label: "Menunggu",
      },
      {
        value: "Disetujui",
        label: "Disetujui",
      },
      {
        value: "Ditolak",
        label: "Ditolak",
      },
    ];
    setListStatus(tmp);
  }, [listPelunasan.listAllPelunasan, page, totalPerHalaman]);

  const optionStatusPengajuan = [
    {
      value: "",
      label: "Pilih Status",
      isDisabled: true,
    },
    {
      value: "Disetujui",
      label: "Disetujui",
    },
    {
      value: "Ditolak",
      label: "Ditolak",
    },
  ];

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const getStatusStyle = (status) => {
    switch (status) {
      case "Menunggu":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE1B8",
          textColor: "#FFA534",
        };
      case "Disetujui":
        return {
          fontWeight: "bold",
          backgroundColor: "#E4F5C9",
          textColor: "#87CB16",
        };
      case "Ditolak":
        return {
          fontWeight: "bold",
          backgroundColor: "#E10F00",
          textColor: "#FFFFFF",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colPengajuanPelunasan = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) =>
        index + listPelunasan.listAllPelunasan.pagingCounter,
    },
    {
      name: "ID Pelunsan",
      minWidth: "240px",
      center: true,
      selector: (row) => row.idPelunasan,
    },
    {
      name: "ID Referensi",
      minWidth: "240px",
      center: true,
      selector: (row) => row.idPengajuan,
    },
    {
      name: "Tgl Pengajuan",
      minWidth: "240px",
      center: true,
      selector: (row) => moment(row.tglPengajuan).format("DD MMMM YYYY"),
    },
    {
      name: "Nama",
      minWidth: "240px",
      center: true,
      selector: (row) => row.detailUser.nama,
    },
    {
      name: "Nopeg",
      minWidth: "240px",
      center: true,
      selector: (row) => row.detailUser.nopeg,
    },
    {
      name: "Tanggal Pengajuan",
      minWidth: "180px",
      center: true,
      selector: (row) => moment(row.tglPengajuan).format("DD MMMM YYYY"),
    },
    {
      name: "Nominal Pokok",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalPokokPelunasan);
        return formattedValue;
      },
    },
    {
      name: "Nominal Admin",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalAdminPelunasan);
        return formattedValue;
      },
    },
    {
      name: "Total Keseluruhan",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalTotal);
        return formattedValue;
      },
    },
    {
      name: "Status Pengajuan",
      minWidth: "200px",
      center: true,
      selector: (row) => row.statusPengajuan,
      cell: (row) => {
        const statusStyle = getStatusStyle(row.statusPengajuan);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusPengajuan}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "300px",
      center: true,
      cell: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    getListPelunasan(dispatch, {
      statusPengajuan:
        filterStatus === null || filterStatus.value === ""
          ? ""
          : filterStatus.value,
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        // setLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [page, totalPerHalaman]);

  return (
    <>
      {detailPelunsanan.modalDetailPelunasan ? (
        <>
          <Modal
            size="md"
            show={modalExportExcel}
            onHide={() => {
              handleExportPengajuan();
              setModalExportExcel(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Row>
                <Col md="12">
                  <Form action="" className="form" method="">
                    <Card style={{ border: 0 }}>
                      <Card.Header>
                        <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                          Export Pengajuan Pelunasan
                        </Card.Title>
                      </Card.Header>
                      <hr></hr>
                      <Card.Body>
                        <Row>
                          <Col sm="12">
                            <Form.Group>
                              <label
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  color: "grey",
                                  textTransform: "none",
                                }}
                              >
                                Tanggal Awal
                              </label>
                              <ReactDatetime
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Date Picker Here",
                                  readOnly: true,
                                  style: {
                                    cursor: "default",
                                    color: "black",
                                    backgroundColor: "white",
                                  },
                                }}
                                onChange={(e) => {
                                  e.toDate().setHours(0, 0, 0, 0),
                                    setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                                }}
                                value={tglAwal}
                                initialValue={tglAwal}
                                timeFormat={false}
                              ></ReactDatetime>
                              {/* <Form.Control
                            onChange={(e) => {
                              setTglAwal(e.target.value);
                            }}
                            type="date"
                          /> */}
                            </Form.Group>
                          </Col>
                          <Col sm="12">
                            <Form.Group>
                              <label
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  color: "grey",
                                  textTransform: "none",
                                }}
                              >
                                Tanggal Akhir
                              </label>
                              <ReactDatetime
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Date Picker Here",
                                  readOnly: true,
                                  style: {
                                    cursor: "default",
                                    color: "black",
                                    backgroundColor: "white",
                                  },
                                }}
                                onChange={(e) => {
                                  e.toDate().setHours(23, 59, 0, 0),
                                    setTglAkhir(
                                      e.toDate().setHours(23, 59, 0, 0)
                                    );
                                }}
                                value={tglAkhir}
                                initialValue={tglAkhir}
                                timeFormat={false}
                              ></ReactDatetime>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          style={{
                            fontWeight: "bold",
                            color: "#21ABCD",
                            backgroundColor: "#CCEDF5",
                            border: 0,
                          }}
                          onClick={submitExportPengajuanPelunasan}
                          // onClick={submitExportExcelNonCair}
                        >
                          Submit
                        </Button>
                        <div className="clearfix"></div>
                      </Card.Body>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>

          <Modal
            size="lg"
            show={modalApprovePengajuan}
            onHide={() => {
              handleCloseModalUpdateStatus();
              setModalApprovePengajuan(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Card style={{ border: 0 }}>
                <Card.Header>
                  <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                    Update Status Pengajuan
                  </Card.Title>
                </Card.Header>
                <hr></hr>
                <Card.Body>
                  <Form.Group>
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: 14,
                        color: "grey",
                        textTransform: "none",
                      }}
                    >
                      Status Pengajuan
                    </label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleStatus"
                      onChange={(value) => {
                        setStatusPengajuan(value);
                      }}
                      options={optionStatusPengajuan}
                      placeholder="Pilih Status"
                    />
                    {statusPengajuan.value === "Disetujui" ? (
                      <Row style={{ marginTop: 15 }}>
                        <Col>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Upload Bukti Transfer
                          </label>
                        </Col>

                        <Col md="12">
                          <Form.Control
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            onChange={(e) => {
                              setBuktiTfAdmin(e.target.files[0]);
                              // setSizeFile(e.target.files[0].size / 1000000);
                            }}
                          ></Form.Control>
                        </Col>
                      </Row>
                    ) : null}

                    {statusPengajuan.value === "Ditolak" ? (
                      <>
                        <Form.Text
                          className="text-muted"
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          Note
                        </Form.Text>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          onChange={(e) => {
                            setNoteReject(e.target.value);
                          }}
                        ></Form.Control>
                      </>
                    ) : null}
                  </Form.Group>
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="info"
                    onClick={submitApprovalPengajuan}
                  >
                    Submit
                  </Button>
                  <div className="clearfix"></div>
                </Card.Body>
              </Card>
            </Modal.Body>
          </Modal>

          <Container fluid>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>
                    <Card.Title
                      as="h3"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      Pengajuan Pelunasan Dipercepat
                      <hr></hr>
                    </Card.Title>
                  </Card.Header>
                  <Card
                    style={{
                      border: 0,
                      marginTop: 20,
                    }}
                  >
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <Col md={"4"} style={{ marginTop: 15, marginLeft: 12 }}>
                        <InputGroup>
                          <Form.Control
                            style={{
                              paddingLeft: "10px",
                            }}
                            type="text"
                            value={searchText}
                            onChange={handleSearch}
                            onKeyDown={handleKeyDown}
                            placeholder="Referensi ID/Nopeg/Nama"
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={handleClearSearch}
                            >
                              <i class="fas fa-times"></i>
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>

                      <Col md={"3"} style={{ marginTop: 15 }}>
                        <div>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="Pilih Status"
                            value={filterStatus}
                            onChange={(selectedOption) => {
                              setFilterStatus(selectedOption);
                              setPilihOpsi(selectedOption.value);
                            }}
                            options={[
                              { value: "", label: "All" },
                              ...liststatus,
                            ]}
                            onKeyDown={handleKeyDown}
                            placeholder="Pilih Status Pengajuan"
                          />
                        </div>
                      </Col>
                      <Col>
                        <Button
                          // variant="info"
                          style={{
                            fontSize: 16,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingTop: 10,
                            paddingBottom: 7,
                            marginTop: 15,
                          }}
                          onClick={handleFilterChange}
                        >
                          <i class="fas fa-search"></i>
                        </Button>
                      </Col>
                    </Row>
                    <div style={{ marginTop: 15, marginLeft: 15 }}>
                      <Button
                        className="btn-wd "
                        onClick={(e) => {
                          setModalExportExcel(true);
                        }}
                        variant="success"
                        style={{
                          backgroundColor: "#6BD98A",
                          border: 0,
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        <i
                          className="fas fa-file-excel fa-lg"
                          style={{ marginRight: 10 }}
                        ></i>
                        Export Pengajuan Pelunasan
                      </Button>
                    </div>
                  </Card>
                  <Card.Body>
                    <div>
                      {listAllPelunasan.length > 0 ? (
                        <DataTable
                          columns={colPengajuanPelunasan}
                          data={listAllPelunasan}
                          customStyles={customStyles}
                          paginationPerPage={totalPerHalaman}
                          progressPending={loading}
                        />
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          <p
                            style={{
                              fontWeight: "bold",
                              marginBottom: "1rem",
                            }}
                          >
                            Maaf, belum ada pengajuan pelunasan saat ini.
                          </p>
                          <img
                            src={require("assets/img/sorry.jpg").default}
                            alt="Sorry"
                            style={{
                              maxWidth: "20%",
                              display: "block",
                              margin: "0 auto",
                            }}
                          />
                        </div>
                      )}

                      <div style={{ marginTop: 35 }}>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col>
                            <Row style={{ alignItems: "center" }}>
                              <div
                                style={{
                                  paddingRight: 0,
                                  marginLeft: 20,
                                  marginRight: 10,
                                }}
                              >
                                <label style={{ fontWeight: "bold" }}>
                                  Rows per page
                                </label>
                              </div>
                              <Col sm="3" style={{ paddingLeft: 0 }}>
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  name="Pilih Client"
                                  value={options.find(
                                    (option) => option.value === totalPerHalaman
                                  )}
                                  onChange={handlePageSizeChange}
                                  options={options}
                                  placeholder="Rows per page"
                                />
                              </Col>
                            </Row>
                          </Col>

                          <Col>
                            <ReactPaginate
                              forcePage={page}
                              containerClassName="paginations justify-content-end"
                              previousClassName="pages-items-containers"
                              previousLinkClassName="pages-links-labels"
                              nextClassName="pages-items-containers"
                              nextLinkClassName="pages-links-labels"
                              breakClassName="pages-items-containers"
                              pageCount={totalPage && totalPage.totalPages}
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={1}
                              pageLinkClassName="pages-links-labels"
                              pageClassName="pages-items-containers"
                              activeClassName="actives"
                              activeLinkClassName="activess"
                              nextLabel=" > "
                              previousLabel=" < "
                              initialPage={page}
                              disableInitialCallback={true}
                              onPageChange={(val) => {
                                setPage(val.selected);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <>
          <DetailPelunsanan
            data={angsuran}
            urlTfUser={buktiTfUser}
            urlTfMasuk={buktiTfMasuk}
            detailPengajuan={pengajuanDetail}
            allVal={semuaVal}
          />
        </>
      )}
    </>
  );
}

export default PengajuanPelunasan;
