import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
  Collapse,
  Alert,
} from "react-bootstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";

import {
  getAllPengajuan,
  getAllUpdateSimpanan,
  getTotalKaryawan,
  logout,
  totalAllPengajuan,
  totalNominalLunas,
  totalNominalOngoing,
  totalPenyaluran,
} from "../../stores";
import io, { Socket } from "socket.io-client";
import { baseUrl } from "../../stores";
import { useDispatch, useSelector } from "react-redux";
import { getAllNotif } from "stores/notifikasi/function";
import audio from "../../assets/sound/notif.mp3";

function AdminNavbar() {
  const dispatch = useDispatch();
  let datenow = new Date().getTime();
  const idUser = localStorage.getItem("ID");
  const notif = useSelector((state) => state.notifReducer);
  const ambilNotif = notif.listNotif;
  // console.log(ambilNotif, "QQQ");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [modalUbahPassword, setModalUbahPassword] = React.useState(false);
  const [oldPass, setOldPass] = React.useState("");
  const [newPass, setNewPass] = React.useState("");
  const [confirmPass, setConfirmPass] = React.useState("");
  const [passwordMatch, setPasswordMatch] = React.useState(true);
  const [showNewPass, setShowNewPass] = React.useState(false);
  const [showConfirmPass, setShowConfirmPass] = React.useState(false);
  const [showNotif, setShowNotif] = React.useState([]);
  const history = useHistory();
  const [notifikasi, setNotifikasi] = React.useState(null);
  const [showAlert, setShowAlert] = React.useState(false);
  const [dariTgl, setDariTgl] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [smpTgl, setSmpTgl] = React.useState(new Date());
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0)
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "id-ID",
      options
    );

    const hours = new Date(dateString).getHours();
    const minutes = new Date(dateString).getMinutes();
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return `${formattedDate} ${formattedTime}`;
  };

  const handleHalamanNotifikasi = () => {
    history.push("/admin/notifikasi");
  };

  const handleNewPassChange = (e) => {
    setNewPass(e.target.value);
    setPasswordMatch(e.target.value === confirmPass);
  };

  const handleConfirmPassChange = (e) => {
    setConfirmPass(e.target.value);
    setPasswordMatch(e.target.value === newPass);
  };

  const toggleShowNewPass = () => {
    setShowNewPass(!showNewPass);
  };

  const toggleShowConfirmPass = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  const submitUbahPassword = () => {
    gantiPassword(dispatch, {
      username: auth.nopeg,
      passwordLama: oldPass,
      passwordBaru: newPass,
      role: auth.role,
    });
  };

  React.useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  }, [showAlert]);

  React.useEffect(() => {
    let tmp =
      notif.listNotif &&
      notif.listNotif.data.response.map((val, index) => {
        return {
          ...val,
          action: <div className="actions-right"></div>,
        };
      });
    setShowNotif(tmp);
  }, [notif.listNotif]);

  const playNotificationSound = () => {
    new Audio(audio).play();
  };

  React.useEffect(() => {
    const socket = io(baseUrl);
    socket.on("connect", () => {
      console.log("connected to server");
    });
    socket.emit("joinRoom", idUser);
    socket.on("notifikasi", (notif) => {
      console.log(notif, "NOTIFFFFF1");
      playNotificationSound();
      // alert("New Notification: " + notif);
      setNotifikasi(notif);
      getAllNotif(dispatch);
      totalPenyaluran(dispatch, {
        tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
        tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
      });
      totalNominalLunas(dispatch, {
        tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
        tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
      });
      totalNominalOngoing(dispatch, {
        tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
        tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
      });
      totalAllPengajuan(dispatch, {
        tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
        tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
      });
      getTotalKaryawan(dispatch);
      getAllPengajuan(dispatch, {
        statusPengajuan: "",
        cari: "",
        page: 1,
        limit: 10,
        tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
        tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
      });
      getAllUpdateSimpanan(dispatch, {
        statusPengajuan: "",
        cari: "",
        page: 1,
        limit: 10,
      });
      console.log(notif, "NOTIFFFFF2");
      setShowAlert(true);
    });
    getAllNotif(dispatch);
    return () => {
      socket.disconnect();
    };
  }, []);

  // React.useEffect(() => {
  //   getAllNotif(dispatch);
  // }, []);

  return (
    <>
      <div
        style={{
          position: "absolute",
          zIndex: 100,
          marginTop: 40,
          right: 0,
          marginRight: 15,
          width: "50%",
        }}
      >
        <Alert
          variant="primary"
          onClose={() => {}}
          // style={{ padding: 0, paddingTop: 0.5 }}
          show={showAlert}
        >
          <div>
            <h4 style={{ marginTop: 0, marginBottom: 5 }}>
              <b style={{ marginLeft: 5 }}>Notifikasi</b>
            </h4>
          </div>

          <p>{notifikasi}</p>
        </Alert>
      </div>
      <Modal
        size="lg"
        show={modalUbahPassword}
        onHide={() => {
          setModalUbahPassword(false);
          setOldPass("");
          setNewPass("");
          setConfirmPass("");
          setPasswordMatch(true);
          setShowNewPass(false);
          setShowConfirmPass(false);
        }}
        // onHide={() => setModalUbahPassword(!modalUbahPassword)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                Ubah Password
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Password Lama
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setOldPass(e.target.value);
                    }}
                    placeholder="Masukkan Password Lama"
                    type="text"
                  ></Form.Control>
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      marginTop: 15,
                    }}
                  >
                    Password Baru
                  </label>
                  <div
                    className="password-input"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Form.Control
                      onChange={handleNewPassChange}
                      style={{ flex: 2 }}
                      placeholder="Masukkan Password Baru"
                      type={showNewPass ? "text" : "password"}
                    />
                    <div
                      className="password-icon"
                      onClick={toggleShowNewPass}
                      style={{ fontSize: 11 }}
                    >
                      {showNewPass ? (
                        <Button style={{ marginLeft: 15 }}>
                          <i class="fa fa-eye"></i>
                        </Button>
                      ) : (
                        <Button style={{ marginLeft: 15 }}>
                          <i class="fa fa-eye-slash"></i>
                        </Button>
                      )}
                    </div>
                  </div>
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      marginTop: 15,
                    }}
                  >
                    Konfirmasi Password Baru
                  </label>
                  <div
                    className="password-input"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Form.Control
                      onChange={handleConfirmPassChange}
                      placeholder="Konfirmasi Password Baru"
                      type={showConfirmPass ? "text" : "password"}
                      style={{
                        borderColor: passwordMatch ? "" : "red",
                      }}
                    />
                    <span
                      className="password-icon"
                      onClick={toggleShowConfirmPass}
                      style={{ fontSize: 11 }}
                    >
                      {showConfirmPass ? (
                        <Button style={{ marginLeft: 15 }}>
                          <i class="fa fa-eye"></i>
                        </Button>
                      ) : (
                        <Button style={{ marginLeft: 15 }}>
                          <i class="fa fa-eye-slash"></i>
                        </Button>
                      )}
                    </span>
                  </div>
                  {!passwordMatch && (
                    <p style={{ color: "red" }}>
                      Password harus sama seperti password baru.
                    </p>
                  )}
                </Form.Group>
              </Form>
            </Card.Body>

            <Card.Footer>
              <Button
                className="btn-fill"
                type="submit"
                variant="primary"
                onClick={submitUbahPassword}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>
      <Navbar expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"
                variant="dark"
                onClick={() => document.body.classList.toggle("sidebar-mini")}
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
              <Button
                className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                variant="dark"
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
            </div>
            {/* <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
              Buttons
            </Navbar.Brand> */}
          </div>
          <button
            className="navbar-toggler navbar-toggler-right border-0"
            type="button"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </button>
          <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
            {/* <Nav className="nav mr-auto" navbar>
              <Form
                className="navbar-form navbar-left navbar-search-form ml-3 ml-lg-0"
                role="search"
              >
                <InputGroup>
                  <i className="nc-icon nc-zoom-split"></i>
                  <Form.Control
                    defaultValue=""
                    placeholder="Search..."
                    type="text"
                  ></Form.Control>
                </InputGroup>
              </Form>
            </Nav> */}
            <Nav navbar>
              {/* {/* <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="dropdown-165516306"
                  variant="default"
                >
                  <i className="nc-icon nc-planet"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Create New Post
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Manage Something
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Do Nothing
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Submit to live
                  </Dropdown.Item>
                  <li className="divider"></li>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Another action
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
              {/* {ambilNotif && ambilNotif.data.response.length > 0 && ( */}
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="dropdown-414718872"
                  variant="default"
                  style={{ color: "black" }}
                >
                  <i
                    class="far fa-bell"
                    style={{
                      fontSize: 24,
                      color: "black",
                    }}
                  ></i>
                  {ambilNotif && ambilNotif.data.notRead > 0 ? (
                    <span
                      className="notification"
                      style={{ marginLeft: 3, marginTop: 10 }}
                    >
                      {ambilNotif && ambilNotif.data.notRead}
                    </span>
                  ) : null}

                  <span className="d-lg-none">Notification</span>
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight>
                  {ambilNotif &&
                    ambilNotif.data.response
                      .filter((item) => !item.statusNotif)
                      .slice(0, 3)
                      .map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          style={{ fontSize: "16px" }}
                          // onClick={() => {
                          //   handleHalamanPengajuan();
                          // }}
                        >
                          <div style={{ color: "black" }}>
                            <i
                              style={{
                                backgroundColor: "#FFFFFF",
                                marginTop: 10,
                              }}
                              className={
                                item.typeNotif === "pengajuan"
                                  ? "fas fa-file-contract text-primary mr-2"
                                  : item.typeNotif === "register"
                                  ? "fas fa-address-card text-info mr-2"
                                  : item.typeNotif === "angsuran"
                                  ? "fas fa-money-bil-wave text-success mr-2"
                                  : item.typeNotif === "pinjaman"
                                  ? "fas fa-hand-holding-usd text-warning mr-2"
                                  : "fas fa-wallet text-secondary mr-2"
                                // ? "fas fa-file-contract text-warning mr-2"
                                // : "fas fa-info-circle text-warning mr-2"
                              }
                              // style={{ fontSize: 24 }}
                            ></i>
                            {item.message}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "gray",
                              paddingLeft: 26,
                              paddingTop: 4,
                              fontWeight: "bold",
                            }}
                          >
                            {formatDate(item.dateNotif)}
                          </div>
                        </Dropdown.Item>
                      ))}
                  <Dropdown.Item
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#212121",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      handleHalamanNotifikasi();
                    }}
                  >
                    <div>Lihat Semua Notifikasi</div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* )} */}

              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="dropdown-41471887333"
                  variant="default"
                  style={{ color: "black", marginTop: 8 }}
                >
                  <img
                    alt="..."
                    src={require("assets/img/menu.png").default}
                    height="25PX"
                    // style={{ marginBottom: 3 }}
                  ></img>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  alignRight
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {/* <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-email-85"></i>
                    Messages
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-umbrella-13"></i>
                    Help Center
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-settings-90"></i>
                    Settings
                  </Dropdown.Item>
                  <div className="divider"></div>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-lock-circle-open"></i>
                    Lock Screen
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    className="text-primary"
                    onClick={() => setModalUbahPassword(!modalUbahPassword)}
                  >
                    <i class="fa fa-key" style={{ marginRight: 10 }}></i>
                    Ubah Password
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="text-danger"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      logout();
                    }}
                  >
                    <i className="nc-icon nc-button-power"></i>
                    Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
