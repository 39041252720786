const initialState = {
  listAllAngsuran: [],
};

const angsuranReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_GETALL_ANGSURAN":
      return {
        ...state,
        listAllAngsuran: data,
      };
    default:
      return state;
  }
};

export default angsuranReducer;
