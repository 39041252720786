import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Dropdown,
  Row,
  Col,
  Tab,
  InputGroup,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Table,
  Image,
  FormGroup,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import CurrencyInput from "react-currency-input-field";
import DataTable from "react-data-table-component";
import {
  getAllSimpanan,
  getDetailSimpanan,
  tambahSimpanan,
  getUserAktifSimpanan,
  getAllUpdateSimpanan,
  upgradeSimpanan,
  exportUpgradeSimpanan,
} from "../../stores";
import DetailSimpanan from "./DetailSimpanan";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { setConstantValue } from "typescript";
import { CardBody } from "reactstrap";
import { Dna } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function UpgradeSimpanan() {
  const dispatch = useDispatch();
  const date = new Date();

  const simpanan = useSelector((state) => state.simpananReducer);
  const auth = useSelector((state) => state.authReducer);
  const [searchText, setSearchText] = React.useState("");
  const [modalUpdateStatus, setModalUpdateStatus] = React.useState(false);
  const [listAllUpdateSimpanan, setListAllUpdateSimpanan] = React.useState([]);
  const [modalExportExcel, setModalExportExcel] = React.useState(false);
  const [exportTglExcel, setExportTglExcel] = React.useState(new Date());

  const [filterStatus, setFilterStatus] = React.useState(null);
  const [listStatus, setListStatus] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [keterangan, setKeterangan] = React.useState("");
  const [idHistory, setIdHistory] = React.useState("");
  const [fcmTokenUser, setFcmTokenUser] = React.useState("");
  const [statusPengajuan, setStatusPengajuan] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [pilihOpsi, setPilihOpsi] = React.useState("");
  const [tglApproval, setTglApproval] = React.useState("");

  const handleFilterChange = () => {
    setIsLoading(true);
    setPage(0);
    getAllUpdateSimpanan(dispatch, {
      statusPengajuan: pilihOpsi,
      page: 1,
      limit: totalPerHalaman,
      cari: searchText,
    })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "id-ID",
      options
    );
    return formattedDate;
  };

  function submitUpdateStatus(e) {
    e.preventDefault();

    // Show confirmation dialog
    Swal.fire({
      title: "Konfirmasi",
      text: "Apakah Anda yakin ingin menyimpan perubahan?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    }).then((result) => {
      if (result.isConfirmed) {
        if (!statusPengajuan.value) {
          Swal.fire({
            title: "Status Harus Diisi",
            icon: "warning",
          });
          return;
        } else if (statusPengajuan.value === "Diterima") {
          upgradeSimpanan(dispatch, {
            idHistory: idHistory,
            statusPengajuan: statusPengajuan.value,
            keterangan: keterangan,
            fcmTokenUser: fcmTokenUser,
            tanggalApproval: tglApproval,
          }).then((response) => {
            if (response.status === 200) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: response.message,
                showConfirmButton: false,
                timer: 1500,
              });
              setModalUpdateStatus(false);
              getAllUpdateSimpanan(dispatch, {
                statusPengajuan:
                  filterStatus === null || filterStatus.value === ""
                    ? ""
                    : filterStatus.value,
                cari: "",
                page: page + 1,
                limit: totalPerHalaman,
              });
            } else if (response.status === 400) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.message,
              });
            }
          });
        } else {
          if (keterangan === "") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Keterangan tidak boleh kosong",
            });
            return;
          }
          upgradeSimpanan(dispatch, {
            idHistory: idHistory,
            statusPengajuan: statusPengajuan.value,
            keterangan: keterangan,
            fcmTokenUser: fcmTokenUser,
          }).then((response) => {
            if (response.status === 200) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: response.message,
                showConfirmButton: false,
                timer: 1500,
              });
              setModalUpdateStatus(false);
              handleCloseModalUpdate();
              getAllUpdateSimpanan(dispatch, {
                statusPengajuan:
                  filterStatus === null || filterStatus.value === ""
                    ? ""
                    : filterStatus.value,
                cari: "",
                page: page + 1,
                limit: totalPerHalaman,
              });
            } else if (response.status === 400) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.message,
              });
            }
          });
        }
      }
    });
  }

  const submitExportExcel = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Konfirmasi Export User",
      text: `Apakah Anda ingin mengexport ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, Export",
      cancelButtonText: "Batal",
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const data = await exportUpgradeSimpanan({
            tanggalApproval: exportTglExcel,
          });
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Export Upgrade Simpanan.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          Swal.fire({
            icon: "success",
            title: "Data berhasil diexport",
          });
        } catch (error) {
          Swal.fire({
            title: "Terjadi kesalahan",
            text: error.message,
            icon: "error",
          });
        }
      },
    });
  };

  // function submitUpdateStatus(e) {
  //   e.preventDefault();
  //   if (!statusPengajuan.value) {
  //     Swal.fire({
  //       title: "Status Harus Diisi",
  //       icon: "warning",
  //     });
  //     return;
  //   } else if (statusPengajuan.value === "Diterima") {
  //     upgradeSimpanan({
  //       idHistory: idHistory,
  //       statusPengajuan: statusPengajuan.value,
  //       keterangan: keterangan,
  //       fcmTokenUser: fcmTokenUser,
  //     }).then((response) => {
  //       if (response.status === 200) {
  //         Swal.fire({
  //           position: "center",
  //           icon: "success",
  //           title: response.message,
  //           showConfirmButton: false,
  //           timer: 1500,
  //         });
  //         setModalUpdateStatus(false);
  //         getAllUpdateSimpanan(dispatch, {
  //           statusPengajuan:
  //             filterStatus === null || filterStatus.value === ""
  //               ? ""
  //               : filterStatus.value,
  //           cari: "",
  //           page: page + 1,
  //           limit: totalPerHalaman,
  //         });
  //       } else if (response.status === 400) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: response.message,
  //         });
  //       }
  //     });
  //   } else {
  //     if (keterangan === "") {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Keterangan tidak boleh kosong",
  //       });
  //       return;
  //     }
  //     upgradeSimpanan({
  //       idHistory: idHistory,
  //       statusPengajuan: statusPengajuan.value,
  //       keterangan: keterangan,
  //       fcmTokenUser: fcmTokenUser,
  //     }).then((response) => {
  //       if (response.status === 200) {
  //         Swal.fire({
  //           position: "center",
  //           icon: "success",
  //           title: response.message,
  //           showConfirmButton: false,
  //           timer: 1500,
  //         });
  //         setModalUpdateStatus(false);
  //         handleCloseModalUpdate();
  //         getAllUpdateSimpanan(dispatch, {
  //           statusPengajuan:
  //             filterStatus === null || filterStatus.value === ""
  //               ? ""
  //               : filterStatus.value,
  //           cari: "",
  //           page: page + 1,
  //           limit: totalPerHalaman,
  //         });
  //       } else if (response.status === 400) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: response.message,
  //         });
  //       }
  //     });
  //   }
  // }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterChange();
    }
  };

  React.useEffect(() => {
    let tmp =
      simpanan.listUpdateSimpanan &&
      simpanan.listUpdateSimpanan.docs.map((val, index) => {
        console.log(val, "IIII");
        return {
          ...val,
          action: (
            <div className="actions-right">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Update Pengajuan Simpanan</Tooltip>}
              >
                <Button
                  className="mr-2"
                  disabled={
                    (auth.role === "admin" && auth.role === "hrd") ||
                    val.statusPengajuan === "Diterima"
                  }
                  style={{
                    marginTop: 5,
                    padding: "5px 15px",
                    fontSize: "18px",
                    backgroundColor: "#C0DDED",
                    border: 0,
                    cursor:
                      (auth.role === "admin" && auth.role === "hrd") ||
                      val.statusPengajuan === "Diterima"
                        ? "not-allowed"
                        : "pointer",
                  }}
                  onClick={() => {
                    // console.log(val, "KKK");
                    setIdHistory(val.idHistory);
                    setFcmTokenUser(val.user.fcmToken);
                    handleDropdownItemUpdate();
                  }}
                >
                  <i
                    class="fas fa-file-upload"
                    style={{ color: "#517E9F" }}
                  ></i>
                </Button>
              </OverlayTrigger>
            </div>
          ),
        };
      });
    setListAllUpdateSimpanan(tmp);
  }, [simpanan]);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const optionStatusPengajuan = [
    {
      value: "",
      label: "Pilih Status",
      isDisabled: true,
    },
    {
      value: "Diterima",
      label: "Diterima",
    },
    {
      value: "Ditolak",
      label: "Ditolak",
    },
  ];

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: false,
      },
      {
        value: "Menunggu",
        label: "Menunggu",
      },
      {
        value: "Diterima",
        label: "Diterima",
      },

      {
        value: "Ditolak",
        label: "Ditolak",
      },
    ];
    setListStatus(tmp);
  }, [simpanan.listAllUpdateSimpanan, page, totalPerHalaman]);

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colSimpanan = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) =>
        index + simpanan.listUpdateSimpanan.pagingCounter,
    },
    {
      name: "ID Pengajuan",
      minWidth: "280px",
      center: true,
      selector: (row) => row.idHistory,
    },
    {
      name: "Tgl Pengajuan",
      minWidth: "280px",
      center: true,
      selector: (row) => moment(row.date).format("DD MMMM YYYY"),
    },
    {
      name: "Nama",
      minWidth: "280px",
      center: true,
      selector: (row) => row.user.nama,
    },
    {
      name: "Nomor Pegawai",
      minWidth: "200px",
      center: true,
      selector: (row) => row.user.nopeg,
    },
    {
      name: "Perusahaan",
      minWidth: "270px",
      center: true,
      selector: (row) => row.namaPerusahaan.namaPerusahaan,
    },
    {
      name: "Nominal Lama",
      minWidth: "220px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalLama);
        return formattedValue;
      },
    },
    {
      name: "Nominal Baru",
      minWidth: "220px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nominalBaru);
        return formattedValue;
      },
    },
    {
      name: "Status Pengajuan",
      minWidth: "200px",
      center: true,
      selector: (row) => row.statusPengajuan,
      cell: (row) => {
        const statusStyle = getStatusStyle(row.statusPengajuan);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusPengajuan}
          </div>
        );
      },
    },
    {
      name: "Tgl Approval",
      minWidth: "280px",
      center: true,
      selector: (row) =>
        !row.tanggalApproval
          ? "-"
          : moment(row.tanggalApproval).format("DD MMMM YYYY"),
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      omit: !(auth.role === "super admin" || auth.role === "admin"),
      cell: (row) => row.action,
    },
  ];

  const handleDropdownItemUpdate = () => {
    setModalUpdateStatus(true);
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Menunggu":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE1B8",
          textColor: "#FFA534",
        };
      case "Diterima":
        return {
          fontWeight: "bold",
          backgroundColor: "#DBEFB5",
          textColor: "#87CB16",
        };

      case "Ditolak":
        return {
          fontWeight: "bold",
          backgroundColor: "#E10F00",
          textColor: "#FFFFFF",
        };
      default:
        return {};
    }
  };

  const handleCloseModalExport = () => {
    // setExportTgl(new Date());
    setExportTglExcel(new Date());
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleCloseModalUpdate = () => {
    setStatusPengajuan("");
    setKeterangan("");
  };

  React.useEffect(() => {
    setIsLoading(true);
    getAllUpdateSimpanan(dispatch, {
      statusPengajuan:
        filterStatus === null || filterStatus.value === ""
          ? ""
          : filterStatus.value,
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [page, totalPerHalaman]);

  return (
    <>
      <Modal
        size="md"
        show={modalExportExcel}
        onHide={() => {
          handleCloseModalExport();
          setModalExportExcel(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card style={{ border: 0 }}>
                  <Card.Header>
                    <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                      Export Upgrade Simpanan (Excel)
                    </Card.Title>
                  </Card.Header>
                  <hr></hr>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Pilih Tanggal Export
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              // console.log(e.target.value, "PPP");
                              setExportTglExcel(e.target.value);
                            }}
                            type="date"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitExportExcel}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  upgrade simpanan
                  <hr></hr>
                </Card.Title>
                <Tab.Content>
                  <Card style={{ border: 0, marginTop: 20 }}>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <Col md={"4"}>
                        <InputGroup>
                          <Form.Control
                            style={{
                              paddingLeft: "10px",
                            }}
                            type="text"
                            value={searchText}
                            onChange={handleSearch}
                            onKeyDown={handleKeyDown}
                            placeholder="Nama/Nopeg"
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={handleClearSearch}
                            >
                              <i class="fas fa-times"></i>
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>

                      <Col md={"3"}>
                        <div>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="Pilih Status"
                            value={filterStatus}
                            onKeyDown={handleKeyDown}
                            onChange={(selectedOption) => {
                              setFilterStatus(selectedOption);
                              setPilihOpsi(selectedOption.value);
                            }}
                            options={[
                              { value: "", label: "All" },
                              ...listStatus,
                            ]}
                            placeholder="Pilih Status Pengajuan"
                          />
                        </div>
                      </Col>
                      <Col>
                        <Button
                          // variant="info"
                          style={{
                            fontSize: 16,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingTop: 10,
                            paddingBottom: 7,
                          }}
                          onClick={handleFilterChange}
                        >
                          <i class="fas fa-search"></i>
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                  <div style={{ marginTop: 15, marginLeft: 15 }}>
                    <Button
                      className="btn-wd "
                      onClick={(e) => {
                        setModalExportExcel(true);
                      }}
                      // variant="success"
                      style={{
                        backgroundColor: "#6BD98A",
                        border: 0,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      <i
                        className="fas fa-file-excel fa-lg"
                        style={{ marginRight: 10 }}
                      ></i>
                      Export Upgrade Simpanan (Excel)
                    </Button>
                  </div>
                </Tab.Content>
              </Card.Header>
              <Card.Body>
                {isLoading ? (
                  <div
                    className="loader-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "30vh",
                    }}
                  >
                    <Dna
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="dna-loading"
                      wrapperStyle={{}}
                      wrapperClass="dna-wrapper"
                    />
                    <p>Loading...</p>
                  </div>
                ) : (
                  <div>
                    <DataTable
                      columns={colSimpanan}
                      data={listAllUpdateSimpanan}
                      customStyles={customStyles}
                      paginationPerPage={totalPerHalaman}
                      progressPending={isLoading}
                    />
                    <div style={{ marginTop: 35 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <Row style={{ alignItems: "center" }}>
                            <div
                              style={{
                                paddingRight: 0,
                                marginLeft: 20,
                                marginRight: 10,
                              }}
                            >
                              <label style={{ fontWeight: "bold" }}>
                                Rows per page
                              </label>
                            </div>
                            <Col sm="3" style={{ paddingLeft: 0 }}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Client"
                                value={options.find(
                                  (option) => option.value === totalPerHalaman
                                )}
                                onChange={handlePageSizeChange}
                                options={options}
                                placeholder="Rows per page"
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <ReactPaginate
                            forcePage={page}
                            containerClassName="paginations justify-content-end"
                            previousClassName="pages-items-containers"
                            previousLinkClassName="pages-links-labels"
                            nextClassName="pages-items-containers"
                            nextLinkClassName="pages-links-labels"
                            breakClassName="pages-items-containers"
                            pageCount={simpanan.listUpdateSimpanan.totalPages}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageLinkClassName="pages-links-labels"
                            pageClassName="pages-items-containers"
                            activeClassName="actives"
                            activeLinkClassName="activess"
                            nextLabel=" > "
                            previousLabel=" < "
                            initialPage={page}
                            disableInitialCallback={true}
                            onPageChange={(val) => {
                              setPage(val.selected);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modalUpdateStatus}
        onHide={() => {
          handleCloseModalUpdate();
          setModalUpdateStatus(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                        Update Status
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Status Pengajuan
                          </label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleStatus"
                            onChange={(value) => setStatusPengajuan(value)}
                            options={optionStatusPengajuan}
                            placeholder="Pilih Status"
                          />
                        </Form.Group>
                      </Col>
                      {statusPengajuan.value === "Ditolak" ? (
                        <Col sm="12">
                          <Form.Group>
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#grey",
                                textTransform: "none",
                                fontSize: 14,
                              }}
                            >
                              Keterangan
                            </label>
                            <textarea
                              onChange={(e) => {
                                setKeterangan(e.target.value);
                              }}
                              rows={4}
                              className="form-control"
                            />
                          </Form.Group>
                        </Col>
                      ) : null}

                      {statusPengajuan.value === "Diterima" ? (
                        <Col sm="12">
                          <Form.Group>
                            <label
                              style={{
                                fontWeight: "bold",
                                fontSize: 14,
                                color: "grey",
                                textTransform: "none",
                              }}
                            >
                              Tanggal Realisasi
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setTglApproval(e.target.value);
                              }}
                              type="date"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      ) : null}

                      {/* {statusPengajuan.value === "Diterima" ? (
                        <Col sm="12">
                          <Form.Group>
                            <label
                              style={{
                                fontWeight: "bold",
                                fontSize: 14,
                                color: "grey",
                                textTransform: "none",
                              }}
                            >
                              Tanggal Pencairan
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setTglApproval(e.target.value);
                              }}
                              type="date"
                              min={
                                new Date().getFullYear() +
                                "-" +
                                (new Date().getMonth() + 1)
                                  .toString()
                                  .padStart(2, "0") +
                                "-25"
                              }
                              max={
                                new Date().getFullYear() +
                                "-" +
                                (new Date().getMonth() + 1)
                                  .toString()
                                  .padStart(2, "0") +
                                "-25"
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      ) : null} */}
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitUpdateStatus}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UpgradeSimpanan;
